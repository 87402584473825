const { uuidValidate } = require("./uuid-validate");
const { DISPLAY_MODES } = require("./constants");
const uuid = require("uuid");
const HandledException = require("./handled-exception");
const joi = require("joi");
const scenario_export_format = "tiq_scenario_export";
const session_export_format = "tiq_session_export";
const { tlds } = require("@hapi/tlds");

const reusedFields = {
  annotation: joi.string().trim().min(1).allow(null),
  batch: joi
    .string()
    .trim()
    .regex(/^[a-zA-Z0-9._/=: -]+$/),
  connector: joi
    .string()
    .regex(/^[a-zA-Z0-9_-]+$/)
    .min(2)
    .max(50)
    .required(),
  description: joi.string().trim().min(3).max(100).required(),
  email: joi
    .string()
    .trim()
    .email({ minDomainSegments: 2, tlds: { allow: tlds } })
    .required(),
  extra: joi.object(),
  feature_flag_name: joi
    .string()
    .trim()
    .regex(/^[a-zA-Z0-9_/-]+$/)
    .required(),
  name: joi.string().trim().min(3).max(50).required(),
  options: joi.object().required(),
  roles: joi
    .array()
    .items(
      joi
        .string()
        .regex(/^[A-Z_]+$/)
        .required(),
    )
    .required(),
  import_metadata: (file_format) =>
    joi
      .object({
        file_format: joi.string().valid(file_format).required(),
        tiq_version: joi.string().required(),
        export_time: joi.string().isoDate().required(),
      })
      .required(),
  level: joi
    .string()
    .valid("ERROR", "error", "WARNING", "warning", "INFO", "info")
    .required(),
  external_data_retention_limit: joi
    .number()
    .min(0)
    .max(1_000_000)
    .allow("", null),
  external_data_retention_period: joi.number().min(0).max(3650).allow("", null),
  base_url: joi
    .string()
    .uri()
    .regex(/^[^:]+:\/\/[^/]+$/),
  field_settings_insights: joi
    .string()
    .trim()
    .required()
    .valid("no", "low", "medium", "high"),
  display_mode: joi
    .string()
    .trim()
    .required()
    .valid(
      DISPLAY_MODES.NEVER,
      DISPLAY_MODES.INSIGHTS_ONLY,
      DISPLAY_MODES.ALWAYS,
    ),
  scenario_alerters: joi.array().items(joi.string().uuid()),
};

const reusedObjects = {
  cronjob: joi
    .object({
      schedule: joi.string().required(),
      status: joi.string().valid("enabled", "disabled"),
    })
    .unknown(),
  action_insights_config: joi.object({
    length: joi.object().required(),
    rarity: joi.object().required(),
    values: joi.object().required(),
    distance: joi.object().required(),
    frequency: joi.object().required(),
    relevance: joi.object().required(),
    occurrences: joi.object().required(),
    sensitivity: joi.object().required(),
    significance: joi.object().required(),
    values_count: joi.object().required(),
    values_frequency: joi.object().required(),
    session_sensitivity: joi.object().required(),
  }),
  source: joi.object({
    active: joi.boolean(),
    connector: reusedFields.connector,
    description: reusedFields.description,
    name: reusedFields.name,
    options: reusedFields.options,
    options_mode: joi.string().allow(""),
    offline_mode: joi.boolean(),
    offline_type: joi.string().alphanum().allow(""),
    offline_bucket: joi.string().allow(""),
    offline_prefix: joi.string().allow(""),
  }),
  sources_state: joi.object({
    batch: reusedFields.batch.required(),
    most_recent_event_time: joi.date().allow(null),
    oldest_event_time: joi.date().allow(null),
    num_records: joi.number().integer(),
    path: reusedFields.batch,
    raw_file: reusedFields.batch,
    size: joi.number().integer(),
    status: joi.string().alphanum(),
    type: joi.string().alphanum(),
    payload: joi.string(),
    is_encrypted: joi.boolean(),
    file_key: joi.string().alphanum().allow(null),
    file_key_iv: joi.string().alphanum().allow(null),
    file_contents_iv: joi.string().alphanum().allow(null),
  }),
};

const schemas = {
  accounts: {
    patch: joi.object({
      openid_issuer: joi.string().allow(null),
      openid_client_id: joi.string().allow(null),
      openid_client_id_public: joi.string().allow(null),
      openid_client_secret: joi.string().allow(""),
      db_host: joi.string().allow(null),
      db_port: joi.number().integer().allow(null),
      db_user: joi.string().allow(null),
      db_pass: joi.string().allow(""),
      base_url: reusedFields.base_url,
    }),
  },
  alerter: {
    post: joi.object({
      alerter_id: joi.string().uuid().required(),
      active: joi.boolean().required(),
      options: joi.object().optional().default({}),
      scenarios: reusedFields.scenario_alerters.optional(),
    }),
    patch: joi.object({
      active: joi.boolean().required(),
      options: reusedFields.options,
      scenarios: reusedFields.scenario_alerters.optional(),
    }),
    patchAvailable: joi.object({
      active: joi.boolean().required(),
    }),
  },
  appsessions: {
    sid: joi
      .string()
      .regex(/^SPSSN[m0-9]+$/)
      .required(),
    patch: joi.object({
      annotation: reusedFields.annotation,
      benign: joi.boolean(),
      malicious: joi.when("benign", {
        is: true,
        then: joi.boolean().invalid(true),
        otherwise: joi.boolean(),
      }),
      close: joi.boolean(),
    }),
    dailyAlerts: joi.object({
      days: joi.number().integer().min(1).max(365),
      min_risk: joi.number().min(0).max(1),
      max_risk: joi.number().min(0).max(1),
      offset: joi.number(),
      limit: joi.number(),
    }),
    import: joi
      .object({
        metadata: reusedFields.import_metadata(session_export_format),
        sessions: joi
          .array()
          .items(
            joi.object({
              session_id: joi.string().required(),
              evaluation: joi.string().allow("", null),
              evaluated_on: joi.string().isoDate(),
              evaluated_by: joi.string().uuid(),
              classification: joi
                .string()
                .valid("benign", "malicious", "neutral", "unclassified")
                .required(),
            }),
          )
          .max(1000)
          .required(),
      })
      .unknown(),
    postRelevanceFeedback: joi.object({
      action: joi.string().required(),
      feedback: joi.string().required().allow(null),
    }),
  },
  appusers: {
    uid: joi.string().required(),
  },
  audit: {
    post: joi.object({
      service: joi.string().alphanum().required(),
      run_id: joi.string().uuid().required(),
    }),
    postSystemLog: joi.object({
      scenario: joi.string().uuid(),
      type: joi.string().required(),
      action: joi.string().required(),
      details: joi.object(),
    }),
    put: joi.object({
      id: joi.number().required(),
      completed: joi.boolean().required(),
      messages: joi.string().required().allow(""),
      extra: reusedFields.extra,
    }),
  },
  authorizeremote: {
    post: joi.object({
      account: joi.string().uuid(),
      method: joi.string().alphanum().required(),
      url: joi.string(),
      resource: joi
        .string()
        .regex(/^[a-zA-Z.-]+$/)
        .required(),
      scenario: joi.string().uuid().allow(""),
    }),
  },
  bundles: {
    connector: reusedFields.connector,
    patch: joi.object({
      active: joi.boolean().required(),
    }),
    upload: joi.object({
      name: reusedFields.name,
      description: reusedFields.description,
      connector: reusedFields.connector,
      version: joi
        .string()
        .regex(/^[a-zA-Z0-9._-]+$/)
        .required(),
      requires: joi.string().regex(/^[a-zA-Z0-9._-]+$/),
      options: reusedFields.options.required().invalid(null),
      options_modes: joi.object(),
      icon: joi.object({
        file: joi
          .string()
          .regex(/^[a-zA-Z0-9._-]+$/)
          .required(),
        content_type: joi
          .string()
          .regex(/^[a-zA-Z0-9._/-]+$/)
          .required(),
      }),
      offline_support: joi.boolean().optional().default(false),
      external_collector_support: joi.boolean().optional().default(false),
      capabilities: joi.object().optional().default({}),
    }),
  },
  clusters: {
    id: joi.number().required(),
    patch: joi.object({
      annotation: reusedFields.annotation,
      malicious: joi.boolean(),
    }),
    histogram: joi.object({
      id: joi.number().required(),
      type: joi.string().valid("locations", "paths", "delta_t").required(),
    }),
  },
  collectors: {
    post: joi.object({
      connector: reusedFields.connector,
      description: reusedFields.description,
      name: reusedFields.name,
      options: reusedFields.options.required().invalid(null),
      options_mode: joi.string().required(),
      data_sample_size: joi.number().min(0).allow("", null),
      external_data_retention_limit: reusedFields.external_data_retention_limit,
      external_data_retention_period:
        reusedFields.external_data_retention_period,
      identifier_fields: joi.string().allow(null),
    }),
    patch: joi.object({
      connector: reusedFields.connector.optional(),
      description: reusedFields.description.optional(),
      name: reusedFields.name.optional(),
      options: reusedFields.options.invalid(null).optional(),
      options_mode: joi.string().allow(""),
      data_sample_size: joi.number().min(0).allow("", null),
      external_data_retention_limit: reusedFields.external_data_retention_limit,
      external_data_retention_period:
        reusedFields.external_data_retention_period,
      identifier_fields: joi.string().allow(null),
      extra: reusedFields.extra,
    }),
    resetQuery: joi
      .object({
        job_type: joi.string().required(),
      })
      .unknown(),
  },
  connector_state: {
    post: joi.object({
      namespace: joi.string().alphanum().required(),
      key: joi
        .string()
        .regex(/^[a-zA-Z0-9._/=: -]+$/)
        .required(),
      value: joi.string().allow("", null),
    }),
  },
  cronjob: {
    post: reusedObjects.cronjob,
    put: reusedObjects.cronjob,
  },
  jobs: {
    query: joi.object({
      job_type: joi.string().optional(),
      from: joi.date().optional(),
      to: joi.date().optional(),
      interval: joi.number().integer().min(1).optional(),
      iteration: joi.number().integer().min(0).optional(),
      data_analysis_ids: joi.string().optional(),
      column_analysis_ids: joi.string().optional(),
      filtering: joi.boolean().optional(),
    }),
  },
  data_analysis_run: {
    post: joi.object({
      records: joi.number().integer().required(),
      columns: joi.number().integer().required(),
      files: joi.array().items(joi.string().uuid()).required(),
    }),
  },
  data_analysis_columns: {
    post: joi
      .array()
      .items(
        joi.object({
          name: joi.string().required(),
          unique: joi.number().integer().min(0).required(),
          unique_estimate: joi.boolean().optional().default(false),
          types: joi.array().items(joi.string()).required(),
          appearances: joi.number().required(),
          entropy: joi.number().required(),
          case_variance: joi.boolean().required(),
          num_variance: joi.boolean().required(),
          top_values: joi
            .array()
            .items(
              joi.object({
                value: joi.string().required(),
                appearances: joi.number().integer().min(0).required(),
              }),
            )
            .required(),
        }),
      )
      .required(),
  },
  data_analysis_column_values: {
    post: joi
      .array()
      .items(
        joi.object({
          value: joi.string().required(),
          appearances: joi.number().required(),
          regex: joi.string().allow(null).required(),
        }),
      )
      .required(),
  },
  events: {
    superPost: joi.object({
      account: joi.string().uuid().required(),
      event: joi
        .string()
        .trim()
        .regex(/^[a-zA-Z0-9._/ -]+$/)
        .required(),
      payload: joi.object(),
    }),
    post: joi.object({
      event: joi
        .string()
        .trim()
        .regex(/^[a-zA-Z0-9._/ -]+$/)
        .required(),
      payload: joi.object(),
    }),
  },
  files: {
    post: joi.object({
      name: reusedFields.name,
      secret: joi.boolean().optional().default(false),
    }),
    patch: joi.object({
      name: reusedFields.name,
    }),
  },
  fieldSettings: {
    post: joi.object({
      field: joi.string().trim().required(),
      visible: joi
        .boolean()
        .required()
        .when("favorite", {
          is: true,
          then: joi.boolean().valid(true),
          otherwise: joi.boolean().valid(true, false),
        }),
      favorite: reusedFields.display_mode,
      mapping: joi.string().allow(null, "").optional(),
      value_count_insights: reusedFields.field_settings_insights,
      value_frequency_insights: reusedFields.field_settings_insights,
      alert: reusedFields.display_mode,
    }),
  },
  feature_flags: {
    patch: joi.object({
      name: reusedFields.feature_flag_name,
      value: joi.string().trim().min(1).allow(null).required(),
    }),
    post: joi.object({
      name: reusedFields.feature_flag_name,
      value: joi.string().trim().min(1).allow(null).required(),
      default_value: joi.string().trim().required(),
      description: reusedFields.description,
      possible_values: joi.string().trim().required(),
    }),
    query: joi
      .object({
        account: joi.string().uuid().default(uuid.NIL),
        name: reusedFields.feature_flag_name,
        missing_okay: joi.boolean().optional().default(false),
        no_cache: joi.boolean().optional().default(false),
      })
      .unknown(),
  },
  license: {
    post: joi.object({
      description: reusedFields.description,
      key: joi.string().trim().required(),
    }),
  },
  login: {
    post: joi.object({
      username: joi.string().required(),
      password: joi.string().required(),
    }),
  },
  parameter: {
    patch: joi.object({
      name: joi.string().trim().required(),
      description: joi.string().allow(""),
      values: joi.string().trim().allow("").required(),
    }),
    delete: joi
      .object({
        name: joi.string().trim().required(),
      })
      .unknown(),
  },
  reports: {
    sensitivity_coverage: joi
      .array()
      .items(
        joi.object({
          location: joi.string().required(),
          weight: joi.number().required(),
        }),
      )
      .required(),
    postBackoffice: joi
      .object({
        rows: joi
          .array()
          .items(
            joi.object({
              id: joi.string().uuid().optional(),
              active: joi.boolean().required(),
              display_name: joi.string().required(),
              prefix: joi.string().required(),
              query: joi.string().required(),
              columns: joi
                .array()
                .items(
                  joi.object({
                    field: joi.string().required(),
                    column: joi.string().required(),
                  }),
                )
                .required(),
              assign_action_info: joi.boolean().required(),
              parameters: joi.array().items(joi.string().optional()).required(),
              inputs: joi
                .array()
                .items(
                  joi.object({
                    name: joi.string().required(),
                    label: joi.string().required(),
                    default: joi.string().required(),
                  }),
                )
                .required(),
            }),
          )
          .required(),
      })
      .unknown(),
  },
  session_notify: {
    post: joi.object({
      notes: joi.string().allow(""),
      scenario: joi.string().uuid().required(),
      session: joi.string().alphanum().required(),
    }),
  },
  session_summary: {
    query: joi.object({
      commonness: joi.boolean().default(true),
      properties: joi.boolean().default(false),
      limit: joi.number(),
      offset: joi.number(),
      sort: joi.any().valid("frequency", "rarity", "sensitivity"),
    }),
  },
  scenario_progress: {
    post: joi.object({
      run_id: joi.string().uuid(),
      stage: joi
        .string()
        .regex(/^[a-zA-Z0-9._/ -]+$/)
        .required(),
      message: joi.string().allow(""),
      complete: joi.boolean(),
    }),
  },
  scenarios: {
    clone: joi.object({
      description: reusedFields.description,
      name: reusedFields.name,
    }),
    import: joi
      .object({
        description: reusedFields.description,
        external_collector: joi.boolean().required(),
        extra: reusedFields.extra
          .keys({
            action_insights_config:
              reusedObjects.action_insights_config.required(),
          })
          .unknown()
          .required()
          .invalid(null),
        metadata: reusedFields.import_metadata(scenario_export_format),
        name: reusedFields.name,
        external_data_retention_limit:
          reusedFields.external_data_retention_limit,
        external_data_retention_period:
          reusedFields.external_data_retention_period,
        identifier_fields: joi.string().allow(null),
        is_sync_with_sources: joi.boolean().required(),
      })
      .unknown(),
    patchMostRecentEventTime: joi.object({
      most_recent_event_time: joi.date(),
    }),
    patchOldestEventTime: joi.object({
      oldest_event_time: joi.date().allow(null),
    }),
    patchExternalSyncTime: joi.object({
      external_sync_time: joi.date(),
    }),
    patchDataSample: joi.object({
      sample: joi.object().required().invalid(null),
    }),
    patchNumEvents: joi.object({
      num_events: joi.number().min(0),
    }),
    patchJobStatus: joi.object({
      state: joi.string().valid("start", "run", "end").required(),
      run_id: joi.string().uuid(),
      success: joi.boolean(),
      aborted: joi.boolean(),
      recluster: joi.boolean(),
      rescore: joi.boolean(),
      type: joi.string().trim(),
      from: joi.date(),
      to: joi.date(),
      interval: joi.number().min(1),
      iteration: joi.number().min(0),
      version: joi.string().trim(),
    }),
    patchRank: joi.object({
      rank: joi.number().required(),
    }),
    post: joi.object({
      description: reusedFields.description,
      name: reusedFields.name,
      use_case: joi
        .string()
        .trim()
        .regex(/^[a-zA-Z0-9._-]+$/)
        .required(),
    }),
    put: joi.object({
      description: reusedFields.description,
      extra: reusedFields.extra
        .keys({
          action_insights_config:
            reusedObjects.action_insights_config.required(),
        })
        .unknown()
        .required()
        .invalid(null),
      name: reusedFields.name,
      generate_profile_details: joi.boolean().required(),
      users_activity_timeframe: joi.number().min(1).required(),
      siem_id: joi.string().allow("").required(),
      is_production: joi.boolean().required(),
      is_sync_with_sources: joi.boolean().required(),
    }),
    notification: joi.object({
      notifications_enabled: joi.boolean().required(),
      notifications_recipients: joi
        .when("notifications_enabled", {
          is: true,
          then: joi.array().items(reusedFields.email),
          otherwise: joi.array().empty(),
        })
        .required(),
      notifications_type: joi.string().valid("simple", "detailed").required(),
    }),
    postEngineQuery: joi
      .object({
        description: reusedFields.description,
      })
      .unknown(),
    patchEngine: joi.object({
      active: joi.boolean(),
    }),
    patchAlerter: joi.object({
      alerter_id: joi.string().uuid().required(),
      associated: joi.boolean().required(),
    }),
    fieldsQuery: joi.object({
      setting: joi.string().valid("favorite", "alert").optional(),
      limit: joi.number(),
      offset: joi.number(),
    }),
    actionInfoQuery: joi.object({
      sensitive_only: joi.boolean().optional().default(false),
      format: joi.string().optional(),
      count: joi.boolean().optional().default(false),
      limit: joi.number(),
      offset: joi.number(),
    }),
  },
  signup: {
    post: joi.object({
      name: reusedFields.name,
      account_name: reusedFields.name.regex(/^[a-zA-Z0-9_.+]+$/),
      account_description: reusedFields.description,
      account_company: joi.string().trim().min(3).max(40),
      base_url: reusedFields.base_url.allow(null),
    }),
  },
  sources: {
    patch: joi.object({
      active: joi.boolean(),
    }),
    post: reusedObjects.source,
    put: reusedObjects.source,
  },
  sources_state: {
    post: reusedObjects.sources_state,
    patch: reusedObjects.sources_state,
    delete: joi.object({
      batch: reusedFields.batch.required(),
    }),
  },
  user_messages: {
    delete: joi.object({
      ids: joi.array().optional(),
    }),
    post: joi.object({
      service: joi.string().required(),
      message: joi.string().required(),
      level: reusedFields.level,
    }),
    superpost: joi.object({
      account: joi.string().uuid(),
      scenario: joi.string().uuid(),
      service: joi.string().required(),
      message: joi.string().required(),
      level: reusedFields.level,
    }),
  },
  users: {
    patch: joi.object({
      name: reusedFields.name,
      roles: reusedFields.roles,
    }),
    patchself: joi.object({
      extra: reusedFields.extra,
    }),
    post: joi.object({
      email: reusedFields.email,
      name: reusedFields.name,
      roles: reusedFields.roles,
    }),
    superpost: joi.object({
      name: reusedFields.name,
      email: reusedFields.email,
      extra: reusedFields.extra,
    }),
  },
  wheels: {
    patch: joi.object({
      active: joi.boolean(),
    }),
    post: joi
      .object({
        filename: reusedFields.name,
        description: reusedFields.description,
      })
      .unknown(),
  },
  jwt: {
    get: joi
      .object({
        scheduled_job: joi.boolean().default(false).optional(),
      })
      .unknown(),
  },
  frontendError: {
    post: joi.object({
      message: joi.string(),
      details: joi.object(),
      warning: joi.boolean().default(false).optional(),
    }),
  },
};
module.exports.schemas = schemas;
module.exports.schemaValidate = async (objectType, action, body) => {
  const schema = schemas[objectType]?.[action];
  if (!schema) {
    throw new Error(
      `No schema found for objectType='${objectType}', action='${action}'`,
    );
  }
  try {
    return await schema.validateAsync(body, { abortEarly: false });
  } catch (err) {
    throw new HandledException("invalid_schema", {
      message: err.message,
      objectType,
      action,
    });
  }
};

module.exports.uuidValidate = (uuid) => {
  if (!uuidValidate(uuid)) {
    throw new HandledException("invalid_uuid", { uuid });
  }
  return uuid;
};

module.exports.scenarioExportFormat = scenario_export_format;
module.exports.sessionExportFormat = session_export_format;
