import { groupBy, omit } from "lodash-es";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import CollectorsAPI from "api/collectors";
import FeaturesAPI from "api/features";
import SourcesAPI from "api/sources";

import { createMessage } from "utils/createMessage";
import stdFetch from "utils/stdFetch";

const initialState = {
  featureFlags: [],
  jobs: {},
  apps: [],
  deletedScenarios: [],
  analysisColumns: [],

  //Mapping editor output fields
  outputFields: {},
  duplicateOutputFields: [],
  messages: [],
};

export const useStore = create(
  subscribeWithSelector((set, get) => ({
    ...initialState,

    //feature flags
    getFeatureFlags: async () => {
      try {
        const featureFlags = await stdFetch(FeaturesAPI.getFeaturesState().url);
        set({ featureFlags });
      } catch {
        set({ featureFlags: [] });
      }
    },

    //Jobs
    setScenarioJobs: (scenario, jobs) =>
      set((state) => ({ jobs: { ...state.jobs, [scenario]: jobs } })),
    setJobs: (jobs) => set({ jobs: groupBy(jobs, "scenario") }),

    //Collector applications
    getApps: async () => {
      try {
        const apps = await stdFetch(SourcesAPI.enumApplicationsUrl().url);
        const sortedApps = apps.sort((a, b) => a.name.localeCompare(b.name));
        set({ apps: sortedApps });
      } catch {
        set({ apps: [] });
      }
    },

    //deleted scenarios
    markDeletedScenario: (scenarioId) =>
      set((state) => ({
        deletedScenarios: [...state.deletedScenarios, scenarioId],
      })),
    unmarkDeletedScenario: (scenarioId) =>
      set((state) => ({
        ...state.deletedScenarios.filter((sid) => sid !== scenarioId),
      })),
    isDeletedScenario: (scenarioId) =>
      get().deletedScenarios.includes(scenarioId),

    //Analysis columns
    getAnalysisColumns: async (app, options) => {
      if (!app?.options?.default?.internal_connector) {
        if (options?.collector !== true) {
          return;
        }
      }
      const runs = await stdFetch(
        CollectorsAPI.getCollectorDataAnalysisRuns(app.id).url
      );
      if (!runs.length) {
        set({ analysisColumns: [] });
        return;
      }
      const run = runs[0];
      const colData = await stdFetch(
        CollectorsAPI.getCollectorDataAnalysisColumns(
          app.id,
          run.id,
          0,
          1000,
          {},
          { name: 1 }
        ).url
      );
      const analysisColumns = colData.map((col) => col.name);
      set({ analysisColumns });
    },
    clearOutputFields: () => set({ outputField: {} }),
    removeOutputField: (name) =>
      set((state) => {
        const newOutputFields = omit(state.outputFields, name);
        return { outputFields: newOutputFields };
      }),
    setOutputFields: (outputField, outputValue) =>
      set((state) => ({
        outputFields: { ...state.outputFields, [outputField]: outputValue },
      })),

    // Messages
    pushMessage: (level, text, noAutoClose) => {
      set((state) => {
        const last = state.messages.length
          ? state.messages[state.messages.length - 1]
          : {};

        if (level === last.level && text === last.text) {
          return state.messages;
        }
        return {
          messages: [
            ...state.messages,
            createMessage(level, text, noAutoClose),
          ],
        };
      });
    },
    deleteMessage: (id) =>
      set((state) => ({ messages: state.messages.filter((m) => m.id !== id) })),
    clearExpiredMessages: () => {
      const messages = get().messages;
      if (!messages.length) {
        return;
      }
      const timeout = 30000;
      const now = Date.now();
      const expiredMessages = messages.filter(
        (m) => m.time && now - m.time >= timeout
      );

      if (expiredMessages.length) {
        set(() => ({
          messages: messages.filter((m) => !m.time || now - m.time < timeout),
        }));
      }
    },
    clearMessages: () => set({ messages: [] }),
    resetStore: () => set({ ...initialState }),
  }))
);

setInterval(() => useStore.getState().clearExpiredMessages(), 1000);
