import { useContext, useMemo } from "react";

import { useSessionTagsQuery } from "api/sessions";

import { TagsVisibilityContext } from "contexts/TagsVisibilityContext";

import { useSessionDetails } from "components/sessions/SessionDetailsContext";

export const useTags = ({ scenario, session, favoriteFields }) => {
  const { isVisibleField } = useContext(TagsVisibilityContext);

  const { data: sessionData, isPending: isPendingSessionData } = useSessionDetails();

  const loadTags =
    favoriteFields || (!isPendingSessionData && !sessionData?.facts?.rows);

  const { data, isPending } = useSessionTagsQuery({
    scenario,
    session,
    favoriteTags: favoriteFields,
    enabled: loadTags,
  });

  const tags = useMemo(() => {
    if (sessionData?.facts?.rows) {
      const displayedFields = data?.map((t) => t.tag);
      const d = sessionData.facts.rows;
      const visible = d?.filter(({ tag }) => isVisibleField(tag));
      return favoriteFields
        ? visible.filter(({ tag }) => displayedFields?.includes(tag))
        : visible;
    }
    return data?.filter(({ tag }) => isVisibleField(tag));
  }, [sessionData?.facts?.rows, data, favoriteFields, isVisibleField]);

  return {
    tags,
    insights: sessionData?.insights,
    totals: sessionData?.facts?.totals,
    actions: sessionData?.facts?.actions,
    isPending: loadTags ? isPending : isPendingSessionData,
    isPendingTagTotals: isPendingSessionData,
  };
};
