import { useEffect, useMemo, useState } from "react";

import {
  Badge,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Rating,
  Select,
  Typography,
  styled,
} from "@mui/material";
import { ROLES } from "@tiq/shared/lib/constants";

import {
  useSessionsGetSessionSummaryQuery,
  useSessionsRelevanceFeedbackMutation,
} from "api/sessions";

import SessionTokens from "components/sessions/SessionTokens";
import { formatPeriod } from "components/sessions/relevance";
import DataLink from "components/ui/DataLink";
import Flexbox from "components/ui/Flexbox";
import { HelpIcon } from "components/ui/HelpIcon";
import HtmlTooltip from "components/ui/HtmlTooltip";
import { InformationPopover } from "components/ui/InformationPopover";
import { PageLoadingSpinner } from "components/ui/PageLoadingSpinner";
import RarityIndicator from "components/ui/RarityIndicator";
import SensitivityIndicator from "components/ui/SensitivityIndicator";
import StatusIcon from "components/ui/StatusIcon";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";
import { useRoles } from "hooks/useRoles";
import { useTags } from "hooks/useTags";

import EvaluateSession from "./EvaluateSession";
import { ActionText } from "./SessionActions";
import { SessionSummaryFieldsContextProvider } from "./SessionSummaryFields";

const StyledSessionSummaryContent = styled("div")(({ theme }) => ({
  display: "grid",
  gap: "3em",
  gridTemplateRows: "min-content min-content min-content",
  height: "calc(100vh - 160px)",
  overflow: "auto",
  padding: "2em",
  "& .important-actions": {
    h2: {
      marginBottom: "1em",
    },
  },
  "& .aspects-toolbar": {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "1em",
  },
  "& .actions-tags-wrapper": {
    display: "grid",
    flexDirection: "column",
    gap: "20px",
  },
  "& .table-wrapper": {
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    position: "relative",
    width: "100%",
    table: {
      border: "none",
      borderCollapse: "collapse",
      height: "100%",
      left: "0",
      tableLayout: "fixed",
      top: "0",
      width: "100%",
      "td,th": {
        border: "none",
        padding: "10px 10px",
        textAlign: "left",
      },
      thead: {
        tr: {
          th: {
            top: "0",
            backgroundColor: `${theme.palette.sidebar.light}`,
            color: `${theme.palette.purple.dark}`,
            textTransform: "capitalize",
            zIndex: "10",
          },
        },
      },
      tbody: {
        tr: {
          td: {
            borderBottom: "1px solid #ddd",
          },
        },
      },
      "&[data-hover]": {
        tbody: {
          "tr:hover": {
            td: {
              backgroundColor: "rgba(0, 0, 0, 0.03)",
            },
          },
        },
      },
    },
  },
  "&.tags-wrapper": {
    display: "grid",
    flexDirection: "column",
    gap: "20px",
    overflow: "hidden",
    widht: "100%",
    "& .chart": {
      paddingLeft: "10px",
    },
  },
  "& .tags-wrapper-inner": {
    display: "flex",
    height: "auto",
    paddingLeft: "10px",
    widht: "100%",
  },
}));

const RelevanceTooltipContent = ({
  action,
  commonness,
  components,
  feedback,
  scenario,
  score,
  level,
  session,
}) => {
  const strings = useLocalizedStrings();
  const { hasRoles } = useRoles();
  const tooltipDisabled = !hasRoles([ROLES.ADMIN]);
  const updateRelevanceFeedbackMutation = useSessionsRelevanceFeedbackMutation({
    scenario,
    session,
  });

  const { pushMessage } = useMessages();

  const tooltipContent = Object.entries(components || {}).map(([key, val]) => ({
    name: `${String.fromCharCode(0x2022)} ${key}`,
    value: (
      <div style={{ width: "220px", marginLeft: "auto" }}>
        {val?.do_not_score ? "N/A" : `${val?.level} (${val?.value})`}{" "}
      </div>
    ),
  }));
  tooltipContent.push({
    name: (
      <div style={{ width: "150px" }}>
        {strings.sessiondetails_column_relevance_composite_score}: {score}
      </div>
    ),
  });
  const footer = commonness
    ? strings.formatString(
        strings.sessiondetails_column_relevance_tooltip_footer,
        commonness.total,
        formatPeriod(commonness.from_date, commonness.to_date, strings)
      )
    : strings.sessiondetails_column_relevance_tooltip_fallback;

  const handleChange = (_, newValue) => {
    const formattedNewValue = newValue || 0;

    updateRelevanceFeedbackMutation.mutate(
      {
        scenario,
        session,
        action,
        feedback: formattedNewValue.toString(),
      },
      {
        onSuccess: () => {
          pushMessage(
            "success",
            strings.sessiondetails_column_relevance_feedback_save_success
          );
        },
      }
    );
  };

  return (
    <InformationPopover
      disabled={tooltipDisabled}
      title={
        <div style={{ display: "flex", gap: "5px" }}>
          <div>{strings.sessiondetails_column_relevance_tooltip_title}</div>
        </div>
      }
      titleDetails={
        <Rating
          name="relevance-feedback-rating"
          value={isNaN(feedback) ? 0 : feedback}
          onChange={handleChange}
          min={0}
          max={3}
          style={{ marginLeft: "auto" }}
        />
      }
      content={tooltipContent}
      footer={footer}
    >
      <div
        style={{
          display: "flex",
          marginLeft: "auto",
          marginRight: "auto",
          textDecoration: "underline",
        }}
      >
        {level ?? score}
        <Badge
          color="secondary"
          variant="dot"
          sx={{
            width: "5px",
            "& .MuiBadge-badge": {
              backgroundColor: "royalblue",
              textTransform: "capitalize",
              width: "8px",
            },
          }}
          invisible={!feedback || feedback == 0}
        />
      </div>
    </InformationPopover>
  );
};

const ColumnHeaderTooltip = ({ label, tooltip }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "default",
        gap: "2px",
      }}
    >
      {label}
      <HtmlTooltip title={tooltip} placement="top">
        <HelpIcon />
      </HtmlTooltip>
    </div>
  );
};

const tableColumns = (strings, session) => [
  {
    id: "action",
    label: strings.sessiondetails_column_action,
    render: (v, { translate, properties, summaryFields }) => (
      <ActionText
        description={v.description}
        action={v.action}
        translate={translate}
        properties={properties}
        summaryFields={summaryFields}
      />
    ),
    style: {
      width: "auto",
    },
  },
  {
    id: "relevance",
    label: strings.sessiondetails_column_relevance,
    render: (v, props) => {
      return (
        <RelevanceTooltipContent
          action={v.action}
          commonness={props?.relevanceCommonness}
          components={v.components}
          feedback={Number(props?.relevanceFeedback)}
          scenario={props.scenario}
          score={v.score}
          level={v.level}
          session={session}
        />
      );
    },
    style: {
      width: "8em",
      textAlign: "center",
    },
  },
  {
    id: "sensitivity",
    label: (
      <ColumnHeaderTooltip
        label={strings.sessiondetails_column_sensitivity}
        tooltip={strings.sessiondetails_column_sensitivity_tip}
      />
    ),
    render: (v) => {
      if (!v.match_info.match) {
        return (
          <SensitivityIndicator
            value={Math.abs(Math.min(+v.sensitivity, 0))}
            size="medium"
          />
        );
      }
      return (
        <HtmlTooltip
          title={
            <div>
              <h4>Matched by:</h4>
              <pre style={{ fontFamily: "monospace" }}>
                {v.match_info.match}
              </pre>
            </div>
          }
        >
          <SensitivityIndicator
            value={Math.abs(Math.min(+v.sensitivity, 0))}
            size="medium"
          />
        </HtmlTooltip>
      );
    },
    style: {
      width: "8em",
      textAlign: "center",
    },
  },
  {
    id: "rarity",
    label: (
      <ColumnHeaderTooltip
        label={strings.sessiondetails_column_rarity}
        tooltip={strings.sessiondetails_column_rarity_tip}
      />
    ),
    render: (v) => <RarityIndicator value={+v.rarity_score} />,
    style: {
      width: "6em",
      textAlign: "center",
    },
  },
  {
    id: "frequency",
    label: (
      <ColumnHeaderTooltip
        label={strings.sessiondetails_column_frequency}
        tooltip={strings.sessiondetails_column_frequency_tip}
      />
    ),
    render: (v) => +v.frequency,
    style: {
      width: "9em",
      textAlign: "center",
    },
  },
  {
    id: "num_sessions",
    label: (
      <ColumnHeaderTooltip
        label={strings.sessiondetails_column_num_sessions}
        tooltip={strings.sessiondetails_column_num_sessions_tip}
      />
    ),
    render: (v, { scenario, isPending, totalSessions }) => {
      if (isPending) {
        return <StatusIcon status="active" />;
      }

      if (!v.commonness) {
        return (
          <HtmlTooltip
            title={strings.formatString(
              strings.sessiondetails_column_zero_sessions_tooltip,
              totalSessions
            )}
            placement="top"
          >
            <Typography style={{ cursor: "default" }}>{"0"}</Typography>
          </HtmlTooltip>
        );
      }

      const {
        num_occurrences,
        num_sessions,
        num_unprofiled,
        sessions,
        total,
        from_date,
        to_date,
      } = v.commonness;
      const sessionParams = sessions
        ? new URLSearchParams([
            ["app_session_id", sessions.join(",")],
          ]).toString()
        : undefined;

      const formattedAverage = Math.round(
        Number(Number(num_occurrences / num_sessions).toFixed(2))
      );

      return (
        <HtmlTooltip
          title={strings.formatString(
            strings.sessiondetails_column_num_sessions_tooltip,
            formattedAverage,
            num_sessions || "0",
            total || "0",
            num_unprofiled || "0",
            num_unprofiled === "1" ? strings.text_is : strings.text_are,
            formatPeriod(from_date, to_date, strings)
          )}
          placement="left"
        >
          {sessionParams ? (
            <DataLink
              style={{ color: "steelBlue", textDecoration: "unset" }}
              to={`/scenarios/${scenario}/sessions?${sessionParams}`}
            >
              {+num_sessions}
            </DataLink>
          ) : (
            <Typography>{"0"}</Typography>
          )}
        </HtmlTooltip>
      );
    },
    style: {
      width: "8em",
      textAlign: "center",
    },
  },
];

const SortSelector = ({ showRelevance, sortType, setSortType }) => {
  const strings = useLocalizedStrings();

  const sortTypes = useMemo(
    () =>
      [
        {
          id: "relevance",
          label: strings.sessiondetails_column_relevance,
        },
        {
          id: "sensitivity",
          label: strings.sessiondetails_column_sensitivity,
        },
        {
          id: "rarity",
          label: strings.sessiondetails_column_rarity,
        },
        {
          id: "frequency",
          label: strings.sessiondetails_column_frequency,
        },
      ].filter((st) => showRelevance || st.id !== "relevance"),
    [showRelevance, strings]
  );

  return (
    <Flexbox gap="1em">
      <Typography>{strings.sessiondetails_selector_label}</Typography>
      <Select
        style={{
          width: "150px",
          height: "3em",
        }}
        value={sortType}
        onChange={(e) => setSortType(e.target.value)}
      >
        {sortTypes.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            {d.label}
          </MenuItem>
        ))}
      </Select>
    </Flexbox>
  );
};

export default function SessionSummary({ scenario, session }) {
  const strings = useLocalizedStrings();
  const [sortType, setSortType] = useState();
  const [translate, setTranslate] = useCurrentUserSettings(
    "summary/translate",
    true
  );

  const {
    tags,
    isPending: isPendingTags,
    isPendingTagTotals,
    totals,
    actions,
    insights,
  } = useTags({
    scenario,
    session,
    favoriteFields: true,
  });
  const summaryFields = useMemo(() => tags?.map((t) => t.tag), [tags]);
  const { data, isPending: isPendingData } = useSessionsGetSessionSummaryQuery({
    scenario,
    session,
    commonness: false,
  });
  const { data: dataWithCommonness, isPending } =
    useSessionsGetSessionSummaryQuery({
      scenario,
      session,
      commonness: true,
    });
  const summary = isPending ? data : dataWithCommonness;

  //calculated in the job, taken from sessions endpoint
  const relevanceCommonness = new Map(
    actions?.map((a) => [a.action, a.commonness])
  );

  const relevanceFeedback = new Map(
    summary?.feedback?.map((item) => [item.action, item.feedback])
  );

  const properties = data?.properties;
  const showRelevance = !!summary?.relevance?.some((action) => !!action.score);
  useEffect(() => {
    !isPendingData && setSortType(showRelevance ? "relevance" : "sensitivity");
  }, [showRelevance, isPendingData]);

  const cols = useMemo(
    () =>
      showRelevance
        ? tableColumns(strings, session)
        : tableColumns(strings).filter((c) => c.id !== "relevance"),
    [showRelevance, strings, session]
  );

  const isPendingPage =
    isPendingTagTotals || isPendingData || sortType === undefined;

  return (
    <SessionSummaryFieldsContextProvider summaryFields={summaryFields}>
      <StyledSessionSummaryContent>
        {isPendingPage ? (
          <div
            style={{
              display: "flex",
              height: "40vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PageLoadingSpinner />
          </div>
        ) : (
          <>
            <div className="important-actions">
              <h2>{strings.sessiondetails_summary_title}</h2>
              <div className="aspects-toolbar">
                <FormControlLabel
                  label={strings.sessiondetails_checkbox_translate}
                  control={
                    <Checkbox
                      checked={translate}
                      onChange={(e) => setTranslate(e.target.checked)}
                    />
                  }
                />
                <SortSelector
                  sortType={sortType}
                  setSortType={setSortType}
                  showRelevance={showRelevance}
                />
              </div>
              <div className="actions-tags-wrapper">
                <div className="table-wrapper">
                  <table data-hover="true">
                    <colgroup>
                      {cols.map((c) => (
                        <col
                          key={c.id}
                          style={{ ...c.style, textAlign: "left" }}
                        />
                      ))}
                    </colgroup>
                    <thead>
                      <tr>
                        {cols.map((c) => (
                          <th key={c.id} style={c.style}>
                            {c.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {summary &&
                        summary[sortType]?.map((d) => (
                          <tr key={d.action}>
                            {cols.map((c) => (
                              <td key={c.id} style={c.style}>
                                {c.render(d, {
                                  translate,
                                  scenario,
                                  isPending,
                                  totalSessions: summary.total,
                                  relevanceCommonness: relevanceCommonness.get(
                                    d.action
                                  ),
                                  relevanceFeedback: relevanceFeedback.get(
                                    d.action
                                  ),
                                  properties: properties?.[d.action],
                                })}
                              </td>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="tags-wrapper">
              <div className="tags-wrapper-inner">
                {isPendingTags ? (
                  strings.text_loading2
                ) : (
                  <SessionTokens
                    histograms={tags}
                    totals={totals}
                    isPendingTagTotals={isPendingTagTotals}
                    minSensitivity={0}
                    readOnly={true}
                    insights={insights}
                  />
                )}
              </div>
            </div>
          </>
        )}
        <EvaluateSession scenario={scenario} session={session} />
      </StyledSessionSummaryContent>
    </SessionSummaryFieldsContextProvider>
  );
}
