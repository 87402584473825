import { useEffect, useState } from "react";

import { Paper, Typography, styled } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import CollectorsAPI from "api/collectors";
import {
  useDiagnosticsDeleteMessagesForQuery,
  useDiagnosticsGetMessagesForUrlQuery,
} from "api/diagnostics";

import AuditListView from "components/scenarios/diagnostics/AuditListView";
import JobActions from "components/scenarios/diagnostics/JobActions";
import { default as JobProgressView } from "components/scenarios/diagnostics/JobProgress";
import BigMessage from "components/ui/BigMessage";
import { DeleteButton } from "components/ui/DeleteButton";
import LocalDownloader from "components/ui/LocalDownloader";
import { UiSecondaryButton } from "components/ui/StyledButtons";

import { timeFormatter } from "utils/time-fmt";

import { useEnhancedAPI } from "hooks/useAPI";
import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import useScenarioJobState from "hooks/useScenarioJobState";
import { useLocalStorage } from "hooks/useStorage";

import TabHeader from "./TabHeader";

const StyledDiagnosticsTab = styled("div")`
  padding: 2em;
  overflow-x: scroll;
  .diagnostics-content {
    width: 90%;
    max-width: 1200px;
    min-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding-bottom: 1em;
    .diagnostics-section {
      display: grid;
      grid-template-rows: min-content auto;
      grid-template-columns: 100%;
      gap: 10px;
      .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .diagnostics-section-title {
          text-transform: uppercase;
          font-weight: 100;
          letter-spacing: 2px;
          display: flex;
          align-items: center;
        }
      }
    }
    .messages-list {
      overflow: auto;
      max-height: 25em;
      padding-right: 5px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .message-item {
        border: 1px solid #ddd;
        border-radius: 7px;
        .MuiAlert-message {
          width: 100%;
          .message-body-wrapper {
            padding: 5px 10px;
            background-color: rgba(255, 255, 255, 0.6);
            border-radius: 5px;
          }
          .caption-region {
            margin-top: 10px;
            display: flex;
            gap: 2em;
            .caption {
              display: flex;
              gap: 0.5em;
            }
          }
        }
      }
    }
    .messages-count {
      display: flex;
      justify-content: flex-end;
      opacity: 0.5;
    }
  }
`;

function MessagesItem({ message }) {
  return (
    <Alert className="message-item" severity={message.level.toLowerCase()}>
      <AlertTitle>{message.level.toUpperCase()}</AlertTitle>
      <Paper className="message-body-wrapper" elevation={0}>
        <code style={{ whiteSpace: "pre-wrap" }}>{message.message}</code>
      </Paper>
      <div className="caption-region">
        <Typography className="caption" variant="caption">
          <b>FROM</b>
          {(message.service || "-unknown-").toUpperCase()}
        </Typography>
        <Typography className="caption" variant="caption">
          <b>BY</b>
          {message.username || "-unknown-"}
        </Typography>
        <Typography className="caption" variant="caption">
          <b>AT</b>
          {timeFormatter(new Date(message.created))}
        </Typography>
      </div>
    </Alert>
  );
}

const MessagesList = ({ messages = [] }) => {
  const strings = useLocalizedStrings();

  return (
    <>
      <div className="messages-list">
        {messages.map((d, i) => (
          <MessagesItem key={i} message={d} />
        ))}
      </div>
      <div className="messages-count">
        {strings.formatString(
          strings.collectors_diagnostics_messages_counter_format,
          messages?.length || 0
        )}
      </div>
    </>
  );
};

const DiagnosticsSection = (props) => {
  const { label, ToolbarComponent = null, children } = props;
  return (
    <div className="diagnostics-section">
      <div className="section-header">
        <h3 className="diagnostics-section-title">{label}</h3>
        {ToolbarComponent}
      </div>
      {children}
    </div>
  );
};

const LastJobLogs = ({ collectorId }) => {
  const api = useEnhancedAPI();
  const strings = useLocalizedStrings();
  const [lastJobLogs, setLastJobLogs] = useState();

  const { isRunning } = useScenarioJobState(collectorId);

  useEffect(() => {
    if (!collectorId) {
      return;
    }
    api(CollectorsAPI.getCollector(collectorId, true))
      .then((rows) => {
        setLastJobLogs(rows[0]?.job_log);
      })
      .catch(() => {});
  }, [isRunning, api, collectorId]);

  return (
    <UiSecondaryButton
      style={{ width: "15em", marginBottom: "5px" }}
      disabled={!lastJobLogs?.length}
    >
      <LocalDownloader
        data={lastJobLogs || ""}
        filename={`job-log.${collectorId}.txt`}
        mimetype="text/plain"
      >
        {strings.collectors_diagnostics_exports_label_job_log}
      </LocalDownloader>
    </UiSecondaryButton>
  );
};

const DiagnosticsTab = (props) => {
  const { collector, apps } = props;
  const strings = useLocalizedStrings();
  const caps = useCapabilities();
  const [messagesBulkSize] = useLocalStorage("max-user-messages-bulk-size", 30);
  const [progressStarted, setProgressStarted] = useState(0);
  const { mutate: clearMessages } = useDiagnosticsDeleteMessagesForQuery({
    scenarioId: collector?.id,
  });
  const { data: messages } = useDiagnosticsGetMessagesForUrlQuery({
    scenarioId: collector?.id,
    count: messagesBulkSize,
  });
  const capabilities =
    apps.find((a) => a.connector === collector?.connector)?.capabilities || {};

  const messageClearDisabled =
    !caps({ "acm.user_messages": { write: true } }) || messages?.length === 0;

  const handleClearMessages = () => {
    clearMessages(messages?.map((d) => d.id));
  };

  if (!collector) {
    return <BigMessage>{strings.collectors_no_selected}</BigMessage>;
  }

  return (
    <StyledDiagnosticsTab>
      <div className="diagnostics-content">
        <TabHeader
          collector={collector}
          name={strings.collectors_diagnostics_header}
        />
        <hr />

        <DiagnosticsSection
          label={strings.collectors_diagnostics_section_label_messages}
          ToolbarComponent={
            <div className="messages-toolbar">
              <DeleteButton
                onClick={handleClearMessages}
                disabled={messageClearDisabled}
              />
            </div>
          }
        >
          <MessagesList messages={messages} {...props} />
        </DiagnosticsSection>
        <hr />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DiagnosticsSection
            label={strings.collectors_diagnostics_section_label_jobprogress}
          >
            <div style={{ height: "300px", overflow: "auto" }}>
              <JobProgressView key={progressStarted} scenario={collector?.id} />
            </div>
          </DiagnosticsSection>
          <div
            style={{
              width: "30%",
              borderLeft: "1px solid lightgrey",
              paddingLeft: "2em",
            }}
          >
            <DiagnosticsSection
              label={strings.collectors_diagnostics_section_label_run}
            >
              <JobActions
                scenario={collector.id}
                scenarioName={collector.name}
                collectorCapabilities={capabilities}
                onProgressStarted={() => setProgressStarted((prev) => prev + 1)}
              />
            </DiagnosticsSection>
          </div>
        </div>
        <hr />

        <DiagnosticsSection
          label={strings.collectors_diagnostics_section_label_audit}
        >
          <AuditListView scenario={collector.id} style={{ height: "23em" }} />
        </DiagnosticsSection>
        <hr />
        <DiagnosticsSection
          label={strings.collectors_diagnostics_exports_title}
        >
          <LastJobLogs collectorId={collector.id} />
        </DiagnosticsSection>
      </div>
    </StyledDiagnosticsTab>
  );
};

export default DiagnosticsTab;
