import { useContext, useEffect, useRef, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import PersonIcon from "@mui/icons-material/Person";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, styled } from "@mui/material";
import { Link } from "react-router-dom";

import { useClustersInfiniteQuery } from "api/clusters";

import { ScenariosContext } from "contexts/ScenariosContext";

import FilterChips from "components/scenarios/FiltersChips";
import FiltersSection from "components/scenarios/FiltersSection";
import DataLink from "components/ui/DataLink";
import EmbeddedTagsView from "components/ui/EmbeddedTagsView";
import FiltersShowHideButton from "components/ui/FiltersShowHideButton";
import NotAllowedMessage from "components/ui/NotAllowedMessage";
import TextSearchInput from "components/ui/TextSearchInput";
import { VirtuosoTable } from "components/ui/VirtuosoTable";

import FilterOperators from "utils/FilterOperators";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import { useCapabilities } from "hooks/useCapabilities";
import useCurrentUrlState from "hooks/useCurrentUrlState";
import useLocalizedStrings from "hooks/useLocalizedStrings";

const operators = FilterOperators;

const filtersSchema = (strings) =>
  Object.entries({
    id: {
      label: strings.profilestable_filters_id,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    size: {
      label: strings.profilestable_filters_size,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    annotation: {
      label: strings.profilestable_filters_comment,
      type: "text",
      operators: [
        operators["eq"],
        operators["contains"],
        operators["not-contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
    deviation: {
      label: strings.profilestable_filters_deviation,
      type: "number",
      visible: false,
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    average: {
      label: strings.profilestable_filters_average,
      type: "number",
      visible: false,
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    app_user_id: {
      label: strings.profilestable_filters_user,
      type: "text",
      operators: [
        operators["eq"],
        operators["contains"],
        operators["not-contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
    uid: {
      label: strings.profilestable_filters_user,
      type: "text",
      visible: false,
      operators: [operators["eq"]],
    },
    user_or_annotation: {
      label: strings.profilestable_filters_user_or_comment,
      type: "text",
      visible: false,
      operators: [
        operators["eq"],
        operators["contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
    malicious: {
      label: strings.profilestable_filters_malicious,
      type: "select",
      default_value: "All",
      dropdown_options: [
        {
          id: "all",
          label: "All",
          value: "All",
        },
        {
          id: "malicious",
          label: "Malicious",
          value: "true",
        },
        {
          id: "benign",
          label: "Unclassified",
          value: "false",
        },
      ],
      operators: [operators["eq"]],
    },
  });

const StyledProfilesViewContainer = styled("div")`
  --gap: 8px 0;
  height: calc(100vh - 36px - var(--appbar-height));
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr 30px;

  .filters-strip {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.4em;
  }
`;

const columns = ({ strings, scenario }) => [
  {
    id: "actions",
    label: null,
    render: (d) => (
      <div style={{ display: "flex" }}>
        <Link
          to={`/scenarios/${scenario}/profiles/${d.id}/actions`}
          size="small"
          title={strings.profilestable_actions_tooltip_details}
        >
          <VisibilityIcon />
        </Link>
        <Link
          to={`/scenarios/${scenario}/users?uid=${encodeURIComponent(
            d.app_user_id
          )}`}
          size="small"
          title={strings.profilestable_actions_tooltip_user}
        >
          <PersonIcon />
        </Link>
      </div>
    ),
    style: {
      width: "5em",
    },
  },
  {
    id: "id",
    label: strings.profiletable_header_ID,
    render: (d) => `#${d.id}`,
    sort: "id",
    style: {
      textAlign: "center",
      width: "6em",
    },
  },
  {
    id: "classification",
    label: strings.profiletable_header_type,
    render: (d) =>
      d.malicious ? (
        <ThumbDownOutlinedIcon sx={{ color: "error.main" }} />
      ) : null,
    sort: "malicious",
    style: {
      width: "6em",
    },
  },
  {
    id: "user",
    label: strings.profiletable_header_user,
    render: (d) => <EmbeddedTagsView value={d.app_user_id} userType="none" />,
    sort: "app_user_id",
    style: {
      width: "auto",
    },
  },
  {
    id: "size",
    label: strings.profiletable_header_num_sessions,
    sort: "size",
    render: (d) => (
      <DataLink to={`/scenarios/${scenario}/sessions?cluster=${d.id}`}>
        {d.size}
      </DataLink>
    ),
    style: {
      width: "8em",
      textAlign: "center",
    },
  },
  {
    id: "annotation",
    label: strings.profiletable_header_comment,
    render: (d) => d.annotation,
    sort: "annotation",
    style: {
      width: "20em",
      wordWrap: "normal",
      whiteSpace: "break-spaces",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
];

const ProfilesTable = ({
  data,
  isPending,
  loadMoreItems,
  onOrderBy,
  orderBy,
  scenario,
  pageLimit,
}) => {
  const strings = useLocalizedStrings();

  return (
    <VirtuosoTable
      columns={columns({ strings, scenario })}
      isPending={isPending}
      items={data?.rows}
      itemsCount={data?.count}
      loadMoreItems={loadMoreItems}
      orderBy={orderBy}
      onOrderBy={onOrderBy}
      pageLimit={pageLimit}
    />
  );
};

export default function ProfilesTablePage() {
  const pageLimit = 40;
  const strings = useLocalizedStrings();
  const { selectedScenario } = useContext(ScenariosContext);
  const [showFilters, setShowFilters] = useState(false);
  const [search, setSearch] = useState();
  const [filters, setFilters] = useCurrentUserSettings("profiles.filters", {});
  const query = useRef(null);
  const caps = useCapabilities();
  const { removeQueryString } = useCurrentUrlState();

  const [orderBy, setOrderBy] = useCurrentUserSettings("profiles.orderby", {});

  const { data, fetchNextPage, isFetching, isPending, cancel } =
    useClustersInfiniteQuery({
      filters,
      scenario: selectedScenario.id,
      orderBy,
      pageLimit,
    });

  const setNextOrderBy = (name) => {
    cancel();
    setOrderBy((prev) => {
      let d = prev[name] || 0;
      d++;
      if (d === 0) {
        return {};
      }
      if (d > 1) {
        d = -1;
      }
      return { [name]: d };
    });
  };

  //  build filters from query-string if exists
  useEffect(() => {
    const s = removeQueryString();
    query.current = s;
    if (!s) {
      return;
    }
    const f = {};
    if (s["id"]) {
      f["id"] = { operator: "eq", operand: s["id"] };
    }
    if (s["uid"]) {
      f["uid"] = { operator: "eq", operand: s["uid"] };
    }
    setFilters(f);
  }, [removeQueryString, setFilters]);

  // update filters upon changes in the text-search field
  useEffect(() => {
    if (search === undefined) {
    } else if (search === "") {
      setFilters((prev) => {
        const f = { ...prev };
        delete f.user_or_annotation;
        return f;
      });
    } else {
      setFilters({
        user_or_annotation: { operator: "contains", operand: search },
      });
    }
  }, [search, setFilters]);

  useEffect(() => {
    if (!filters?.user_or_annotation || !filters?.user_or_annotation.operand) {
      setSearch("");
    }
  }, [filters]);

  const resetFilters = () => {
    cancel();
    setSearch("");
    setFilters({});
  };

  if (!caps({ "analysis-model.clusters": { read: true } })) {
    return <NotAllowedMessage />;
  }

  return (
    <StyledProfilesViewContainer>
      <div className="filters-strip">
        <FiltersShowHideButton setOpenFilters={setShowFilters} />
        <FilterChips
          filters={filters}
          schema={filtersSchema(strings)}
          setFilters={setFilters}
        />
        <TextSearchInput searchPattern={search} setSearchPattern={setSearch} />
        <IconButton size="small" onClick={resetFilters}>
          <ClearIcon />
        </IconButton>
      </div>

      <ProfilesTable
        data={data}
        isPending={isPending}
        loadMoreItems={() => !isFetching && fetchNextPage()}
        orderBy={orderBy}
        onOrderBy={setNextOrderBy}
        pageLimit={pageLimit}
        scenario={selectedScenario.id}
      />

      <FiltersSection
        filters={filters}
        schema={filtersSchema(strings)}
        setFilters={setFilters}
        openFilters={showFilters}
        setOpenFilters={setShowFilters}
        top={35}
      />
    </StyledProfilesViewContainer>
  );
}
