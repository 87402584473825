import { useCallback, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material";

import AlertersAPI, {
  useAlertersGetAvailableAlertersQuery,
} from "api/alerters";

import { AlerterDetails } from "components/alerters/AlerterDetails";
import { AlerterDialog } from "components/alerters/AlerterDialog";
import { DeleteButton } from "components/ui/DeleteButton";
import Flexbox from "components/ui/Flexbox";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import NotAllowedMessage from "components/ui/NotAllowedMessage";
import { StdSwitch } from "components/ui/StdSwitch";
import { UiPrimaryButton } from "components/ui/StyledButtons";
import TableCellText from "components/ui/TableCellText";

import { useReportedFetch } from "hooks/http";
import { useEnhancedAPI } from "hooks/useAPI";
import useBackendEvents from "hooks/useBackendEvents";
import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";

function AlerterActions({ a, onToggleActive, onEdit, onDelete, caps }) {
  return (
    <Flexbox>
      <StdSwitch
        beforeLabel="YES"
        afterLabel="NO"
        checked={a.active}
        onChange={() => onToggleActive(a)}
        disabled={!caps({ "acm.alerters": { write: true } })}
      />
      <IconButton
        size="small"
        onClick={() => onEdit(a)}
        disabled={!caps({ "acm.alerters": { write: true } })}
      >
        <EditIcon />
      </IconButton>

      <DeleteButton
        onClick={() => onDelete(a)}
        disabled={!caps({ "acm.alerters": { write: true } })}
      />
    </Flexbox>
  );
}

const columns = (strings) => [
  {
    id: "actions",
    width: "4em",
    label: strings.account_settings_alerters_header_active,

    render: (a, onToggleActive, onEdit, onDelete, caps) => (
      <AlerterActions
        a={a}
        onToggleActive={onToggleActive}
        onEdit={onEdit}
        onDelete={onDelete}
        caps={caps}
      />
    ),
  },
  {
    id: "name",
    label: strings.account_settings_alerters_header_name,
    width: "8em",
    render: (a) => a.name,
  },
  {
    id: "description",
    label: strings.account_settings_alerters_header_description,
    width: "15em",
    render: (a) => a.description,
  },
  {
    id: "transport",
    label: strings.account_settings_alerters_header_type,
    width: "8em",
    render: (a) => a.transport.toUpperCase(),
  },
  {
    id: "format",
    label: strings.account_settings_alerters_header_format,
    width: "8em",
    render: (a) => a.format.toUpperCase(),
  },
  {
    id: "associated_scenarios",
    label: strings.account_settings_alerters_header_associated,
    width: "14em",
    render: (a) => (
      <Typography>{a.associated_scenarios?.length ?? 0}</Typography>
    ),
  },
  {
    id: "details",
    label: strings.account_settings_alerters_header_details,
    width: "auto",
    render: (a) => <AlerterDetails alerter={a} />,
  },
];

const StyledAlertersTable = styled("div")`
    height: calc(100vh - var(--appbar-height) - 49px);
    width: 70%;
    display: grid;
    min-width: 1100px;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    padding: 2em;
    gap: 1em;
    & .toolbar: {
      display: flex;
      alignItems: center;
      & button: {
        padding: 1em 2em;
      },
    },
    & .MuiTableContainer-root: {
      overflow: auto;
    },
  }
`;

const backendEventsOfInterest = ["alerters"];

function AccountAlerters() {
  const strings = useLocalizedStrings();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmContext, setConfirmContext] = useState();
  const [backendEvent] = useBackendEvents(backendEventsOfInterest);
  const [alerters] = useReportedFetch(AlertersAPI.alerters().url, [
    backendEvent,
  ]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currAlerter, setCurrAlerter] = useState(null);
  const api = useEnhancedAPI();
  const caps = useCapabilities();
  const isEditing = !!currAlerter;
  const { data: available } = useAlertersGetAvailableAlertersQuery();

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);

  const handleDelete = useCallback(
    (alerter) => {
      setConfirmContext(alerter);
      setConfirmOpen(true);
    },
    [setConfirmContext, setConfirmOpen]
  );

  const handleAdd = useCallback(() => {
    setCurrAlerter();
    setOpenDialog(true);
  }, []);

  const handleEdit = useCallback(
    (alerter) => {
      setCurrAlerter(alerter);
      setOpenDialog(true);
    },
    [setCurrAlerter, setOpenDialog]
  );

  const handleToggleActive = useCallback(
    (alerter) => {
      api(
        AlertersAPI.updateAlerter(alerter.id, {
          ...alerter,
          active: !alerter.active,
        })
      )
        .then(() => {})
        .catch(() => {});
    },
    [api]
  );

  const alerterOptionsInfo = useCallback(
    (alerter) => available?.find((a) => a.id === alerter.alerter_id)?.options,
    [available]
  );

  const handleCreateAlerter = useCallback(
    (alerter) => {
      setOpenDialog(false);

      const optionsInfo = alerterOptionsInfo(alerter);

      for (const k in alerter.options) {
        if (optionsInfo[k]) {
          const { secret = false } = optionsInfo[k];
          const trimmed =
            typeof alerter.options[k] === "string"
              ? alerter.options[k]?.trim()
              : alerter.options[k];

          alerter.options[k] =
            isEditing && trimmed === "" && secret ? null : trimmed;
        }
      }
      if (currAlerter) {
        // edit mode
        api(AlertersAPI.updateAlerter(currAlerter.id, alerter))
          .then(() => {})
          .catch(() => {});
      } else {
        // create mode
        api(AlertersAPI.addAlerter(alerter))
          .then(() => {})
          .catch(() => {});
      }
      setCurrAlerter(null);
    },
    [alerterOptionsInfo, currAlerter, isEditing, api]
  );

  const handleConfirm = useCallback(
    (ctx) => {
      api(AlertersAPI.deleteAlerter(ctx.id))
        .then(() => {})
        .catch(() => {});
    },
    [api]
  );

  if (!caps({ "acm.alerters": { read: true } })) {
    return <NotAllowedMessage />;
  }
  return (
    <StyledAlertersTable>
      <Flexbox>
        <div className="toolbar">
          <UiPrimaryButton
            startIcon={<AddCircleIcon />}
            onClick={handleAdd}
            disabled={!caps({ "acm.alerters": { write: true } })}
          >
            {strings.button_add}
          </UiPrimaryButton>
        </div>
      </Flexbox>

      <TableContainer>
        <Table stickyHeader>
          <colgroup>
            {columns(strings).map((c) => (
              <col key={c.id} style={{ width: c.width }} />
            ))}
          </colgroup>

          <TableHead>
            <TableRow>
              {columns(strings).map((c) => (
                <TableCell key={c.id}>
                  <TableCellText>
                    <strong>{c.label}</strong>
                  </TableCellText>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {alerters
              .sort((a, b) => a.id.localeCompare(b.id))
              .map((a) => (
                <TableRow key={a.id}>
                  {columns(strings).map((c) => (
                    <TableCell key={c.id}>
                      {c.render &&
                        c.render(
                          a,
                          handleToggleActive,
                          handleEdit,
                          handleDelete,
                          caps
                        )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AlerterDialog
        available={available ?? []}
        alerter={currAlerter}
        open={openDialog}
        key={openDialog}
        onClose={handleCloseDialog}
        onAccept={handleCreateAlerter}
      />

      <MultipleOptionsDialog
        confirmText={strings.button_remove}
        onConfirm={handleConfirm}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        context={confirmContext}
        title={strings.account_settings_alerters_remove_confirm_title}
        text={strings.formatString(
          strings.account_settings_alerters_remove_confirm_question,
          confirmContext?.associated_scenarios?.length ?? 0
        )}
      />
    </StyledAlertersTable>
  );
}

export default AccountAlerters;
