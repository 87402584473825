import FilterNoneIcon from "@mui/icons-material/FilterNone";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

const CopyToClipboardButton = ({ text }) => {
  const strings = useLocalizedStrings();
  const { pushMessage } = useMessages();

  const handleClick = () => {
    navigator.clipboard.writeText(text);
    pushMessage("success", strings.copyToClipboardMessage);
  };

  return (
    <FilterNoneIcon
      fontSize="small"
      color="disabled"
      onClick={handleClick}
      style={{ cursor: "pointer", fill: "gray", marginLeft: "5px" }}
    />
  );
};

export default CopyToClipboardButton;
