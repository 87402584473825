import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Zoom, styled } from "@mui/material";
import Alert from "@mui/material/Alert";

import { useMessages } from "hooks/useMessage";

const StyledMessages = styled(Box)(() => ({
  alignItems: "center",
  bottom: 0,
  left: 0,
  position: "fixed",
  width: "auto",
  zIndex: "1500",
  "& .messageWrapper": {
    display: "flex",
    flexDirection: "column",
    minWidth: "25rem",
  },
  ".popup-message": {
    display: "inline-block",
  },
}));

function PopupMessage({ message }) {
  const [open, setOpen] = useState(true);
  const { deleteMessage } = useMessages();

  const handleClose = () => {
    deleteMessage(message.id);
    setOpen(false);
  };

  return (
    <Zoom in={open}>
      <Box className="popup-message" pt={0.6}>
        <Alert
          variant="filled"
          severity={message.level}
          onClose={handleClose}
          sx={{
            ".MuiAlert-action": {
              alignItems: "center",
              padding: 0,
            },
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
              sx={{ color: "inherit" }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message.text}
        </Alert>
      </Box>
    </Zoom>
  );
}
function Messages() {
  const { messages } = useMessages();

  return (
    <StyledMessages p={1.5}>
      <div className="messageWrapper">
        {messages.reverse().map((m) => (
          <PopupMessage key={m.id} message={m} />
        ))}
      </div>
    </StyledMessages>
  );
}

export default Messages;
