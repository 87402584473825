import { createContext, useCallback, useContext, useMemo } from "react";

import { DISPLAY_MODES } from "@tiq/shared/lib/constants";

import { useScenarioFieldSettingsQuery } from "api/scenarios";

import { ScenariosContext } from "./ScenariosContext";

export const TagsVisibilityContext = createContext({});

export function TagsVisibilityContextProvider({ children }) {
  const { selectedScenario } = useContext(ScenariosContext);
  const { data, isPending } = useScenarioFieldSettingsQuery(
    selectedScenario?.id
  );

  const fields = useMemo(() => {
    if (isPending) {
      return new Map();
    }
    return new Map(
      data?.map((f) => [f.field, { visible: f.visible, favorite: f.favorite }])
    );
  }, [data, isPending]);

  const favoriteFields = useMemo(
    () =>
      data
        ?.filter((f) => f.favorite === DISPLAY_MODES.ALWAYS)
        .map((f) => f.field),
    [data]
  );

  const configuredFields = useMemo(() => {
    return Array.from(fields.keys());
  }, [fields]);

  const isFavoriteField = useCallback(
    (f) => {
      return fields.get(f)?.favorite === DISPLAY_MODES.ALWAYS;
    },
    [fields]
  );

  const isVisibleField = useCallback(
    (tag) => {
      const f = fields.get(tag);
      return f ? f.visible : true;
    },
    [fields]
  );

  return (
    <TagsVisibilityContext.Provider
      value={{
        isFavoriteField,
        isVisibleField,
        favoriteFields,
        configuredFields,
      }}
    >
      {children}
    </TagsVisibilityContext.Provider>
  );
}
