import { useMemo } from "react";

import { useCollectorsGetCollectorQuery } from "api/collectors";
import { useGetFilesQuery } from "api/files";

import ErrorBoundary from "components/ErrorBoundary";
import SubNavigator from "components/ui/SubNavigator";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import ConfigureTab from "./ConfigureTab";
import DataTab from "./DataTab";
import DiagnosticsTab from "./DiagnosticsTab";
import SchedulingTab from "./SchedulingTab";

const tabs = (strings) => [
  {
    id: "diagnostics",
    label: strings.collectors_tabs_diagnostics,
    route: "diagnostics",
    component: DiagnosticsTab,
    caps: { "acm.collectors": { read: true } },
    isDefault: true,
  },
  {
    id: "datatab",
    label: "data",
    route: "data",
    component: DataTab,
    caps: { "acm.collectors": { read: true } },
  },
  {
    id: "configure",
    label: strings.collectors_tabs_configure,
    route: "configure",
    component: ConfigureTab,
    caps: { "acm.collectors": { read: true } },
  },
  {
    id: "scheduling",
    label: strings.collectors_tabs_scheduling,
    route: "scheduling",
    component: SchedulingTab,
    caps: { "acm.collectors": { read: true } },
  },
];

const CollectorsContentArea = ({ selectedCollector, ...props }) => {
  const strings = useLocalizedStrings();
  const { data: collector } = useCollectorsGetCollectorQuery({
    id: selectedCollector,
    enabled: !!selectedCollector,
  });
  const { data: files } = useGetFilesQuery();

  const errorContext = useMemo(() => {
    return {
      selectedCollector: selectedCollector,
      collectorName: collector?.name,
    };
  }, [selectedCollector, collector?.name]);

  return (
    <ErrorBoundary context={errorContext}>
      <SubNavigator
        tabsSpec={tabs(strings)}
        routes={tabs(strings)}
        label={strings.collectors_tabs_label}
        collector={collector}
        files={files}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default CollectorsContentArea;
