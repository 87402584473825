import { useCallback, useEffect, useRef } from "react";

import { useHistory } from "react-router-dom";

import stdFetch from "utils/stdFetch";

import { useMessages } from "./useMessage";

function useAPI() {
  const abort = useRef();
  const history = useHistory();

  useEffect(() => {
    abort.current = new AbortController();
    const ab = abort.current;
    return () => {
      ab && ab.abort();
    };
  }, []);

  const f = useCallback(
    async ({ url, options }, settings) => {
      const opt = {
        signal: abort.current.signal,
        ...options,
      };
      const rows = await stdFetch(url, opt, settings);
      if (rows?.auth_url) {
        history.push("/?expired=1");
      }
      return rows;
    },
    [history]
  );

  return f;
}

export function useEnhancedAPI() {
  const { pushMessage } = useMessages();

  const api = useAPI();

  const f = useCallback(
    async ({ url, options }, settings) => {
      try {
        return await api({ url, options }, settings);
      } catch (err) {
        if (err.name !== "AbortError" && err.name !== "TypeError") {
          pushMessage("error", err.message);
        }
        throw err;
      }
    },
    [pushMessage, api]
  );

  return f;
}
