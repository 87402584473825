import React from "react";

import { ROLES } from "@tiq/shared/lib/constants";

import ScenariosContextProvider from "contexts/ScenariosContext";

import ErrorBoundary from "components/ErrorBoundary";
import SubNavigator from "components/ui/SubNavigator";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useRoles } from "hooks/useRoles";

import AccountAdmins from "./AccountAdmins";
import AccountAdvanced from "./AccountAdvanced";
import AccountAlerters from "./AccountAlerters";
import { AccountFiles } from "./AccountFiles";
import AccountUsage from "./AccountUsage";
import SystemLogTable from "./SystemLogTable";
import { CollectorsPage } from "./collectors/CollectorsPage";

const tabs = (strings, hasRoles) => [
  {
    id: "collectors",
    label: strings.account_settings_tabname_collectors,
    route: "collectors",
    component: CollectorsPage,
    caps: { "acm.collectors": { read: true } },
    isDefault: hasRoles([ROLES.ADMIN]),
  },
  {
    id: "files",
    label: strings.account_settings_tabname_files,
    route: "files",
    component: AccountFiles,
    caps: { "acm.files": { read: true } },
  },
  {
    id: "admins",
    label: strings.account_settings_tabname_accountusers,
    route: "admins",
    component: AccountAdmins,
    caps: { "acm.users": { write: true } },
    isDefault: hasRoles([ROLES.ACCOUNT_ADMIN]),
  },
  {
    id: "alerts",
    label: strings.account_settings_tabname_alerters,
    route: "alerts",
    component: AccountAlerters,
    caps: { "acm.alerters": { read: true } },
  },
  {
    id: "audis",
    label: "System Log",
    route: "system-log",
    component: SystemLogTable,
    visible: hasRoles([ROLES.ADMIN]),
  },
  {
    id: "advanced",
    label: strings.account_settings_tabname_advanced,
    route: "advanced",
    component: AccountAdvanced,
    visible: hasRoles([ROLES.ADMIN]),
  },
  {
    id: "usage",
    label: strings.account_settings_tabname_usage,
    route: "usage",
    tabStyle: { marginLeft: "auto" },
    component: AccountUsage,
    visible: hasRoles([ROLES.ADMIN]),
  },
];

function AccountSettings() {
  const strings = useLocalizedStrings();
  const { hasRoles } = useRoles();

  return (
    <ScenariosContextProvider>
      <ErrorBoundary>
        <SubNavigator
          tabsSpec={tabs(strings, hasRoles)}
          routes={tabs(strings, hasRoles)}
          label={strings.account_settings_navbar_label}
        />
      </ErrorBoundary>
    </ScenariosContextProvider>
  );
}

export default AccountSettings;
