import { useCallback } from "react";

import { useStore } from "store";

export default function useFeature(featureId) {
  const flag = useStore(
    useCallback(
      (state) => {
        return state?.featureFlags.find((flag) => flag.name === featureId);
      },
      [featureId]
    )
  );

  return flag ? flag.value : false;
}
