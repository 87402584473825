import { useEffect, useState } from "react";

import AuditAPI from "api/audit";
import ScenariosAPI from "api/scenarios";

import { useEnhancedAPI } from "hooks/useAPI";

export default function useBillingInfo(fromDate, toDate) {
  const [scenarios, setScenarios] = useState();
  const [events, setEvents] = useState([]);
  const api = useEnhancedAPI();

  useEffect(() => {
    api(ScenariosAPI.enumScenariosUrl())
      .then((rows) => setScenarios(rows))
      .catch(() => {});
  }, [api]);

  // load billing events
  useEffect(() => {
    if (!scenarios) {
      return;
    }
    api(AuditAPI.getUsage(fromDate, toDate))
      .then((rows) => {
        setEvents(
          rows.reduce((a, d) => {
            if (!a[d.scenario]) {
              // init scenario
              const scenarioDetails = scenarios.find(
                (s) => s.id === d.scenario
              );
              a[d.scenario] = {
                name: (scenarioDetails?.name || d.scenario).toUpperCase(),
                description: scenarioDetails?.description,
                events: [],
                total: 0,
                deleted: !scenarioDetails,
              };
            }
            a[d.scenario].events.push(d);
            a[d.scenario].total += +d.num_events;
            return a;
          }, {})
        );
      })
      .catch(() => {});
  }, [api, scenarios, fromDate, toDate]);

  return [events];
}
