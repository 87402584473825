import React, { useContext, useEffect, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import { Container, Divider, Grid, styled } from "@mui/material";
import { Link } from "react-router-dom";

import ScenariosAPI from "api/scenarios";
import SchedulerAPI from "api/scheduler";
import SourcesAPI from "api/sources";

import { ScenariosContext } from "contexts/ScenariosContext";

import FrameContent from "components/ui/FrameContent";
import FrameTitle from "components/ui/FrameTitle";
import FrameTitleText from "components/ui/FrameTitleText";
import HtmlTooltip from "components/ui/HtmlTooltip";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";

import { useEnhancedAPI } from "hooks/useAPI";
import useBackendEvents from "hooks/useBackendEvents";
import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import RunAction from "./RunAction";

const StyledAction = styled(Grid)(() => ({
  alignItems: "center",
  display: "flex",

  "& .hoverPointer": {
    cursor: "pointer",
  },
}));

export const Action = ({
  description,
  handler,
  disabled,
  buttonLabel,
  ButtonType = UiSecondaryButton,
  hasStatus,
  statusLabel,
}) => {
  return (
    <>
      <StyledAction item xl={8} xs={9} sx={{ justifyContent: "end" }}>
        <HtmlTooltip
          className="hoverPointer"
          title={disabled ? null : description}
          placement="left"
        >
          <span>
            <ButtonType
              onClick={handler}
              disabled={disabled}
              style={{ width: "12.5em" }}
            >
              {buttonLabel}
            </ButtonType>
          </span>
        </HtmlTooltip>
      </StyledAction>

      <StyledAction item xl={4} xs={3} sx={{ justifyContent: "start" }}>
        {hasStatus && statusLabel ? (
          <HtmlTooltip
            className="hoverPointer"
            title={statusLabel}
            placement="bottom"
          >
            <InfoIcon color="secondary" />
          </HtmlTooltip>
        ) : undefined}
      </StyledAction>
    </>
  );
};

const ConfirmableAction = ({
  description,
  handler,
  disabled,
  buttonLabel,
  ButtonType = UiPrimaryButton,
  hasStatus,
  statusLabel,
  dialogText,
  dialogTitle,
  confirmAction,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  return (
    <>
      <Action
        description={description}
        handler={handleOpen}
        disabled={disabled}
        buttonLabel={buttonLabel}
        ButtonType={ButtonType}
        hasStatus={hasStatus}
        statusLabel={statusLabel}
      />
      <MultipleOptionsDialog
        confirmText={confirmAction}
        onConfirm={handler}
        open={open}
        setOpen={setOpen}
        text={dialogText}
        title={dialogTitle}
      />
    </>
  );
};

const backendEventsOfInterest = ["jobs", "scenarios"];
function JobActions({ isRunning, onProgressStarted }) {
  const strings = useLocalizedStrings();
  const api = useEnhancedAPI();
  const caps = useCapabilities();
  const { pushMessage } = useMessages();
  const { selectedScenario } = useContext(ScenariosContext);
  const [scenario, setScenario] = useState();
  const [sources, setSources] = useState();
  const [backendNotification] = useBackendEvents(backendEventsOfInterest, [
    scenario?.id,
  ]);

  useEffect(() => {
    if (!selectedScenario?.id) {
      return;
    }

    api(SourcesAPI.enumSourcesUrl(selectedScenario?.id))
      .then((sources) => setSources(sources))
      .catch(() => {});
  }, [api, selectedScenario.id]);

  useEffect(() => {
    if (!selectedScenario?.id) {
      return;
    }

    api(ScenariosAPI.getScenario(selectedScenario?.id, false))
      .then((rows) => {
        const row = rows[0];
        setScenario(row);
      })
      .catch(() => {});
  }, [api, selectedScenario?.id, backendNotification]);

  const flags = {
    rescore_required: scenario?.rescore_required,
    reset_required: scenario?.reset_required,
    recluster_required: scenario?.recluster_required,
  };

  const handleRun = (params = null) => {
    let url = SchedulerAPI.runJobNow(scenario.id).url;
    if (params) {
      const queryParams = new URLSearchParams(params).toString();
      url = `${url}?${queryParams}`;
    }

    api({
      url,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({}),
      },
    })
      .then(() => onProgressStarted())
      .catch(() => {});
  };

  const handleRescore = () => {
    api(SchedulerAPI.rescoreJob(scenario.id))
      .then(() => {
        onProgressStarted();
      })
      .catch(() => {});
  };

  const handleRecluster = () => {
    api(SchedulerAPI.reclusterJob(scenario.id))
      .then(() => {
        onProgressStarted();
      })
      .catch(() => {});
  };

  const handleAbort = () => {
    api(SchedulerAPI.abortJob(scenario.id))
      .then(() => {
        pushMessage(
          "success",
          strings.scenariodiagnostics_jobprogress_abortrequested
        );
      })
      .catch(() => {});
  };

  const handleSendTestAlert = () => {
    api(SchedulerAPI.sendTestAlert(scenario.id))
      .then(() => {})
      .catch(() => {});
  };

  const handleReset = () => {
    api(ScenariosAPI.resetScenario(scenario.id))
      .then(() => {
        pushMessage(
          "success",
          strings.formatString(
            strings.scenariosettings_reset_success,
            scenario?.name || ""
          )
        );
        onProgressStarted();
      })
      .catch(() => {});
  };

  const handleRecalculate = () => {
    api(SchedulerAPI.recalculateJob(scenario.id))
      .then(() => {
        onProgressStarted();
      })
      .catch(() => {});
  };

  return (
    <>
      <FrameTitle>
        <FrameTitleText>
          {strings.scenariodiagnostics_actions_title}
        </FrameTitleText>
      </FrameTitle>
      <FrameContent p={12}>
        <Container disableGutters sx={{ marginTop: "30px" }}>
          <Grid
            alignItems="center"
            container
            justifyContent="center"
            spacing={3}
          >
            <RunAction
              scenario={scenario}
              sources={sources}
              handler={handleRun}
              disabled={
                isRunning || !caps({ "scheduler.jobs": { write: true } })
              }
            />
            <Action
              description={
                <>
                  {strings.scenariodiagnostics_testalerts_explanation}
                  <br />
                  <Link
                    to={{
                      pathname: `/scenarios/${scenario?.id}/settings`,
                      state: { alertersOpen: true },
                    }}
                    style={{
                      color: "steelblue",
                      textDecoration: "none",
                      fontSize: "inherit",
                    }}
                  >
                    {strings.scenariodiagnostics_testalerts_explanation2}
                  </Link>
                </>
              }
              handler={handleSendTestAlert}
              disabled={
                isRunning || !caps({ "scheduler.jobs": { write: true } })
              }
              buttonLabel={strings.scenariodiagnostics_testalerts_test}
            />
            <ConfirmableAction
              ButtonType={UiSecondaryButton}
              dialogTitle={
                strings.scenariodiagnostics_actions_rescore_dialog_title
              }
              dialogText={strings.formatString(
                strings.scenariodiagnostics_actions_rescore_dialog_text,
                scenario?.name || ""
              )}
              handler={handleRescore}
              buttonLabel={strings.scenariodiagnostics_actions_rescore_button}
              disabled={
                isRunning || !caps({ "scheduler.jobs": { write: true } })
              }
              hasStatus={flags?.rescore_required}
              statusLabel={
                strings.scenariodiagnostics_actions_rescore_status_label
              }
              confirmAction={strings.button_rescore}
            />
            <ConfirmableAction
              handler={handleRecluster}
              buttonLabel={strings.scenariodiagnostics_actions_recluster_button}
              disabled={
                isRunning || !caps({ "scheduler.jobs": { write: true } })
              }
              hasStatus={flags?.recluster_required}
              statusLabel={
                strings.scenariodiagnostics_actions_recluster_status_label
              }
              ButtonType={UiSecondaryButton}
              dialogTitle={
                strings.scenariodiagnostics_actions_recluster_dialog_title
              }
              dialogText={strings.formatString(
                strings.scenariodiagnostics_actions_recluster_dialog_text,
                scenario?.name || ""
              )}
              confirmAction={strings.button_recluster}
            />
            <Action
              handler={handleRecalculate}
              buttonLabel={strings.scenariodiagnostics_actions_recalc_button}
              description={
                <pre>
                  {strings.scenariodiagnostics_actions_recalc_description}
                </pre>
              }
              disabled={
                isRunning || !caps({ "scheduler.jobs": { write: true } })
              }
            />
            <Grid item xs={10}>
              <Divider />
            </Grid>
            <ConfirmableAction
              handler={handleReset}
              buttonLabel={strings.button_reset}
              disabled={
                isRunning || !caps({ "acm.scenarios": { write: true } })
              }
              hasStatus={flags?.reset_required}
              statusLabel={
                strings.scenariodiagnostics_actions_reset_status_label
              }
              ButtonType={UiSecondaryButton}
              dialogTitle={
                strings.scenariodiagnostics_actions_reset_dialog_title
              }
              dialogText={strings.formatString(
                strings.scenariodiagnostics_actions_reset_dialog_text,
                scenario?.name || ""
              )}
              confirmAction={strings.button_reset}
            />
            <ConfirmableAction
              description={strings.scenariodiagnostics_actions_abort_title}
              handler={handleAbort}
              buttonLabel={strings.scenariodiagnostics_jobprogress_abort}
              disabled={
                !isRunning || !caps({ "scheduler.jobs": { write: true } })
              }
              ButtonType={UiSecondaryButton}
              dialogTitle={
                strings.scenariodiagnostics_actions_abort_dialog_title
              }
              dialogText={strings.scenariodiagnostics_actions_abort_dialog_text}
              confirmAction={strings.button_abort}
            />
          </Grid>
        </Container>
      </FrameContent>
    </>
  );
}

export default JobActions;
