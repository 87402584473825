import { useCallback, useContext, useEffect, useMemo } from "react";

import { useStore } from "store";

import SchedulerAPI from "api/scheduler";

import { LoginStatusContext } from "contexts/LoginStatusContext";

import { useEnhancedAPI } from "./useAPI";
import { useCapabilities } from "./useCapabilities";
import { useDataExplorationState } from "./useScenarioJobState";

export const useJobPolling = () => {
  const { user } = useContext(LoginStatusContext);
  const { isDataExplorationJobActive } = useDataExplorationState();
  const setJobs = useStore((state) => state.setJobs);
  const api = useEnhancedAPI();
  const caps = useCapabilities();

  const jobPollingAllowed = useMemo(
    () => caps({ "scheduler.jobs": { read: true } }),
    [caps]
  );

  const getJobs = useCallback(async () => {
    if (!user?.id || user?.super_user) {
      return;
    }
    setJobs(await api(SchedulerAPI.getAllJobsUrl()));
  }, [api, setJobs, user?.id, user?.super_user]);

  useEffect(() => {
    if (!jobPollingAllowed) {
      return;
    }
    getJobs();
  }, [getJobs, jobPollingAllowed]);

  useEffect(() => {
    if (!jobPollingAllowed) {
      return;
    }
    const interval = setInterval(
      () => getJobs(),
      isDataExplorationJobActive ? 5_000 : 30_000
    );
    return () => clearInterval(interval);
  }, [getJobs, isDataExplorationJobActive, jobPollingAllowed]);
};
