import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import DataLink from "components/ui/DataLink";
import HtmlTooltip from "components/ui/HtmlTooltip";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import { useSessionSummaryFields } from "./SessionSummaryFields";

const ActionProperty = ({ property, num_values, top_values }) => {
  const strings = useLocalizedStrings();

  let values = top_values.map((v) => `${v.value} (${v.num_values})`).join(",");

  if (num_values > 5) {
    values += "...";
  }

  return (
    <Typography variant="body2">
      {property}
      <span style={{ color: "gray" }}>
        {strings.formatString(
          strings.action_properties_unique_values,
          num_values
        )}
      </span>
      {values}
    </Typography>
  );
};

const ActionProperties = ({ properties }) => {
  const strings = useLocalizedStrings();
  return (
    <div style={{ padding: "2px 5px" }}>
      <Typography variant="body1">{strings.action_properties_title}</Typography>
      <ul style={{ paddingLeft: "10px" }}>
        {properties.map((p) => (
          <li key={p.property} style={{ padding: "5px 2px" }}>
            <ActionProperty
              property={p.property}
              num_values={p.num_values}
              top_values={p.top_values}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export const ActionText = ({ description, action, translate, properties }) => {
  const { scenario, session } = useParams();
  const summaryFields = useSessionSummaryFields();
  let actionText = translate ? (
    <Typography fontSize={14}>
      {(description || action).replace(/\.$/, "")}
    </Typography>
  ) : (
    action.split("\t").map((a) => (
      <Typography fontSize={14} key={a}>
        {a}
      </Typography>
    ))
  );

  let tooltipContent, propertyFields;
  if (properties?.length) {
    tooltipContent = <ActionProperties properties={properties} />;
    propertyFields = properties.map((p) => p.field);
  }

  let actionDisplay = tooltipContent ? (
    <HtmlTooltip title={tooltipContent}>{actionText}</HtmlTooltip>
  ) : (
    actionText
  );

  if (translate) {
    let pathname = `/scenarios/${scenario}/sessions/${session}/session-timeline`;
    return (
      <DataLink
        to={{ pathname, state: { action, propertyFields, summaryFields } }}
      >
        {actionDisplay}
      </DataLink>
    );
  }

  return actionDisplay;
};
