import { useCallback, useContext } from "react";

import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { TagsVisibilityContext } from "contexts/TagsVisibilityContext";

import useFeature from "hooks/useFeature";

import API from "./apibase";
import { DEFAULT_STALE_TIME, downloadResponse, queryFetch } from "./query";

class SessionsAPI extends API {
  static getUrl({ filters, limit, offset, orderBy, scenario, mret = null }) {
    const f = JSON.parse(JSON.stringify(filters));
    if (f?.["num_sensitive_events"]) {
      f["num_sensitive_events"].operand *= -1;
    }
    const url = super.composeChunkedQueryUrl(
      `/api/analysis-model/v1/${scenario}/sessions`,
      offset,
      limit,
      f,
      orderBy,
      "sort_id",
      "desc"
    );
    const [base, query] = url.split("?");
    const p = new URLSearchParams(query);

    if (
      !p.has("whole_risk[ge]") &&
      (p.has("whole_risk[lt]") || p.has("whole_risk[le]"))
    ) {
      p.set("whole_risk[ge]", 0);
    } else if (
      !p.has("whole_risk[le]") &&
      (p.has("whole_risk[gt]") || p.has("whole_risk[ge]"))
    ) {
      p.set("whole_risk[le]", 100);
    }

    if (
      mret &&
      !p.has("start[le]") &&
      (p.has("start[gt]") || p.has("start[ge]"))
    ) {
      p.set("start[le]", mret);
    }
    if (mret && !p.has("end[le]") && (p.has("end[gt]") || p.has("end[ge]"))) {
      p.set("end[le]", mret);
    }

    if (
      !p.has("num_sensitive_events[ge]") &&
      (p.has("num_sensitive_events[lt]") || p.has("num_sensitive_events[le]"))
    ) {
      p.set("num_sensitive_events[ge]", -10);
    } else if (
      !p.has("num_sensitive_events[le]") &&
      (p.has("num_sensitive_events[gt]") || p.has("num_sensitive_events[ge]"))
    ) {
      p.set("num_sensitive_events[le]", 10);
    }

    return {
      url: `${base}?${p.toString()}`,
    };
  }

  static getAlerts({ filters, limit, offset, orderBy, scenario }) {
    return SessionsAPI.getUrl({
      scenario,
      offset,
      limit,
      orderBy,
      filters: {
        ...filters,
        alerted: { operator: "eq", operand: true },
      },
    });
  }

  static getSessionUrl({ scenario, session }) {
    return {
      url: `/api/analysis-model/v1/${scenario}/sessions/key/${session}`,
    };
  }

  static getActionsUrl({ session, scenario, format }) {
    let params = new URLSearchParams({
      limit: 10_000,
    });
    if (format) {
      params.set("format", format);
    }
    params = params.toString();

    return {
      url: `/api/analysis-model/v1/${scenario}/sessions/key/${session}/routes?${params}`,
    };
  }

  static getSessionsEvaluationsJsonUrl(sid) {
    return {
      url: `/api/analysis-model/v1/${sid}/sessions/export`,
    };
  }

  static postEvaluationsUpload({ scenario, sessionData }) {
    return {
      url: `/api/analysis-model/v1/${scenario}/sessions/import`,
      options: {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        body: sessionData,
      },
    };
  }

  static getUpdateSessionUrl({
    annotation,
    benign,
    close,
    malicious,
    scenario,
    session,
  }) {
    return {
      url: `/api/analysis-model/v1/${scenario}/sessions/key/${session}`,
      options: {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          annotation: annotation?.trim().length ? annotation.trim() : null,
          benign,
          close,
          malicious,
        }),
      },
    };
  }

  static getQuickCloseTicket({ benign, close, malicious, scenario, session }) {
    return SessionsAPI.getUpdateSessionUrl({
      annotation: undefined,
      benign,
      close,
      malicious,
      scenario,
      session,
    });
  }

  static getHistogram({ entity, scenario, session, type }) {
    return {
      url: `/api/analysis-model/v1/${scenario}/sessions/key/${session}/histograms?entity=${entity}&type=${type}`,
    };
  }

  static getSessionsSummary({ scenario }) {
    return { url: `/api/analysis-model/v1/${scenario}/sessions/summary` };
  }

  static getDailyAlerts({ maxRisk, minRisk, numDays, scenario }) {
    return {
      url: `/api/analysis-model/v1/${scenario}/sessions/daily-alerts?days=${
        numDays || 30
      }&min_risk=${minRisk ? +minRisk / 100 : 0}&max_risk=${
        maxRisk ? +maxRisk / 100 : 1
      }`,
    };
  }

  static getSessionSummary({ scenario, session, commonness }) {
    const params = new URLSearchParams({ commonness, properties: true });
    return {
      url: `/api/analysis-model/v1/${scenario}/sessions/key/${session}/summary?${params.toString()}`,
    };
  }

  static postEvaluationReviewNotification({
    analystId,
    notes,
    scenario,
    session,
  }) {
    return {
      url: `/api/acm/v1/users/key/${analystId}/session-notify`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          scenario,
          session,
          notes: notes || "",
        }),
      },
    };
  }

  static getSessionTags({ scenario, session, favoriteTags }) {
    const params = new URLSearchParams({ limit: "10000" });
    if (favoriteTags) {
      params.append("setting", "favorite");
    }
    return {
      url: `/api/analysis-model/v1/${scenario}/sessions/key/${session}/fields?${params.toString()}`,
    };
  }

  static postRelevanceFeedback({ scenario, session, action, feedback }) {
    return {
      url: `/api/analysis-model/v1/${scenario}/sessions/key/${session}/relevance-feedback`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          action,
          feedback,
        }),
      },
    };
  }
}

export default SessionsAPI;

const baseKey = ["sessions"];
const sessionKeys = {
  get: ({ scenario, filters, limit, offset, orderBy }) => [
    baseKey,
    scenario,
    "get",
    filters,
    limit,
    offset,
    orderBy,
  ],
  table: ({ scenario, filters, orderBy, mret, pageLimit }) => [
    baseKey,
    scenario,
    "table",
    filters,
    orderBy,
    mret,
    pageLimit,
  ],
  alerts: ({ scenario, filters, limit, offset, orderBy }) => [
    baseKey,
    scenario,
    "alerts",
    filters,
    limit,
    offset,
    orderBy,
  ],
  alertsTable: ({ scenario, filters, orderBy, pageLimit }) => [
    baseKey,
    scenario,
    "alerts",
    "table",
    pageLimit,
    filters,
    orderBy,
  ],
  allSummaries: ({ scenario }) => [baseKey, scenario, "allSummaries"],
  dailyAlerts: ({ scenario, minRisk, maxRisk, numDays }) => [
    baseKey,
    scenario,
    "dailyAlerts",
    maxRisk,
    minRisk,
    numDays,
  ],
  session: ({ scenario, session }) => [baseKey, scenario, session, "session"],
  sessionReferenceCount: ({ scenario, session }) => [
    baseKey,
    scenario,
    session,
    "ref-count",
  ],
  routes: ({ scenario, session }) => [baseKey, scenario, session, "routes"],
  histogram: ({ scenario, session, entity, type }) => [
    baseKey,
    scenario,
    session,
    "histogram",
    entity,
    type,
  ],
  summary: ({ scenario, session, commonness }) =>
    [baseKey, scenario, session, "summary", commonness].filter(Boolean),
  tags: ({ scenario, session, favoriteTags }) => [
    baseKey,
    scenario,
    session,
    "tags",
    favoriteTags,
  ],
};

export const useSessionsGetUrlQuery = ({
  filters,
  limit,
  offset,
  orderBy,
  scenario,
}) => {
  return useQuery({
    queryKey: sessionKeys.get({ scenario, filters, limit, offset, orderBy }),
    queryFn: () =>
      queryFetch(
        SessionsAPI.getUrl({ filters, limit, offset, orderBy, scenario })
      ),
  });
};

export const useSessionsGetUrlInfiniteQuery = ({
  filters,
  orderBy,
  scenario,
  mret,
}) => {
  const pageLimit = useFeature("ui_settings/sessions_table_page_limit");
  const queryClient = useQueryClient();
  const { data, fetchNextPage, isPending, isPreviousData, isFetching } =
    useInfiniteQuery({
      queryKey: sessionKeys.table({
        scenario,
        filters,
        orderBy,
        mret,
        pageLimit,
      }),
      initialPageParam: 1,
      queryFn: ({ pageParam, signal }) =>
        queryFetch(
          SessionsAPI.getUrl({
            filters,
            limit: pageLimit,
            offset: (pageParam - 1) * pageLimit,
            orderBy,
            scenario,
            mret,
          }).url,
          { signal },
          { returnRawJson: true }
        ),
      select: (data) => {
        const formattedRes = data.pages.reduce(
          (acc, curr) => {
            acc.count = curr?.count;
            acc.pages.push(curr.rows);
            return acc;
          },
          { count: 0, pages: [] }
        );

        const enrichedRes = formattedRes.pages.flat().map((s, idx) => ({
          ...s,
          id: s.app_session_id,
          index: idx,
          sensitivity: Math.abs(Math.min(s.num_sensitive_events, 0)),
        }));

        return {
          rows: enrichedRes,
          count: formattedRes?.count,
        };
      },
      retry: false,
      getNextPageParam: (lastPage, pages) =>
        lastPage.rows.length < pageLimit ? undefined : pages.length + 1,
      placeholderData: keepPreviousData,
    });

  const cancel = useCallback(
    () => queryClient.cancelQueries({ queryKey: [baseKey, scenario] }),
    [scenario, queryClient]
  );

  return {
    data,
    cancel,
    isPending: isPending || isPreviousData,
    isFetching,
    fetchNextPage,
  };
};

export const useSessionsGetAlertsQuery = ({
  filters,
  limit,
  offset,
  orderBy,
  scenario,
}) => {
  return useQuery({
    queryKey: sessionKeys.alerts({ scenario, filters, limit, offset, orderBy }),
    queryFn: () =>
      queryFetch(
        SessionsAPI.getAlerts({ filters, limit, offset, orderBy, scenario })
      ),
  });
};

export const useAlertsDailyTrendsQuery = ({
  scenarios,
  minRisk,
  maxRisk,
  numDays,
}) => {
  return useQueries({
    queries: scenarios.map((scenario) => ({
      queryKey: sessionKeys.dailyAlerts({
        scenario: scenario.id,
        minRisk,
        maxRisk,
        numDays,
      }),
      enabled: !!scenarios && !!scenario?.id,
      queryFn: () =>
        queryFetch(
          SessionsAPI.getDailyAlerts({
            scenario: scenario.id,
            minRisk,
            maxRisk,
            numDays,
          })
        ),
    })),
  });
};

export const useAlertsInfiniteQuery = ({
  filters,
  orderBy,
  scenario,
  pageLimit,
}) => {
  const queryClient = useQueryClient();
  const {
    data,
    fetchNextPage,
    isPending,
    isPreviousData,
    isFetching,
    refetch,
  } = useInfiniteQuery({
    queryKey: sessionKeys.alertsTable({
      scenario,
      pageLimit,
      filters,
      orderBy,
    }),
    initialPageParam: 1,
    queryFn: ({ pageParam, signal }) =>
      queryFetch(
        SessionsAPI.getAlerts({
          filters,
          limit: pageLimit,
          offset: (pageParam - 1) * pageLimit,
          orderBy,
          scenario,
        }).url,
        { signal },
        { returnRawJson: true }
      ),
    select: (data) => {
      const formattedRes = data.pages.reduce(
        (acc, curr) => {
          acc.count = curr?.count;
          acc.pages.push(curr.rows);
          return acc;
        },
        { count: 0, pages: [] }
      );

      const enrichedRes = formattedRes.pages.flat().map((s, idx) => ({
        ...s,
        id: s.app_session_id,
        index: idx,
        sensitivity: Math.abs(Math.min(s.num_sensitive_events, 0)),
      }));

      return {
        rows: enrichedRes,
        count: formattedRes?.count,
      };
    },
    retry: false,
    getNextPageParam: (lastPage, pages) =>
      lastPage.rows.length < pageLimit ? undefined : pages.length + 1,
    placeholderData: keepPreviousData,
  });

  const cancel = useCallback(
    () =>
      queryClient.cancelQueries({
        queryKey: sessionKeys.alertsTable({
          scenario,
          pageLimit,
          filters,
          orderBy,
        }),
      }),
    [queryClient, filters, orderBy, scenario, pageLimit]
  );

  return {
    data,
    refetch,
    cancel,
    isPending: isPending || isPreviousData,
    isFetching,
    fetchNextPage,
  };
};

export const useAlertsAnalyticsQuery = ({
  scenarios,
  filters,
  limit,
  offset,
  orderBy,
}) => {
  return useQueries({
    queries: scenarios.map((scenario) => ({
      queryKey: sessionKeys.alerts({
        scenario,
        filters,
        limit,
        offset,
        orderBy,
      }),
      queryFn: () =>
        queryFetch(
          SessionsAPI.getAlerts({
            filters,
            limit,
            offset,
            orderBy,
            scenario: scenario.id,
          })
        ),
    })),
  });
};

export const useSessionQuery = ({ session, scenario }, opts) => {
  return useQuery({
    queryKey: sessionKeys.session({ scenario, session }),
    queryFn: () => queryFetch(SessionsAPI.getSessionUrl({ scenario, session })),
    select: (res) => res[0],
    staleTime: DEFAULT_STALE_TIME,
    ...opts,
  });
};

export const useSessionsGetActionsUrlQuery = ({ session, scenario }) => {
  const { isVisibleField } = useContext(TagsVisibilityContext);

  return useQuery({
    queryKey: sessionKeys.routes({ scenario, session }),
    queryFn: () => queryFetch(SessionsAPI.getActionsUrl({ session, scenario })),
    select: (data) =>
      data.map((e) => {
        const tokens_filtered = e.tokens.filter((t) => isVisibleField(t.key));
        return {
          ...e,
          time: new Date(e.time).getTime(),
          sensitivity: Math.abs(Math.min(e.sensitivity, 0)),
          tokens_filtered,
        };
      }),
  });
};

export const useSessionDownloadActionsMutation = ({ session, scenario }) => {
  return useMutation({
    mutationFn: () =>
      queryFetch(
        SessionsAPI.getActionsUrl({ session, scenario, format: "csv" })
      ),
    onSuccess: (data) =>
      downloadResponse({
        data,
        type: "text/csv",
        fileName: `actions.${scenario}.${session}.csv`,
      }),
  });
};

export const useSessionReferencedCountQuery = ({ session, scenario }) => {
  return useQuery({
    queryKey: sessionKeys.sessionReferenceCount({ session, scenario }),
    queryFn: () =>
      queryFetch(
        SessionsAPI.getUrl({
          filters: {
            score_ref: {
              operand: session,
              operator: "eq",
            },
          },
          limit: 100,
          offset: 0,
          scenario,
        })
      ),
    enabled: !!session,
    staleTime: 10 * 60 * 60,
    select: (rows) => rows?.length,
  });
};

export const useSessionsUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ annotation, benign, close, malicious, scenario, session }) =>
      queryFetch(
        SessionsAPI.getUpdateSessionUrl({
          annotation,
          benign,
          close,
          malicious,
          scenario,
          session,
        })
      ),
    onSuccess: (_data, { scenario, session }) =>
      queryClient.invalidateQueries({
        queryKey: sessionKeys.session({ scenario, session }),
      }),
  });
};

export const useSessionsQuickCloseTicketMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ benign, close, malicious, scenario, session }) =>
      queryFetch(
        SessionsAPI.getQuickCloseTicket({
          benign,
          close,
          malicious,
          scenario,
          session,
        })
      ),
    onSuccess: (_data, { scenario, session }) =>
      queryClient.invalidateQueries({
        queryKey: sessionKeys.session({ scenario, session }),
      }),
  });
};

export const useSessionsGetHistogramQuery = (
  { entity, scenario, session, type },
  opts
) => {
  return useQuery({
    queryKey: sessionKeys.histogram({ scenario, session, entity, type }),
    queryFn: () =>
      queryFetch(SessionsAPI.getHistogram({ entity, scenario, session, type })),
    ...opts,
  });
};

export const useSessionsGetSessionsSummaryQuery = ({ scenario }) => {
  return useQuery({
    queryKey: sessionKeys.allSummaries({ scenario }),
    queryFn: () => queryFetch(SessionsAPI.getSessionsSummary({ scenario })),
  });
};

export const useSessionsGetDailyAlertsQuery = ({
  maxRisk,
  minRisk,
  numDays,
  scenario,
}) => {
  return useQuery({
    queryKey: sessionKeys.dailyAlerts({ scenario, maxRisk, minRisk, numDays }),
    queryFn: () =>
      queryFetch(
        SessionsAPI.getDailyAlerts({ maxRisk, minRisk, numDays, scenario })
      ),
  });
};
export const useSessionsGetSessionSummaryQuery = ({
  scenario,
  session,
  commonness,
}) => {
  return useQuery({
    queryKey: sessionKeys.summary({ scenario, session, commonness }),
    queryFn: () =>
      queryFetch(
        SessionsAPI.getSessionSummary({ scenario, session, commonness })
      ),
    select: (rows) => rows[0],
  });
};

export const useSessionsEvaluationReviewNotificationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ analystId, notes, scenario, session }) =>
      queryFetch(
        SessionsAPI.postEvaluationReviewNotification({
          analystId,
          notes,
          scenario,
          session,
        })
      ),
    onSuccess: ({ scenario, session }) =>
      queryClient.invalidateQueries({
        queryKey: sessionKeys.session({ scenario, session }),
      }),
  });
};

export const useSessionTagsQuery = ({
  scenario,
  session,
  favoriteTags,
  enabled,
}) => {
  return useQuery({
    queryKey: sessionKeys.tags({ scenario, session, favoriteTags }),
    queryFn: () =>
      queryFetch(
        SessionsAPI.getSessionTags({ scenario, session, favoriteTags })
      ),
    enabled,
  });
};

export const useSessionsEvaluationImportMutation = () =>
  useMutation({
    mutationFn: ({ scenario, sessionData }) =>
      queryFetch(SessionsAPI.postEvaluationsUpload({ scenario, sessionData })),
  });

export const useSessionsRelevanceFeedbackMutation = ({ scenario, session }) => {
  const queryClient = useQueryClient();
  const queryKey = sessionKeys.summary({
    scenario,
    session,
    commonness: true,
  });

  return useMutation({
    mutationFn: ({ scenario, session, action, feedback }) =>
      queryFetch(
        SessionsAPI.postRelevanceFeedback({
          action,
          feedback,
          scenario,
          session,
        })
      ),

    onMutate: ({ action, feedback }) => {
      const previousSummary = queryClient.getQueriesData(queryKey)[1];

      queryClient.setQueriesData(
        {
          queryKey: sessionKeys.summary({ scenario, session }),
        },
        (prev) => {
          const previousSummary = prev[0];

          const updatedSummaryFeedback = (
            previousSummary?.feedback ?? []
          ).filter((item) => item["action"] !== action);

          updatedSummaryFeedback.push({
            action,
            feedback,
          });

          const updatedSummary = {
            ...previousSummary,
            feedback: updatedSummaryFeedback,
          };

          return [updatedSummary];
        }
      );
      return { previousSummary };
    },

    onError: (_err, _mutateData, context) => {
      queryClient.setQueryData(queryKey, context.previousFeedback);
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: sessionKeys.summary({ scenario, session }),
      }),
  });
};
