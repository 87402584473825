import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ButtonGroup, FormControlLabel, styled } from "@mui/material";

import SchedulerAPI from "api/scheduler";

import SettingsNotifications from "components/scenarios/settings/SettingsNotifications";
import ActivationButton from "components/ui/ActivationButton";
import BigMessage from "components/ui/BigMessage";
import Footnotes from "components/ui/Footnotes";
import NicerCheckbox from "components/ui/NicerCheckbox";
import { UiSecondaryButton } from "components/ui/StyledButtons";

import Crontab from "utils/crontab";

import { useEnhancedAPI } from "hooks/useAPI";
import useBackendEvents from "hooks/useBackendEvents";
import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import TabHeader from "./TabHeader";

const StyledSchedulingTab = styled("div")`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1em;
  position: relative;
  form {
    // width: 90%;
    max-width: 1000px;
    display: grid;
    gap: 1.5em;
    padding-bottom: 1em;
    .scheduling-editor {
      display: grid;
      grid-template-columns: 8em 9em minmax(28em, 1fr) 14em;
      gap: 10px;
      .editor-section {
        position: relative;
        height: 100%;
        padding: 2em;
        background-color: rgba(0, 0, 0, 0.03);
        border-radius: 1em;
        &::after {
          content: attr(data-label);
          position: absolute;
          left: 1.5em;
          top: -0.7em;
          text-transform: uppercase;
          font-size: 0.8em;
          color: #777;
        }
      }
      .activation {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .days-selector {
        text-transform: uppercase;
      }
      .hours-selector {
        column-count: 4;
        column-width: 25%;
      }
      .quick-helpers {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 5px;
      }
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      & > * {
        flex-basis: 10em;
      }
    }
  }
  .notifications {
    // width: 90%;
    max-width: 1000px;
    .MuiFormGroup-root {
      padding-right: 0px;
    }
    .MuiBox-root {
      padding-right: 0px !important;
    }
  }
`;

const TimeCheckbox = (props) => {
  const { dataindex, ...rest } = props;
  return (
    <FormControlLabel
      control={<NicerCheckbox dataindex={dataindex} />}
      {...rest}
    />
  );
};

const HelperButton = (props) => {
  const { children, ...rest } = props;
  return (
    <UiSecondaryButton variant="outlined" {...rest}>
      {children}
    </UiSecondaryButton>
  );
};

const backendEventsOfInterest = ["schedules"];

const SchedulingTab = (props) => {
  const { collector } = props;
  const strings = useLocalizedStrings();
  const [cronJob, setCronJob] = useState();
  const [days, setDays] = useState(new Array(7).fill(false));
  const [hours, setHours] = useState(new Array(24).fill(false));
  const [enabled, setEnabled] = useState(false);
  const [changed, setChanged] = useState(false);
  const api = useEnhancedAPI();
  const caps = useCapabilities();
  const { pushMessage } = useMessages();
  const [backendEvent] = useBackendEvents(backendEventsOfInterest, [
    collector?.id,
  ]);
  const writeAllowed = caps({ "acm.collectors": { write: true } });

  const notime = useMemo(() => {
    const nodays = days.every((d) => !d);
    const nohours = hours.every((d) => !d);
    return nodays || nohours;
  }, [days, hours]);

  useEffect(() => {
    if (!collector) {
      setCronJob();
      return;
    }
    api(SchedulerAPI.getJobCronUrl(collector.id))
      .then((rows) => setCronJob(rows[0]))
      .catch((err) => {
        if (err.name !== "AbortError") {
          setCronJob();
        }
      });
  }, [collector, backendEvent, api]);

  const handleRevert = useCallback(() => {
    setChanged(false);
    if (!cronJob) {
      setDays(new Array(7).fill(false));
      setHours(new Array(24).fill(false));
      setEnabled(false);
      return;
    }
    const a = Crontab.getDaysAndHours(cronJob.schedule);
    setDays(a[0]);
    setHours(a[1]);
    setEnabled(cronJob.status === "enabled");
  }, [cronJob]);

  // handleRevert is changed whenever the cronJob is changed, which also
  // is a good time to invoke it in order to translate the new cronJob into
  // days, hours and activation state.
  useEffect(() => {
    handleRevert();
  }, [handleRevert]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const j = {
        ...cronJob,
        schedule: Crontab.fromDaysAndHours(days, hours),
        status: enabled && !notime ? "enabled" : "disabled",
      };
      try {
        if (cronJob) {
          await api(SchedulerAPI.updateCronjob(collector.id, j));
        } else {
          await api(SchedulerAPI.createCronjob(collector.id, j));
        }
        pushMessage(
          "success",
          strings.collectors_scheduling_saved_successfully
        );
        setChanged(false);
      } catch (err) {}
    },
    [
      days,
      hours,
      enabled,
      notime,
      strings,
      api,
      collector,
      pushMessage,
      cronJob,
    ]
  );

  const handleEnabledChanged = useCallback((e) => {
    setEnabled(e.target.checked);
    setChanged(true);
  }, []);

  const handleDaysChanged = useCallback((e, i) => {
    setDays((prev) => {
      const a = [...prev];
      a[i] = e.target.checked;
      return a;
    });
    setChanged(true);
  }, []);

  const handleHoursChanged = useCallback((e, i) => {
    setHours((prev) => {
      const a = [...prev];
      a[i] = e.target.checked;
      return a;
    });
    setChanged(true);
  }, []);

  const handleEveryDay = useCallback(() => {
    setDays((prev) => prev.map(() => true));
    setChanged(true);
  }, []);
  const handleEvery2Days = useCallback(() => {
    setDays((prev) => prev.map((_d, i) => i % 2 === 0));
    setChanged(true);
  }, []);
  const handleResetDays = useCallback(() => {
    setDays((prev) => prev.map(() => false));
    setChanged(true);
  }, []);
  const handleEveryHour = useCallback(() => {
    setHours((prev) => prev.map(() => true));
    setChanged(true);
  }, []);
  const handleEvery2Hours = useCallback(() => {
    setHours((prev) => prev.map((_h, i) => i % 2 === 0));
    setChanged(true);
  }, []);
  const handleEvery3Hours = useCallback(() => {
    setHours((prev) => prev.map((_h, i) => i % 3 === 0));
    setChanged(true);
  }, []);
  const handleResetHours = useCallback(() => {
    setHours((prev) => prev.map(() => false));
    setChanged(true);
  }, []);

  if (!collector) {
    return <BigMessage>{strings.collectors_no_selected}</BigMessage>;
  }

  return (
    <StyledSchedulingTab>
      <form onSubmit={handleSubmit}>
        <TabHeader
          collector={collector}
          name={strings.collectors_tabs_scheduling}
        />
        <hr />

        <div className="scheduling-editor">
          <div
            className="editor-section activation"
            data-label={strings.collectors_scheduling_section_label_activation}
          >
            <FormControlLabel
              checked={enabled}
              control={<ActivationButton colored={false} />}
              disabled={!writeAllowed}
              label="Active"
              onChange={handleEnabledChanged}
              style={{ marginLeft: 0 }}
            />
          </div>

          <div
            className="editor-section days-selector"
            data-label={strings.collectors_scheduling_section_label_days}
          >
            {days.map((_d, i) => (
              <TimeCheckbox
                checked={days[i]}
                dataindex={i}
                disabled={!writeAllowed}
                key={`day_${i}`}
                label={strings.scenariosettings_scheduling_days.split(",")[i]}
                onChange={(e) => handleDaysChanged(e, i)}
              />
            ))}
          </div>

          <div
            className="editor-section hours-selector"
            data-label={strings.collectors_scheduling_section_label_hours}
          >
            {hours.map((_d, i) => (
              <TimeCheckbox
                disabled={!writeAllowed}
                key={`hour_${i}`}
                label={`${i}:00`}
                checked={hours[i]}
                onChange={(e) => handleHoursChanged(e, i)}
                dataindex={i}
              />
            ))}
          </div>

          <div
            className="editor-section quick-helpers"
            data-label={strings.collectors_scheduling_section_label_helpers}
          >
            <ButtonGroup orientation="vertical" disabled={!writeAllowed}>
              <HelperButton onClick={handleEveryDay}>
                {strings.collectors_scheduling_helpers_everyday}
              </HelperButton>
              <HelperButton onClick={handleEvery2Days}>
                {strings.collectors_scheduling_helpers_every2days}
              </HelperButton>
              <HelperButton onClick={handleResetDays}>
                {strings.collectors_scheduling_helpers_resetdays}
              </HelperButton>
            </ButtonGroup>
            <ButtonGroup orientation="vertical" disabled={!writeAllowed}>
              <HelperButton onClick={handleEveryHour}>
                {strings.collectors_scheduling_helpers_everyhour}
              </HelperButton>
              <HelperButton onClick={handleEvery2Hours}>
                {strings.collectors_scheduling_helpers_every2hours}
              </HelperButton>
              <HelperButton onClick={handleEvery3Hours}>
                {strings.collectors_scheduling_helpers_every3hours}
              </HelperButton>
              <HelperButton onClick={handleResetHours}>
                {strings.collectors_scheduling_helpers_resethours}
              </HelperButton>
            </ButtonGroup>
          </div>
        </div>

        <div className="actions">
          <UiSecondaryButton onClick={handleRevert} disabled={!changed}>
            {strings.collectors_config_label_revert}
          </UiSecondaryButton>
          <UiSecondaryButton type="submit" disabled={!changed || notime}>
            {strings.collectors_config_label_save}
          </UiSecondaryButton>
        </div>
        <hr />

        <Footnotes
          notes={[
            changed ? strings.collectors_config_footnote : undefined,
            strings.collectors_scheduling_footnote,
          ]}
        />
      </form>

      <div className="notifications">
        <h2>{strings.scenariosettings_notifications_title}</h2>
        <SettingsNotifications fixedOption id={collector?.id} />
      </div>
    </StyledSchedulingTab>
  );
};

export default SchedulingTab;
