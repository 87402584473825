import { useMutation, useQuery } from "@tanstack/react-query";

import API from "./apibase";
import { queryFetch } from "./query";

export default class SourcesAPI extends API {
  static enumApplicationsUrl() {
    return { url: `/api/acm/v1/connectors` };
  }

  static enumSourcesUrl(sid) {
    return { url: `/api/acm/v1/scenarios/key/${sid}/sources` };
  }

  static createNewSource(scenario, source) {
    const trimmedOptions = Object.entries(source?.options || {})
      .map(([k, v]) => [k, typeof v === "string" ? v?.toString()?.trim() : v])
      .reduce((a, [k, v]) => ({ ...a, [k]: v }), {});
    return {
      url: `/api/acm/v1/scenarios/key/${scenario}/sources`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ ...source, options: trimmedOptions }),
      },
    };
  }

  static setSourceActive(id, active) {
    return {
      url: `/api/acm/v1/sources/key/${id}`,
      options: {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          active: !!active,
        }),
      },
    };
  }

  static updateSource({
    id,
    name,
    description,
    connector,
    options,
    active,
    offline_type,
    offline_bucket,
    offline_prefix,
    offline_mode,
  }) {
    const trimmedOptions = Object.entries(options || {})
      .map(([k, v]) => [k, typeof v === "string" ? v?.toString()?.trim() : v])
      .reduce((a, [k, v]) => ({ ...a, [k]: v }), {});
    return {
      url: `/api/acm/v1/sources/key/${id}`,
      options: {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          name: name?.trim(),
          description: description?.trim(),
          connector,
          options: trimmedOptions,
          active,
          offline_mode: !!offline_mode,
          offline_type: offline_type || "s3",
          offline_bucket: offline_bucket?.trim() || "",
          offline_prefix: offline_prefix?.trim() || "",
        }),
      },
    };
  }

  static deleteSource(id) {
    return {
      url: `/api/acm/v1/sources/key/${id}`,
      options: {
        method: "DELETE",
      },
    };
  }

  static uploadActionsInfo(source_id, file) {
    return {
      url: `/api/acm/v1/sources/key/${source_id}/action-info`,
      options: {
        method: "POST",
        headers: {
          "content-type": file.type,
        },
        body: file,
      },
    };
  }
}

const baseKey = ["sources"];
const sourcesKeys = {
  list: () => [baseKey, "list"],
  applications: () => [baseKey, "applications"],
};

export const useApplicationsQuery = () =>
  useQuery({
    queryKey: sourcesKeys.applications(),
    queryFn: queryFetch(SourcesAPI.enumApplicationsUrl()),
  });

export const useSourcesQuery = () =>
  useQuery({
    queryKey: sourcesKeys.list(),
    queryFn: queryFetch(SourcesAPI.enumSourcesUrl()),
  });

export const useCreateSourceQuery = (scenarioId, source) => {
  return useMutation({
    mutationFn: () =>
      queryFetch(SourcesAPI.createNewSource(scenarioId, source)),
  });
};

export const useSetSourceActiveQuery = ({ sourceId, active }) => {
  return useMutation({
    mutationFn: () => queryFetch(SourcesAPI.setSourceActive(sourceId, active)),
  });
};

export const useUpdateSourceQuery = ({ options }) => {
  return useMutation({
    mutationFn: () => queryFetch(SourcesAPI.updateSource(options)),
  });
};

export const useDeleteSourceQuery = ({ sourceId }) => {
  return useMutation({
    mutationFn: () => queryFetch(SourcesAPI.deleteSource(sourceId)),
  });
};

export const useUploadActionsInfoQuery = ({ sourceId, file }) => {
  return useMutation({
    mutationFn: () => queryFetch(SourcesAPI.uploadActionsInfo(sourceId, file)),
  });
};
