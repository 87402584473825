import { useEffect, useState } from "react";

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { useCollectorsUpdateMutation } from "api/collectors";

import FilteringTabComponent from "components/scenarios/MappingEditor/tabComponents/filtering/FilteringTabComponent";
import BrandedRadio from "components/ui/BrandedRadio";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";

import { useDataAnalysisColumns } from "hooks/useDataAnalysisColumns";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

const getDefaultValues = (collector) => {
  const newValues = {
    options: {
      internal_config: collector?.extra.internal_config ?? {
        filtering: {
          entries: [{ and_rules: [{ input_field: "", op: "=", value: "" }] }],
        },
      },
    },
  };
  return newValues;
};

export const DataAnalysisDialog = ({
  collector,
  fileSelection,
  fullExplorationLabel,
  onClose,
  onExplore,
  open,
  sampleExplorationLabel,
  filesExplorationTitle,
}) => {
  const strings = useLocalizedStrings();
  const [explorationType, setExplorationType] = useState(
    fileSelection === 0 ? "full" : "sample"
  );
  const [applyFilter, setApplyFilter] = useState(
    explorationType === "sample" ? false : true
  );
  const { getAnalysisColumns } = useDataAnalysisColumns();
  const updateCollectorMutation = useCollectorsUpdateMutation();

  const { pushMessage } = useMessages();

  useEffect(() => {
    if (!collector) {
      return;
    }
    const getColumns = async () =>
      await getAnalysisColumns(collector, { collector: true });
    getColumns();
  }, [getAnalysisColumns, collector]);

  const methods = useForm({
    mode: "onChange",
    defaultValues: getDefaultValues(collector),
  });

  const onSubmit = (data) => {
    const newData = {
      extra: {
        ...data.options,
      },
    };

    updateCollectorMutation.mutate(
      {
        id: collector.id,
        info: newData,
      },
      {
        onSuccess: () => {
          pushMessage("success", strings.collectors_config_saved_successfully);
          onExplore(explorationType, applyFilter);
          onClose();
        },
      }
    );
  };

  const watchedFiltering = methods.watch(
    `options.internal_config.filtering.entries.0.and_rules`
  );

  useEffect(() => {
    if (explorationType === "sample") {
      setApplyFilter(false);
    } else {
      setApplyFilter(true);
    }
  }, [explorationType, fileSelection, watchedFiltering]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <DialogTitle>
          {filesExplorationTitle ||
            strings.collectors_dataExploration_tab_explore_dialog_title}
        </DialogTitle>
        <DialogContent
          style={{
            maxHeight: explorationType === "sample" ? 150 : 500,
            width: 1000,
          }}
        >
          <RadioGroup
            onChange={(e) => setExplorationType(e.target.value)}
            row
            value={explorationType}
          >
            <FormControlLabel
              control={<BrandedRadio />}
              label={
                sampleExplorationLabel ||
                strings.collectors_dataExploration_tab_explore_dialog_sample_exploration
              }
              labelPlacement="end"
              value="sample"
              disabled={fileSelection === 0 && explorationType !== "sample"}
            />
            <FormControlLabel
              control={<BrandedRadio />}
              label={
                fullExplorationLabel ||
                strings.collectors_dataExploration_tab_explore_dialog_full_exploration
              }
              labelPlacement="end"
              value="full"
              disabled={!!fileSelection && explorationType !== "full"}
            />
          </RadioGroup>

          {explorationType === "full" && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyFilter}
                  color="primary"
                  disabled={false}
                  onChange={() => setApplyFilter(!applyFilter)}
                />
              }
              label={
                strings.collectors_dataExploration_tab_explore_dialog_apply_filter
              }
            />
          )}
          {explorationType === "sample" && (
            <Typography style={{ whiteSpace: "pre-wrap", marginTop: "10px" }}>
              {
                strings.collectors_dataExploration_tab_explore_dialog_sample_exploration_text
              }
            </Typography>
          )}
          {applyFilter && (
            <FormProvider {...methods}>
              <div style={{ marginTop: "20px" }}>
                <FilteringTabComponent />
              </div>
            </FormProvider>
          )}
        </DialogContent>
        <DialogActions>
          <UiSecondaryButton onClick={onClose}>
            {strings.button_cancel}
          </UiSecondaryButton>
          <UiPrimaryButton type="submit">
            {
              strings.collectors_dataExploration_tab_explore_dialog_explore_button
            }
          </UiPrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
