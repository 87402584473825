import React, { useCallback, useEffect, useMemo, useState } from "react";

import SchedulerAPI from "api/scheduler";

import ActivationButton from "components/ui/ActivationButton";
import HtmlTooltip from "components/ui/HtmlTooltip";

import { useEnhancedAPI } from "hooks/useAPI";
import useBackendEvents from "hooks/useBackendEvents";
import useLocalizedStrings from "hooks/useLocalizedStrings";

const backendEventsOfInterest = ["schedules"];

const CollectorActivationButton = ({ collectorId, inView, disabled }) => {
  const strings = useLocalizedStrings();
  const api = useEnhancedAPI();
  const [cronJob, setCronJob] = useState();
  const [backendEvent] = useBackendEvents(backendEventsOfInterest, [
    collectorId,
  ]);

  const isActive = useMemo(() => {
    if (!cronJob) {
      return false;
    }
    return cronJob.status === "enabled";
  }, [cronJob]);

  const tooltipText = useMemo(() => {
    return isActive
      ? strings.button_deactivate_job
      : strings.button_activate_job;
  }, [isActive, strings]);

  useEffect(() => {
    if (!inView || disabled) {
      return;
    }
    api(SchedulerAPI.getJobCronUrl(collectorId))
      .then((rows) => setCronJob(rows[0]))
      .catch(() => {});
  }, [collectorId, disabled, inView, backendEvent, api]);

  const handleActivationChanged = useCallback(
    (e) => {
      const j = {
        ...cronJob,
        status: e.target.checked ? "enabled" : "disabled",
      };
      api(SchedulerAPI.updateCronjob(collectorId, j))
        .then((rows) => setCronJob(rows[0]))
        .catch(() => {});
    },
    [cronJob, api, collectorId]
  );

  return (
    <HtmlTooltip
      title={tooltipText.split("\n").map((d, i) => (
        <p key={i}>{d}</p>
      ))}
    >
      <ActivationButton
        checked={isActive}
        onChange={handleActivationChanged}
        sx={{ color: "purple.dark" }}
        disabled={disabled}
      />
    </HtmlTooltip>
  );
};

export default CollectorActivationButton;
