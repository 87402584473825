import { useState } from "react";

import { Box, styled } from "@mui/material";

import SessionTokens from "components/sessions/SessionTokens";
import Flexbox from "components/ui/Flexbox";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useTags } from "hooks/useTags";

import { SensitivitySelector } from "./GradientSelectors";

const StyledSessionTokensPopularityChart = styled(Flexbox)(() => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  gap: "1em",
}));

function SessionTokensPopularityChart({ scenario, session }) {
  const strings = useLocalizedStrings();
  const [minSensitivity, setMinSensitivity] = useState(0);

  const { tags, isPending, isPendingTagTotals, insights, totals, actions } =
    useTags({
      scenario,
      session,
    });

  if (isPending) {
    return strings.text_loading2;
  }

  return (
    <>
      <StyledSessionTokensPopularityChart>
        <p>{strings.sessiondetails_tags_explanation1}</p>
        <p>{strings.sessiondetails_tags_explanation2}</p>
        <Flexbox pr={0} pl={0} mb={1} flexGrow={0} width={1}>
          <Box ml="auto">
            <SensitivitySelector
              minSensitivity={minSensitivity}
              setMinSensitivity={setMinSensitivity}
            />
          </Box>
        </Flexbox>
        <SessionTokens
          histograms={tags}
          totals={totals}
          isPendingTagTotals={isPendingTagTotals}
          minSensitivity={minSensitivity}
          scenario={scenario}
          insights={insights}
          actions={actions}
        />
      </StyledSessionTokensPopularityChart>
    </>
  );
}

export default SessionTokensPopularityChart;
