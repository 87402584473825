import { createContext, useContext } from "react";

const SessionSummaryFieldsContext = createContext();

export function SessionSummaryFieldsContextProvider({
  children,
  summaryFields,
}) {
  return (
    <SessionSummaryFieldsContext.Provider value={summaryFields}>
      {children}
    </SessionSummaryFieldsContext.Provider>
  );
}

export function useSessionSummaryFields() {
  return useContext(SessionSummaryFieldsContext);
}
