import { useCallback, useContext, useMemo } from "react";

import { Box } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useLocation } from "react-router-dom";

import { ScenariosContext } from "contexts/ScenariosContext";
import { TagsVisibilityContext } from "contexts/TagsVisibilityContext";

import HtmlTooltip from "components/ui/HtmlTooltip";
import RarityIndicator from "components/ui/RarityIndicator";
import SensitivityIndicator from "components/ui/SensitivityIndicator";

import { timeFormatter } from "utils/time-fmt";

import { useCurrentUserSettings } from "hooks/currentUserSettings";

const staticCols = [
  {
    field: "time",
    headerName: "Time",
    width: 200,
    hideable: false,
    display: "flex",
    valueGetter: (value) => timeFormatter(value),
  },
  {
    field: "sensitivity",
    headerName: "Sensitivity",
    width: 100,
    hideable: false,
    display: "flex",
    renderCell: (cell) => {
      if (!cell.row.match_info) {
        return <SensitivityIndicator value={cell.value} />;
      }
      return (
        <HtmlTooltip
          title={
            <div>
              <h4>Matched by:</h4>
              <pre style={{ fontFamily: "monospace" }}>
                {cell.row.match_info}
              </pre>
            </div>
          }
        >
          <SensitivityIndicator value={cell.value} />
        </HtmlTooltip>
      );
    },
  },
  {
    field: "rarity_score",
    headerName: "Rarity",
    width: 100,
    hideable: false,
    display: "flex",
    renderCell: (cell) => <RarityIndicator value={cell.value} />,
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    hideable: true,
    valueGetter: (value) => value,
  },
];

export const TimelineDataGrid = ({
  items,
  presentFields,
  actionKeys,
  isPending,
}) => {
  const { configuredFields, isFavoriteField } = useContext(
    TagsVisibilityContext
  );
  const { selectedScenario } = useContext(ScenariosContext);
  const apiRef = useGridApiRef();
  const [storedColumns, setStoredColumns] = useCurrentUserSettings(
    `timeline-table-${selectedScenario?.id}`,
    {}
  );
  const location = useLocation();
  const showOnlyFavoriteFields =
    !!location?.state?.action || !!location?.state?.summaryFields;
  const propertyFields = location?.state?.propertyFields;
  const summaryFields = location?.state?.summaryFields;

  const saveState = useCallback(() => {
    if (!apiRef.current) {
      return;
    }
    const currentColumns = apiRef.current.exportState().columns;
    const pinnedColumns = apiRef.current.exportState().pinnedColumns;

    setStoredColumns({ currentColumns, pinnedColumns });
  }, [apiRef, setStoredColumns]);

  const dynamicCols = useMemo(
    () =>
      configuredFields.map((field) => {
        return {
          field,
          headerName: field,
          headerClassName: actionKeys?.has(field) ? "action" : "annotation",
          width: 150,
        };
      }),
    [actionKeys, configuredFields]
  );

  const columns = useMemo(() => {
    const cols = [...staticCols, ...dynamicCols];
    const order = storedColumns?.currentColumns?.orderedFields;
    if (!order) {
      return cols;
    }
    return cols.sort((a, b) => order.indexOf(a.field) - order.indexOf(b.field));
  }, [dynamicCols, storedColumns]);

  const initialColumns = useMemo(() => {
    let columnVisibilityModel = {};
    if (showOnlyFavoriteFields) {
      for (const { field } of dynamicCols) {
        const hasFav = isFavoriteField(field);
        const hasProp = !!propertyFields?.includes(field);
        const onSummary = !!summaryFields?.includes(field);
        columnVisibilityModel[field] = hasFav || hasProp || onSummary;
      }
    } else {
      for (const { field } of dynamicCols) {
        columnVisibilityModel[field] = presentFields.has(field);
      }
    }
    for (const { field } of staticCols) {
      columnVisibilityModel[field] = true;
    }

    return {
      ...storedColumns.currentColumns,
      columnVisibilityModel,
    };
  }, [
    presentFields,
    showOnlyFavoriteFields,
    storedColumns,
    dynamicCols,
    isFavoriteField,
    propertyFields,
    summaryFields,
  ]);

  const rows = useMemo(() => {
    return items.map((i) => {
      const row = i.tokens_filtered.reduce((acc, r) => {
        acc[r.key] = r.value[0];
        return acc;
      }, {});
      row.id = i.id;
      row.sensitivity = i.sensitivity;
      row.rarity_score = i.rarity_score;
      row.time = i.time;
      row.description = i.description.replace(/\.$|\t/g, "");
      row.match_info = i.match_info.match;
      return row;
    });
  }, [items]);

  return (
    <Box sx={{ position: "relative", width: "100%", overflowX: "scroll" }}>
      <DataGridPro
        key={isPending}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "700",
            textTransform: "capitalize",
          },
          "& .action": {
            color: "steelblue",
          },
          "& .MuiDataGrid-cell": {
            padding: "10px 10px",
          },
        }}
        slotProps={{
          panel: {
            sx: {
              "& .MuiDataGrid-panelWrapper": {
                maxHeight: "30vh",
              },
            },
          },
        }}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        onColumnVisibilityModelChange={saveState}
        onColumnOrderChange={saveState}
        onPinnedColumnsChange={saveState}
        getRowHeight={() => "auto"}
        initialState={{
          columns: initialColumns,
          pinnedColumns: storedColumns?.pinnedColumns,
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        loading={isPending}
        pageSizeOptions={[100]}
        disableColumnFilter
        pagination
        disableRowSelectionOnClick
      />
    </Box>
  );
};
