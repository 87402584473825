import { useContext, useEffect, useMemo, useState } from "react";

import {
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  styled,
} from "@mui/material";
import { Grid } from "@mui/material";
import { Paper } from "@mui/material";
import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

import ScenariosAPI from "api/scenarios";
import SchedulerAPI from "api/scheduler";

import { ScenariosContext } from "contexts/ScenariosContext";

import BrandedRadio from "components/ui/BrandedRadio";
import Flexbox from "components/ui/Flexbox";
import Footnotes from "components/ui/Footnotes";
import FrameActions from "components/ui/FrameActions";
import FrameContent from "components/ui/FrameContent";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";

import Crontab from "utils/crontab";

import { useReportedFetch } from "hooks/http";
import { useEnhancedAPI } from "hooks/useAPI";
import useBackendEvents from "hooks/useBackendEvents";
import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import SettingsOption from "./SettingsOption";

const StyledSettingsScheduling = styled(FrameContent)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  padding: "16px",
  "& .day": {
    width: "10em",
  },
  "& .timesList": {
    columnCount: 4,
    lineHeight: 0,
    "& .MuiListItem-root": {
      padding: "0 5px",
    },
  },
  "& .actions": {
    justifyContent: "center",
    "& > *": {
      marginBottom: theme.spacing(1),
    },
  },
  "& .dataTitle": {
    backgroundColor: theme.palette.grey.light,
    fontStyle: "italic",
    fontWeight: "bold",
    margin: "2px",
    padding: theme.spacing(0.5),
    textAlign: "center",
  },
  "& .controls": {
    maxWidth: "800px",
  },
  "& .crontabDisplay": {
    alignContent: "flex-start",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: `${theme.spacing(4)} ${theme.spacing(1)} ${theme.spacing(
      1
    )} ${theme.spacing(1)}`,
    "& code": {
      backgroundColor: "#555",
      borderRadius: theme.shape.borderRadius,
      color: "white",
      fontSize: "11pt",
      padding: "10px 2em",
    },
    "& a": {
      color: "inherit",
      marginLeft: theme.spacing(1),
      textDecoration: "none",
      "& .MuiSvgIcon-root": {
        fontSize: "14pt",
      },
    },
  },
}));

function DataTitle({ children }) {
  return (
    <Typography className="dataTitle" variant="subtitle2">
      {children}
    </Typography>
  );
}

function DaysSelector({ days, setDays }) {
  const caps = useCapabilities();
  const strings = useLocalizedStrings();
  const handleChange = (e) => {
    let d = [...days];
    d[e.target.name] = e.target.checked;
    setDays(d);
  };
  return (
    <Paper elevation={0}>
      <DataTitle>Days</DataTitle>
      <Box pl={1}>
        {strings.scenariosettings_scheduling_days.split(",").map((d, idx) => (
          <FormControlLabel
            className="day"
            key={d}
            control={
              <Checkbox
                checked={days[idx]}
                onChange={handleChange}
                name={`${idx}`}
                disabled={!caps({ "scheduler.jobs": { write: true } })}
              />
            }
            label={d.toUpperCase()}
          />
        ))}
      </Box>
    </Paper>
  );
}

const hours = Array.from(Array(24).keys());

function TimesList({ times, setTimes }) {
  const caps = useCapabilities();
  const hour24 = (v) => (v < 10 ? `0${v}:00` : `${v}:00`);

  const handleChange = (e) => {
    let t = [...times];
    t[e.target.name] = e.target.checked;
    setTimes(t);
  };
  return (
    <List className="timesList">
      {hours.map((h) => (
        <ListItem button key={h}>
          <FormControlLabel
            control={
              <Checkbox
                checked={times[h]}
                onChange={handleChange}
                name={`${h}`}
                disabled={!caps({ "scheduler.jobs": { write: true } })}
              />
            }
            label={hour24(h)}
          />
        </ListItem>
      ))}
    </List>
  );
}

function TimesSelector({ times, setTimes }) {
  return (
    <Paper elevation={0}>
      <DataTitle>Hours</DataTitle>
      <TimesList times={times} setTimes={setTimes} />
    </Paper>
  );
}

function CronjobEnable({ enabled, setEnabled }) {
  const caps = useCapabilities();
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={enabled}
          onChange={(e) => setEnabled(e.target.checked)}
          name="enable"
          disabled={!caps({ "scheduler.jobs": { write: true } })}
        />
      }
      label="Enabled"
    />
  );
}

function SchedulingMode({ syncMode, handleSchedulingChange }) {
  const strings = useLocalizedStrings();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
        width: "500px",
      }}
    >
      <FormLabel sx={{ color: "black", fontWeight: "bold" }}>
        {strings.scenariosettings_scheduling_title}
      </FormLabel>
      <FormControl>
        <RadioGroup
          row
          name="scheduling-mode"
          value={syncMode}
          onChange={handleSchedulingChange}
        >
          <FormControlLabel
            value={false}
            control={<BrandedRadio />}
            label={strings.scenariosettings_scheduling_config_label}
          />
          <FormControlLabel
            value={true}
            control={<BrandedRadio />}
            label={strings.scenariosettings_scheduling_sync_label}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

function ActionButton({ children, ...other }) {
  const caps = useCapabilities();
  return (
    <UiSecondaryButton
      variant="outlined"
      size="small"
      disabled={!caps({ "scheduler.jobs": { write: true } })}
      {...other}
    >
      {children}
    </UiSecondaryButton>
  );
}

const backendEventsOfInterest = ["schedules"];

const empty = (n, fill) => new Array(n).fill(fill);

export default function SettingsScheduling() {
  const strings = useLocalizedStrings();
  const { selectedScenario } = useContext(ScenariosContext);
  const [backendEvent] = useBackendEvents(backendEventsOfInterest, [
    selectedScenario.id,
  ]);
  const api = useEnhancedAPI();
  const { pushMessage } = useMessages();
  const [days, setDays] = useState(empty(7, false));
  const [times, setTimes] = useState(empty(24, false));
  const [enabled, setEnabled] = useState(false);
  const [changed, setChanged] = useState(false);
  const [syncMode, setSyncMode] = useState(
    selectedScenario?.is_sync_with_sources ?? false
  );
  const [[cronjob]] = useReportedFetch(
    SchedulerAPI.getJobCronUrl(selectedScenario.id).url,
    [selectedScenario.id, changed, backendEvent]
  );

  const notime = useMemo(
    () => days.every((d) => !d) || times.every((t) => !t),
    [days, times]
  );
  const caps = useCapabilities();

  useEffect(() => {
    setSyncMode(selectedScenario?.is_sync_with_sources);
  }, [selectedScenario]);

  // disable if no days or hours selected
  useEffect(() => {
    if (enabled && notime) {
      setEnabled(false);
    }
  }, [notime, enabled]);

  useEffect(() => {
    setChanged(true);
  }, [days, times, enabled]);

  // update helper states whenever cronjob changed
  useEffect(() => {
    if (!cronjob) {
      setDays(empty(7, false));
      setTimes(empty(24, false));
      setEnabled(false);
      return;
    }
    const s =
      cronjob.schedule === Crontab.HALF_HOUR
        ? Crontab.makeDefault()
        : cronjob.schedule;
    const [_days, _hours] = Crontab.getDaysAndHours(s);
    setDays(_days);
    setTimes(_hours);
    setEnabled(cronjob.status === "enabled");
  }, [cronjob]);

  const handleSchedulingChange = (e) =>
    setSyncMode(e.target.value === "false" ? false : true);

  const handleResetDays = () => setDays(empty(7, false));

  const handleAllDays = () => setDays(empty(7, true));

  const handleResetTimes = () => setTimes(empty(24, false));

  const handleHourly = () => setTimes(empty(24, true));

  const handleEveryTwoHours = () =>
    setTimes(empty(24, false).map((_, h) => h % 2 === 0));

  const handleEveryThreeHours = () =>
    setTimes(empty(24, false).map((_, h) => h % 3 === 0));

  const handleSave = async () => {
    const jobInfo = {
      ...cronjob,
      schedule: syncMode
        ? Crontab.HALF_HOUR
        : Crontab.fromDaysAndHours(days, times),
      status: enabled && !notime ? "enabled" : "disabled",
    };

    try {
      await api(
        ScenariosAPI.updateScenario(selectedScenario.id, {
          description: selectedScenario.description,
          extra: selectedScenario.extra,
          name: selectedScenario.name,
          generate_profile_details: selectedScenario.generate_profile_details,
          users_activity_timeframe: selectedScenario.users_activity_timeframe,
          siem_id: selectedScenario.siem_id ?? "",
          is_production: selectedScenario.is_production,
          is_sync_with_sources: syncMode,
        })
      );
      await (cronjob
        ? api(SchedulerAPI.updateCronjob(selectedScenario.id, jobInfo))
        : api(SchedulerAPI.createCronjob(selectedScenario.id, jobInfo)));
      pushMessage("success", strings.scenariosettings_scheduling_success);
      setChanged(false);
    } catch {}
  };

  if (!caps({ "scheduler.jobs": { read: true } })) {
    return null;
  }

  return (
    <SettingsOption title={strings.scenariosettings_scheduling_title}>
      <StyledSettingsScheduling>
        <div style={{ width: "100%", paddingLeft: "12px" }}>
          <CronjobEnable enabled={enabled} setEnabled={setEnabled} />
          <SchedulingMode
            syncMode={syncMode}
            handleSchedulingChange={handleSchedulingChange}
          />
        </div>
        {!syncMode && (
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <DaysSelector days={days} setDays={setDays} />
            </Grid>
            <Grid item xs={6}>
              <TimesSelector times={times} setTimes={setTimes} />
            </Grid>
            <Grid item xs={3}>
              <Flexbox
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-end"
                style={{ height: "100%", width: "100%" }}
              >
                <FormGroup className="actions">
                  <ActionButton onClick={handleAllDays}>
                    {strings.scenariosettings_scheduling_button_everyday}
                  </ActionButton>
                  <ActionButton onClick={handleResetDays}>
                    {strings.scenariosettings_scheduling_button_resetdays}
                  </ActionButton>
                  <br />
                  <ActionButton onClick={handleHourly}>
                    {strings.scenariosettings_scheduling_button_everyhour}
                  </ActionButton>
                  <ActionButton onClick={handleEveryTwoHours}>
                    {strings.scenariosettings_scheduling_button_every2hours}
                  </ActionButton>
                  <ActionButton onClick={handleEveryThreeHours}>
                    {strings.scenariosettings_scheduling_button_every3hours}
                  </ActionButton>
                  <ActionButton onClick={handleResetTimes}>
                    {strings.scenariosettings_scheduling_button_resethours}
                  </ActionButton>
                </FormGroup>
              </Flexbox>
              <Footnotes notes={[strings.collectors_scheduling_footnote]} />
            </Grid>
          </Grid>
        )}
      </StyledSettingsScheduling>
      <FrameActions>
        <UiPrimaryButton
          disabled={!changed || !caps({ "scheduler.jobs": { write: true } })}
          onClick={handleSave}
        >
          {strings.button_save}
        </UiPrimaryButton>
      </FrameActions>
    </SettingsOption>
  );
}
