import { useContext, useEffect, useMemo, useRef, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Typography, styled, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";

import { useScenarioParameterKeyQuery } from "api/scenarioparams";
import SessionsAPI, { useSessionsGetUrlInfiniteQuery } from "api/sessions";

import { ScenariosContext } from "contexts/ScenariosContext";

import DataLink from "components/ui/DataLink";
import EmbeddedTagsView from "components/ui/EmbeddedTagsView";
import HtmlTooltip from "components/ui/HtmlTooltip";
import RiskIndicator from "components/ui/RiskIndicator";
import SensitivityIndicator from "components/ui/SensitivityIndicator";
import { StyledFilterButtonsGroup } from "components/ui/StyledButtonsGroup";
import { VirtuosoTable } from "components/ui/VirtuosoTable";

import FilterOperators from "utils/FilterOperators";
import { baseUrl } from "utils/baseUrl";
import stdFetch from "utils/stdFetch";
import { timeFormatter } from "utils/time-fmt";
import { formatInterval, timeInterval } from "utils/time-fmt";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import { useStateDeep } from "hooks/deepcomp";
import useCurrentUrlState from "hooks/useCurrentUrlState";
import useFeature from "hooks/useFeature";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useLocationChanged } from "hooks/useLocationChanged";

import FiltersSection from "./FiltersSection";
import FreeSearchStrip from "./FreeSearchStrip";
import { ProfileEvaluationIndicator } from "./ProfileEvaluationIndicator";
import { CommentIndicator } from "./SessionCommentIndicator";
import { SessionEvaluationIndicator } from "./SessionEvaluationIndicator";
import { SessionLocalDownloader } from "./SessionLocalDownloader";

const operators = FilterOperators;

const filtersSchema = (strings) =>
  Object.entries({
    start: {
      label: strings.sessionstable_filters_start,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    end: {
      label: strings.sessionstable_filters_end,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    cluster: {
      label: strings.profile,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    orig_app_user_id: {
      label: strings.sessionstable_filters_user,
      type: "text",
      operators: [
        operators["eq"],
        operators["contains"],
        operators["not-contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
    device: {
      label: strings.sessionstable_filters_device,
      type: "text",
      operators: [
        operators["eq"],
        operators["contains"],
        operators["not-contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
    num_events: {
      label: strings.sessionstable_filters_size,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    num_sensitive_events: {
      label: strings.sessionstable_filters_sensitivity,
      type: "number",
      operators: [
        //reversed operators because sensitivity is negative on backend
        { id: "lt", label: ">", op: "lt" },
        { id: "le", label: ">=", op: "le" },
        { id: "gt", label: "<", op: "gt" },
        { id: "ge", label: "<=", op: "ge" },
        operators["eq"],
        operators["ne"],
      ],
    },
    whole_risk: {
      label: strings.sessionstable_filters_risk,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    benign: {
      label: strings.sessionstable_filters_benign,
      type: "boolean",
      visible: false,
      operators: [operators["eq"], operators["ne"]],
    },
    malicious: {
      label: strings.sessionstable_filters_malicious,
      type: "boolean",
      visible: false,
      operators: [operators["eq"], operators["ne"]],
    },
    alerted: {
      label: strings.sessionstable_filters_alerted,
      type: "boolean",
      visible: false,
      operators: [operators["eq"], operators["ne"]],
    },
    time_alerted: {
      label: strings.sessionstable_filters_time_alerted,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    annotation: {
      label: strings.sessionstable_filters_comment,
      type: "text",
      operators: [
        operators["defined"],
        operators["eq"],
        operators["ne"],
        operators["contains"],
        operators["not-contains"],
      ],
    },
    ts_user_or_annotation: {
      label: strings.sessionstable_filters_user_or_comment,
      type: "text",
      visible: false,
      operators: [operators["text-search"]],
    },
    score_ref: {
      label: strings.sessionstable_filter_score_ref,
      type: "text",
      operators: [operators["eq"]],
    },
    app_session_id: {
      label: strings.sessionstable_filter_session_ID,
      type: "text",
      operators: [operators["eq"], operators["in"]],
    },
    nearest_distance: {
      label: strings.sessionstable_filters_nearest_distance,
      type: "number",
      inputProps: { step: "0.001" },
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
      ],
    },
    ts_hist_locations: {
      label: strings.sessionstable_filters_session_actions,
      type: "text",
      operators: [operators["text-search"]],
    },
    alert_info: {
      label: strings.sessionstable_filters_sent,
      type: "select",
      default_value: "All",
      dropdown_options: [
        {
          id: "all",
          label: "All",
          value: "All",
        },
        {
          id: "true",
          label: "True",
          value: "0",
          operator: operators["gt"],
        },
        {
          id: "false",
          label: "False",
          value: "1",
          operator: operators["le"],
        },
      ],
      operators: [operators["gt"]],
    },
  });

const filterPresets = (strings, minRisk) => ({
  risks: {
    label: strings.formatString(
      strings.sessionstable_filter_presets_risky,
      minRisk
    ),
    whole_risk: { operator: "ge", operand: minRisk },
  },
  typical: {
    cluster: { operator: "gt", operand: 0 },
  },
  atypical: {
    cluster: { operator: "le", operand: 0 },
  },
  benign: {
    benign: { operator: "eq", operand: true },
  },
  malicious: {
    malicious: { operator: "eq", operand: true },
  },
  alerted: {
    alerted: { operator: "eq", operand: true },
  },
  evaluated: {
    annotation: { operator: "notnull", operand: null },
  },
});

const formatTooltipText = ({ cluster, benign, malicious }, strings) => {
  if (cluster > 0) {
    if (benign) {
      return strings.sessionstable_session_action_tooltip_session_evaluation_benign;
    }

    if (malicious) {
      return strings.sessionstable_session_action_tooltip_details_evaluation_malicious;
    }
  }
  return null;
};

const fields = (props) => [
  {
    id: "actions",
    label: null,
    immutable: true,
    render: (v) => (
      <div className="session-actions">
        <Link
          to={() =>
            `/scenarios/${props.scenario}/sessions/${encodeURIComponent(
              v.app_session_id
            )}/session-summary`
          }
          size="small"
          title={props.strings.sessionstable_session_action_tooltip_details}
        >
          <VisibilityIcon sx={{ color: "purple.dark" }} />
        </Link>
        <Link
          to={`/scenarios/${props.scenario}/users?uid=${encodeURIComponent(
            v.orig_app_user_id
          )}`}
          size="small"
          title={props.strings.sessionstable_session_action_tooltip_user}
        >
          <PersonIcon sx={{ color: "purple.dark" }} />
        </Link>

        <ProfileEvaluationIndicator
          cluster={+v.cluster}
          cluster_malicious={v.cluster_malicious}
          cluster_annotation={v.cluster_annotation}
          scenario={props.scenario}
        />

        <CommentIndicator
          scenario={props.scenario}
          sessionId={v.app_session_id}
          comment={v.annotation}
        />
        <HtmlTooltip title={formatTooltipText(v, props.strings)}>
          <div style={{ display: "inline" }}>
            <SessionEvaluationIndicator
              scenario={props.scenario}
              sessionInfo={v}
            />
          </div>
        </HtmlTooltip>
      </div>
    ),
    style: {
      width: "10em",
    },
  },
  {
    id: "start",
    label: props.strings.sessionstable_header_start,
    sort: "start",
    render: (v) => (
      <Typography variant="body1">
        {timeFormatter(new Date(v.start))}
      </Typography>
    ),
    style: {
      width: "9em",
    },
  },
  {
    id: "duration",
    label: props.strings.sessionstable_header_duration,
    render: (v) => (
      <Typography variant="body1">
        {formatInterval(timeInterval(v.start, v.end))}
      </Typography>
    ),
    style: {
      width: "7em",
    },
  },
  {
    id: "orig_app_user_id",
    label: props.strings.sessionstable_header_user,
    sort: "orig_app_user_id",
    render: (v) => (
      <EmbeddedTagsView
        value={v.orig_app_user_id}
        userType={v.user_sensitivity_type}
      />
    ),
    style: {
      width: "20em",
      whiteSpace: "nowrap",
    },
  },
  {
    id: "profile-id",
    label: props.strings.profile,
    sort: "cluster",
    render: (v) =>
      +v?.cluster > 0 ? (
        <DataLink
          to={`/scenarios/${props.scenario}/sessions?cluster=${+v.cluster}`}
          title={props.strings.sessionstable_header_profile_tooltip}
        >
          {`#${+v?.cluster}`}
        </DataLink>
      ) : null,
    style: {
      width: "8em",
    },
  },
  {
    id: "length",
    label: props.strings.sessionstable_header_length,
    sort: "num_events",
    render: (v) => <Typography variant="body1">{+v.num_events}</Typography>,
    style: {
      width: "7em",
    },
  },
  {
    id: "sensitivity",
    label: props.strings.sessionstable_header_sensitivity,
    sort: "num_sensitive_events",
    reverse_sort_indicator: true,
    render: (v) => (
      <SensitivityIndicator
        value={Math.abs(Math.min(+v.num_sensitive_events, 0))}
      />
    ),
    style: {
      width: "9em",
    },
  },
  {
    id: "whole_risk",
    label: props.strings.sessionstable_header_risk,
    sort: "whole_risk",
    render: (v) => (
      <DataLink
        to={() =>
          `/scenarios/${props.scenario}/sessions/${encodeURIComponent(
            v.app_session_id
          )}/risk-history`
        }
        style={{ color: "#555" }}
      >
        <RiskIndicator value={v.whole_risk} reevaluated={!!v.reevaluated} />
      </DataLink>
    ),
    style: {
      width: "7em",
      paddingLeft: "10px",
    },
  },
];

function FilterPresetSelector({ onChange, minRisk = 70 }) {
  const strings = useLocalizedStrings();
  return (
    <StyledFilterButtonsGroup
      buttons={filterPresets(strings, minRisk)}
      buttonsStyle={{
        padding: "8px 10px",
        minWidth: "7em",
        fontSize: ".8em",
        fontWeight: "bold",
        whiteSpace: "nowrap",
      }}
      onClick={onChange}
    />
  );
}

const StyledSessionsView = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "100%",
  gridTemplateRows: "min-content 1fr 30px",
  height: "calc(100vh - 36px - var(--appbar-height))",
  position: "relative",
  "& .sessions-toolbar": {
    alignItems: "center",
    display: "flex",
  },
}));

const SessionsTableView = () => {
  const strings = useLocalizedStrings();
  const { selectedScenario } = useContext(ScenariosContext);
  const [filters, setFilters] = useCurrentUserSettings("sessions.filters", {});
  const [orderBy, setOrderBy] = useCurrentUserSettings("sessions.orderby", {});
  const [openFilters, setOpenFilters] = useStateDeep(false);
  const [textSearchPattern, setTextSearchPattern] = useState(
    filters?.["ts_user_or_annotation"]?.operand || ""
  );

  const search = useRef(null);
  const scenario = useMemo(() => selectedScenario?.id, [selectedScenario]);
  const { removeQueryString } = useCurrentUrlState();

  const [location] = useLocationChanged();
  const theme = useTheme();

  const { data, fetchNextPage, isFetching, isPending, cancel } =
    useSessionsGetUrlInfiniteQuery({
      filters,
      orderBy,
      scenario,
      mret: selectedScenario?.most_recent_event_time,
    });

  const { data: minRisk } = useScenarioParameterKeyQuery(
    {
      scenarioId: scenario,
      paramKey: "com/trackeriq/analysis/risk-floor",
    },
    {
      enabled: !!scenario,
    }
  );

  //  build filters from query-string if exists
  useEffect(() => {
    const parseDate = (d) => d.replace(/Z$/, "");
    search.current = removeQueryString();
    const s = search.current;
    if (!s) {
      return;
    }
    const f = {};
    if (s["uid"]) {
      f["orig_app_user_id"] = { operator: "eq", operand: s["uid"] };
    }
    if (s["from"]) {
      f["start"] = { operator: "ge", operand: parseDate(s["from"]) };
    }
    if (s["to"]) {
      f["end"] = { operator: "le", operand: parseDate(s["to"]) };
    }
    if (s["cluster"]) {
      f["cluster"] = { operator: "eq", operand: s["cluster"] };
    }
    if (s["whole_risk"]) {
      f["whole_risk"] = { operator: "ge", operand: s["whole_risk"] };
    }
    if (s["alerted_since"]) {
      f["time_alerted"] = {
        operator: "ge",
        operand: parseDate(s["alerted_since"]),
      };
    }
    if (s["score_ref"]) {
      f["score_ref"] = { operator: "eq", operand: s["score_ref"] };
    }
    if (s["app_session_id"]) {
      f["app_session_id"] = { operator: "in", operand: s["app_session_id"] };
    }
    setFilters(f);
  }, [removeQueryString, setFilters, location]);

  const handleNextSortDirection = (id) => {
    setOrderBy((prev) => {
      let d = (prev?.[id] || 0) + 1;
      if (d > 1) {
        d = -1;
      }
      return d ? { [id]: d } : {};
    });
  };

  const handleFilterPresetChanged = (_, id) => {
    cancel();
    const f = filterPresets(strings, minRisk)[id];
    f && setFilters(f);
  };

  const handleResetFilters = () => {
    setFilters({});
    setTextSearchPattern("");
  };

  const handleChangeFilters = (f) => {
    cancel();
    setFilters((prev) => {
      if (
        prev?.["ts_user_or_annotation"]?.operand &&
        !f?.["ts_user_or_annotation"]?.operand
      ) {
        setTextSearchPattern("");
      }
      return f;
    });
  };

  // apply text-search changes into current filters
  useEffect(() => {
    if (search.current?.["uid"]) {
      return;
    }
    if (textSearchPattern) {
      setFilters((prev) => {
        const f = {
          ...prev,
          ts_user_or_annotation: {
            operator: "text-search",
            operand: textSearchPattern,
          },
        };
        return f;
      });
    } else {
      setFilters((prev) => {
        const o = { ...prev };
        if (o["ts_user_or_annotation"]) {
          delete o["ts_user_or_annotation"];
          return o;
        }
        return prev;
      });
    }
  }, [setFilters, textSearchPattern, search]);

  const handleDownloadCSV = async () => {
    const limit = 10000;
    const fetchUrl = SessionsAPI.getUrl({
      filters,
      limit,
      offset: null,
      orderBy,
      scenario,
    });
    fetchUrl.url += "&format=csv";

    const modifiedFetchUrl = new URL(
      (baseUrl() || window.location.origin) + fetchUrl.url
    );
    modifiedFetchUrl.searchParams.delete("offset");

    return await stdFetch(modifiedFetchUrl.toString());
  };

  const pageLimit = useFeature("ui_settings/sessions_table_page_limit");

  return (
    <StyledSessionsView>
      <div className="sessions-toolbar">
        <FreeSearchStrip
          filters={filters}
          schema={filtersSchema(strings)}
          setFilters={handleChangeFilters}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          searchPattern={textSearchPattern}
          setSearchPattern={setTextSearchPattern}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <SessionLocalDownloader
            filename={`sessions.${selectedScenario?.id}.csv`}
            onClick={handleDownloadCSV}
            type="text/plain"
          />
        </div>
        <div>
          <FilterPresetSelector
            minRisk={minRisk}
            onChange={handleFilterPresetChanged}
          />
        </div>
        <IconButton
          size="small"
          onClick={handleResetFilters}
          style={{ margin: "0 10px" }}
          title="Reset filters"
        >
          <ClearIcon />
        </IconButton>
      </div>

      <VirtuosoTable
        columns={fields({ strings, scenario, theme })}
        isPending={isPending}
        items={data?.rows}
        itemsCount={data?.count}
        loadMoreItems={() => !isFetching && fetchNextPage()}
        onOrderBy={handleNextSortDirection}
        orderBy={orderBy}
        pageLimit={pageLimit}
        overscan={2000}
      />

      <FiltersSection
        filters={filters}
        schema={filtersSchema(strings)}
        setFilters={setFilters}
        openFilters={openFilters}
        setOpenFilters={setOpenFilters}
      />
    </StyledSessionsView>
  );
};

const SessionsTable = (props) => {
  return <SessionsTableView key={props.scenario} />;
};

export default SessionsTable;
