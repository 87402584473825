import React, { useContext, useMemo } from "react";

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  RadioGroup,
  Select,
  styled,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { ScenariosContext } from "contexts/ScenariosContext";

import BrandedRadio from "components/ui/BrandedRadio";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";
import { SecretOptionsInput } from "components/ui/form/SecretInput";
import { TextFieldInput } from "components/ui/form/TextFieldInput";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledAlerterOptionsFrame = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  border: "1px solid #ddd",
  padding: theme.spacing(2),
}));

export const AlerterDialog = ({
  open,
  onClose,
  available,
  alerter: selectedAlerter,
  onAccept,
}) => {
  const strings = useLocalizedStrings();
  const methods = useForm({
    defaultValues: {
      active: selectedAlerter?.active ?? true,
      alerter_id: selectedAlerter?.alerter_id || available?.[0]?.id || "",
      options: selectedAlerter?.options || {},
      scenarios: selectedAlerter?.associated_scenarios || [],
    },
  });

  const { scenarios } = useContext(ScenariosContext);

  const productionScenarios = useMemo(
    () => scenarios?.filter((s) => s.is_production),
    [scenarios]
  );

  const alerterId = methods.watch("alerter_id");
  const availableInfo = useMemo(
    () => available.find((a) => a.id === alerterId),
    [available, alerterId]
  );

  const alerterOptions = useMemo(() => {
    if (!availableInfo?.options) {
      return [];
    }

    return Object.entries(availableInfo.options || {}).sort(([_, a]) =>
      a.datatype === "boolean" ? 1 : -1
    ); //boolean last
  }, [availableInfo]);

  const isEditing = !!selectedAlerter;

  const title = isEditing
    ? strings.account_settings_alerters_form_title_edit
    : strings.account_settings_alerters_form_title_new;

  const renderSelectedScenarios = (selected) => {
    const filteredScenarios = scenarios.reduce((acc, scenario) => {
      if (selected.includes(scenario.id)) {
        acc.push(scenario.name);
      }
      return acc;
    }, []);

    return filteredScenarios.join(", ");
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onAccept)}>
          <DialogTitle> {title} </DialogTitle>

          <DialogContent sx={{ width: "40em" }}>
            <FormGroup sx={{ pb: "2em" }}>
              <FormControl sx={{ width: "100%", display: "block" }}>
                <Controller
                  name="alerter_id"
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      {available
                        .filter((a) =>
                          isEditing ? a.id === selectedAlerter.alerter_id : true
                        )
                        .map((a) => (
                          <FormControlLabel
                            key={a.id}
                            value={a.id}
                            control={<BrandedRadio />}
                            label={`${a.format.toUpperCase()} over ${a.name}`}
                            disabled={isEditing}
                          />
                        ))}
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </FormGroup>
            <FormGroup className="options" style={{ gap: "1em" }}>
              {alerterOptions.length > 0 && (
                <StyledAlerterOptionsFrame>
                  {alerterOptions.map(([k, v]) => {
                    if (v.datatype === "boolean") {
                      return (
                        <Controller
                          name={`options.${k}`}
                          defaultValue={false}
                          key={k}
                          shouldUnregister
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    field.onChange(`${e.target.checked}`)
                                  }
                                  checked={field.value === "true" || false}
                                  color="primary"
                                />
                              }
                              label={v.label}
                            />
                          )}
                        />
                      );
                    }
                    if (v.secret) {
                      return (
                        <SecretOptionsInput
                          autoComplete="off"
                          helperText={v.help}
                          rules={{
                            pattern: {
                              value: /^[^\s]+.*/,
                              message: strings.invalid_input,
                            },
                          }}
                          key={k}
                          autoCorrect="false"
                          spellCheck="false"
                          name={`options.${k}`}
                          label={v.label || ""}
                          required={!isEditing && !!v.required}
                          options={{
                            shouldUnregister: true,
                          }}
                        />
                      );
                    }
                    return (
                      <TextFieldInput
                        name={`options.${k}`}
                        key={k}
                        label={v.label}
                        maxRows={4}
                        multiline={true}
                        placeholder={v.help}
                        required={v.required}
                        type={v.datatype}
                        variant="outlined"
                        options={{
                          shouldUnregister: true,
                        }}
                      />
                    );
                  })}
                </StyledAlerterOptionsFrame>
              )}
              <Controller
                name="scenarios"
                control={methods.control}
                render={({ field: { onChange, value, ...field } }) => {
                  return (
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="scenarios">
                        {
                          strings.account_settings_alerters_alerter_dialog_associated_scenarios_label
                        }
                      </InputLabel>
                      <Select
                        label={
                          strings.account_settings_alerters_alerter_dialog_associated_scenarios_label
                        }
                        {...field}
                        multiple
                        renderValue={renderSelectedScenarios}
                        labelId="selector"
                        onChange={(e) => {
                          const selectedValues = e.target.value;
                          onChange(selectedValues);
                        }}
                        value={value}
                        variant="outlined"
                        style={{ width: "100%" }}
                        MenuProps={{
                          slotProps: {
                            paper: {
                              style: {
                                maxHeight: 200,
                                overflow: "auto",
                              },
                            },
                          },
                        }}
                      >
                        {productionScenarios.map((scenario) => (
                          <MenuItem value={scenario.id} key={scenario.id}>
                            <Checkbox checked={value.includes(scenario.id)} />
                            <ListItemText primary={scenario.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }}
              />
            </FormGroup>
          </DialogContent>

          <DialogActions>
            <UiSecondaryButton onClick={() => onClose()}>
              Cancel
            </UiSecondaryButton>
            <UiPrimaryButton type="submit">
              {selectedAlerter ? strings.button_submit : strings.button_add}
            </UiPrimaryButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
