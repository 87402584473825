import React, { useCallback, useEffect, useState } from "react";

import { TextField } from "@mui/material";

import MultipleOptionsDialog from "./MultipleOptionsDialog";

function PromptDialog({ open, setOpen, title, onAction }) {
  const [value, setValue] = useState();

  const handleAction = useCallback(() => {
    onAction?.(value);
  }, [onAction, value]);

  const handleChange = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  useEffect(() => {
    if (open) {
      setValue("");
    }
  }, [open]);

  return (
    <MultipleOptionsDialog
      onConfirm={handleAction}
      open={open}
      setOpen={setOpen}
      title={title}
    >
      <TextField
        variant="outlined"
        value={value}
        onChange={handleChange}
        style={{ width: "100%" }}
        autoFocus={true}
      />
    </MultipleOptionsDialog>
  );
}

export default PromptDialog;
