import { useContext, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Typography, styled } from "@mui/material";
import { flushSync } from "react-dom";

import ScenariosAPI, {
  useScenarioCloneMutation,
  useScenarioCreateMutation,
} from "api/scenarios";

import { ScenariosContext } from "contexts/ScenariosContext";

import Flexbox from "components/ui/Flexbox";
import HtmlTooltip from "components/ui/HtmlTooltip";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import { UiPrimaryButton } from "components/ui/StyledButtons";

import { useEnhancedAPI } from "hooks/useAPI";
import { useCapabilities } from "hooks/useCapabilities";
import { useDeletedScenario } from "hooks/useDeletedScenario";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { storageRemoveByPrefix } from "hooks/useStorage";

import ScenarioForm from "./ScenarioForm";
import { ScenarioImporter } from "./ScenarioImporter";
import ScenarioThumbnailsList from "./ScenarioThumbnailsList";

const StyledScenariosPaneContent = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.purple50,
  display: "grid",
  gridTemplateColumns: "100%",
  gridTemplateRows: "2.3em 1fr",
  height: "100%",
  width: "100%",
  "& .scenarios-list-wrapper": {
    direction: "rtl",
    marginTop: "10px",
    overflowX: "hidden",
    overflowY: "auto",
    "& > ul ": {
      direction: "ltr",
    },
  },
}));

const SidebarActions = ({ handleBeginCreate, handleImportEnd }) => {
  const strings = useLocalizedStrings();
  const caps = useCapabilities();

  if (!caps({ "acm.scenarios": { write: true } })) {
    return null;
  }

  return (
    <Flexbox>
      <HtmlTooltip title={strings.button_add}>
        <UiPrimaryButton
          onClick={handleBeginCreate}
          startIcon={<AddCircleIcon />}
          variant="text"
          sx={{ padding: "0 8px" }}
        />
      </HtmlTooltip>
      <ScenarioImporter onImportEnd={handleImportEnd} />
    </Flexbox>
  );
};

const SidebarTitleBar = ({ handleBeginCreate, selectNewScenario }) => {
  const strings = useLocalizedStrings();

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "purple.dark",
        display: "flex",
        gap: "1em",
        justifyContent: "space-between",
        padding: "0 1em",
      }}
    >
      <Typography
        sx={{
          textTransform: "uppercase",
          color: "secondary.contrastText",
          textShadow: "1px 0px 0px white",
        }}
      >
        {strings.sidebar_title}
      </Typography>
      <SidebarActions
        handleImportEnd={selectNewScenario}
        handleBeginCreate={handleBeginCreate}
      />
    </Box>
  );
};

function ScenariosSidebar() {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmContext, setConfirmContext] = useState();
  const { scenarios, selectScenario, setScenarios } =
    useContext(ScenariosContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sourceScenario, setSourceScenario] = useState();
  const [scenarioFormMode, setScenarioFormMode] = useState();
  const api = useEnhancedAPI();
  const strings = useLocalizedStrings();
  const { markDeletedScenario, unmarkDeletedScenario } = useDeletedScenario();

  const { mutateAsync: createScenario } = useScenarioCreateMutation();
  const { mutateAsync: cloneScenario } = useScenarioCloneMutation();

  const handleDelete = (scenario) => {
    setConfirmContext(scenario);
    setConfirmOpen(true);
  };

  const handleBeginCreate = () => {
    setScenarioFormMode("create");
    setSourceScenario(null);
    setDialogOpen(true);
  };

  const handleCloneScenario = (scenario) => {
    setScenarioFormMode("clone");
    setSourceScenario(scenario);
    setDialogOpen(true);
  };

  const selectNewScenario = (res) => {
    const newScenario = res[0];
    newScenario.initialized ??= false;

    flushSync(() => {
      setScenarios((prev) => {
        if (prev?.some((scenario) => scenario.id === newScenario.id)) {
          return prev;
        }
        return [newScenario, ...prev];
      });
    });

    selectScenario(newScenario.id);
    setDialogOpen(false);
    setSourceScenario(null);
  };

  const handleEndCreate = async ({ name, description, use_case }) => {
    try {
      if (scenarioFormMode === "create") {
        const res = await createScenario({
          name,
          description,
          use_case,
        });
        selectNewScenario(res);
        return;
      }

      const res = await cloneScenario({
        id: sourceScenario.id,
        name,
        description,
      });
      selectNewScenario(res);
    } catch {}
  };

  const handleRemovalConfirmation = (ctx) => {
    markDeletedScenario(ctx.id);
    api(ScenariosAPI.deleteScenario(ctx.id))
      .then((r) => {
        if (r.length) {
          setScenarios(scenarios.filter((s) => s.id !== r[0].id));
          storageRemoveByPrefix(localStorage, r[0].id);
        }
      })
      .catch(() => {
        unmarkDeletedScenario(ctx.id);
      });
  };

  return (
    <StyledScenariosPaneContent className="scenarios-pane-content">
      <SidebarTitleBar
        handleBeginCreate={handleBeginCreate}
        selectNewScenario={selectNewScenario}
      />
      <div className="scenarios-list-wrapper">
        <ScenarioThumbnailsList
          handleCloneScenario={handleCloneScenario}
          handleDelete={handleDelete}
        />
      </div>

      <ScenarioForm
        open={dialogOpen}
        key={dialogOpen}
        onAccept={handleEndCreate}
        onCancel={() => {
          setDialogOpen(false);
        }}
        sourceScenario={sourceScenario}
        formMode={scenarioFormMode}
      />

      <MultipleOptionsDialog
        context={confirmContext}
        onConfirm={handleRemovalConfirmation}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        text={strings.formatString(
          strings.sidebar_delete_scenario_question,
          confirmContext?.name || ""
        )}
        title={strings.sidebar_delete_scenario_title}
      />
    </StyledScenariosPaneContent>
  );
}

export default ScenariosSidebar;
