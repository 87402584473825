import { useCallback, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IconButton } from "@mui/material";

import TagsViewStyled from "./styled/TagsView.styled";

const re_ip = /[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}/;
const whois = "https://who.is/whois-ip/ip-address";

function TagValue({ value }) {
  const ip = value.match?.(re_ip);
  if (ip) {
    return (
      <a
        className="tag-value"
        target="_blank"
        rel="noreferrer"
        href={`${whois}/${ip[0]}`}
      >
        {value}
      </a>
    );
  }
  return <span className="tag-value">{value}</span>;
}

export default function TagsView({ items, actionKeys, ...rest }) {
  const [collapsed, setCollapsed] = useState(true);

  const handleClick = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, [setCollapsed]);

  return (
    <TagsViewStyled className={collapsed ? "collapsed" : ""}>
      <IconButton size="small" onClick={handleClick}>
        <ArrowDropDownIcon />
      </IconButton>
      <ul className="tags" {...rest}>
        {items
          .sort((a, b) =>
            a.key.localeCompare(b.key, undefined, { sensitivity: "accent" })
          )
          .map((d) => (
            <li
              className={actionKeys.has(d.key) ? "action" : "annotation"}
              key={d.key}
              style={{ wordBreak: "break-all" }}
            >
              <span className="tag-name">{d.key}</span>
              {d.value.map((v, i) => (
                <TagValue key={i} value={v} />
              ))}
            </li>
          ))}
      </ul>
    </TagsViewStyled>
  );
}
