import { useMutation } from "@tanstack/react-query";

import API from "./apibase";
import { queryFetch } from "./query";

class UserMessagesAPI extends API {
  static postUserMessage(scenario, text) {
    return {
      url: `/api/acm/v1/user_messages/key/${scenario}`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          service: "UI",
          level: "info",
          message: text,
        }),
      },
    };
  }
}

export default UserMessagesAPI;

export const usePostUserMessageMutation = ({ scenarioId, text }) =>
  useMutation({
    mutationFn: () =>
      queryFetch(UserMessagesAPI.postUserMessage(scenarioId, text)),
  });
