import { useCallback, useContext, useEffect } from "react";

import { useStore } from "store";

import { LoginStatusContext } from "contexts/LoginStatusContext";

export default function FeatureFlagsWrapper({ children }) {
  const { user } = useContext(LoginStatusContext);

  const getFeatureFlags = useStore(
    useCallback((state) => state.getFeatureFlags, [])
  );

  useEffect(() => {
    if (!user || user.super_user) {
      return;
    }
    getFeatureFlags();
  }, [getFeatureFlags, user]);

  return <>{children}</>;
}
