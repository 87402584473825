import { useEffect, useMemo, useRef, useState } from "react";

import { styled } from "@mui/material";
import { Address4, Address6 } from "ip-address";
import { parse as parseEmailAddress } from "smtp-address-parser";
import { isHttpUri, isHttpsUri } from "valid-url";

import { parseUserId } from "utils/parseUserId";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import HtmlTooltip from "./HtmlTooltip";
import { UserTypeIcon } from "./UserTypeIcon";

const isEmail = (s) => {
  try {
    parseEmailAddress(s);
    return true;
  } catch (err) {
    return false;
  }
};

const isIPAddress = (s) => {
  try {
    new Address4(s);
    return true;
  } catch (err) {}
  try {
    new Address6(s);
    return true;
  } catch (err) {}
  return false;
};

const getWhoisURL = (ip) =>
  `https://dnschecker.org/ip-whois-lookup.php?query=${ip}`;

const tokenType = (strings, t) => {
  try {
    if (isEmail(t.value)) {
      return strings.tagsview_type_email;
    }
    if (isIPAddress(t.value)) {
      return strings.tagsview_type_ip;
    }
    if (isHttpUri(t.value) || isHttpsUri(t.value)) {
      return strings.tagsview_type_web;
    }
  } catch (err) {}
  return undefined;
};

const StyledEmbeddedTagsView = styled("div")`
  --faint: #999;
  --radius: 100vw;
  --animation-speed: 100ms;
  color: #555;
  overflow: hidden;
  font-size: 0.8rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  .tag {
    display: flex;
    height: 3em;
    overflow: hidden;
    gap: 0;
    & > span {
      display: flex;
      align-items: center;
      border: 1px solid ${({ theme }) => theme.palette.purple.dark};
      &.key {
        min-width: 25%;
        padding: 0.3rem 0.4rem 0.3rem 0.7rem;
        background-color: ${({ theme }) => theme.palette.purple.dark};
        color: rgba(255, 255, 255, 0.8);
        color: ${({ theme }) => theme.palette.primary.contrastText};
        border-top-left-radius: var(--radius);
        border-bottom-left-radius: var(--radius);
        border-style: none;
        user-select: none;
        .key-content {
          max-width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      &.value {
        padding: 0.3rem 0.7rem;
        color: ${({ theme }) => theme.palette.purple.dark};
        border-top-right-radius: var(--radius);
        border-bottom-right-radius: var(--radius);
        overflow: hidden;
        & > .tag-content {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        a {
          color: steelblue;
          text-decoration: none;
          &:hover {
            font-weight: bold;
          }
        }
        &[data-type]:after {
          content: attr(data-type);
          position: relative;
          flex-grow: 0;
          display: inline-block;
          margin-left: 0.3em;
          opacity: 0.7;
          white-space: nowrap;
          font-size: 0.6rem;
          font-style: italic;
          text-transform: uppercase;
          transform: translateY(-0.4em);
          width: 19px;
        }
      }
    }
  }
`;

const KeyContent = ({ content }) => {
  const ref = useRef();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const container = ref.current;
    const obs = new ResizeObserver((entries) => {
      const el = entries[0].target;
      setShowTooltip(el.scrollWidth > el.clientWidth);
    });

    if (container) {
      obs.observe(container);
    }

    return () => {
      if (container) {
        obs.unobserve(container);
      }
    };
  }, []);

  return (
    <span className={`key ${content}`}>
      <span className="key-content" ref={ref}>
        {showTooltip ? (
          <HtmlTooltip title={content}>
            <span>{content}</span>
          </HtmlTooltip>
        ) : (
          <span>{content}</span>
        )}
      </span>
    </span>
  );
};

const TranslatedTagValue = ({ token }) => {
  const ref = useRef();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const container = ref.current;
    const obs = new ResizeObserver((entries) => {
      const el = entries[0].target;
      setShowTooltip(el.scrollWidth > el.clientWidth);
    });

    if (container) {
      obs.observe(container);
    }

    return () => {
      if (container) {
        obs.unobserve(container);
      }
    };
  }, []);

  const value = showTooltip ? (
    <HtmlTooltip title={token.value}>
      <span>{token.value}</span>
    </HtmlTooltip>
  ) : (
    <span>{token.value}</span>
  );

  if (token.type === "email") {
    return (
      <a ref={ref} className="tag-content" href={`mailto:${token.value}`}>
        {value}
      </a>
    );
  }
  if (token.type === "ip-address") {
    return (
      <a
        ref={ref}
        className="tag-content"
        href={getWhoisURL(token.value)}
        rel="noreferrer"
        target="_blank"
      >
        {value}
      </a>
    );
  }
  if (token.type === "url") {
    return (
      <a ref={ref} className="tag-content" href={token.value}>
        {value}
      </a>
    );
  }
  if (token.type === "phone") {
    return (
      <a ref={ref} className="tag-content" href={`tel:${token.value}`}>
        {value}
      </a>
    );
  }
  return (
    <span ref={ref} className="tag-content">
      {value}
    </span>
  );
};

export default function EmbeddedTagsView({ value, userType }) {
  const strings = useLocalizedStrings();

  const tags = useMemo(
    () =>
      parseUserId(value, strings.tagsview_untitled)
        .map((t) => ({ ...t, type: tokenType(strings, t) }))
        .sort((a, b) => a.key.localeCompare(b.key)),
    [value, strings]
  );

  if (!tags) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "28px",
          flexShrink: "0",
          visibility:
            userType === "none" ? "collapse" : !userType ? "hidden" : "visible",
        }}
      >
        <UserTypeIcon type={userType} />
      </div>
      <StyledEmbeddedTagsView className="tags" numberOfTags={tags.length}>
        {tags.map((t, idx) => (
          <div className="tag" key={t.key + idx}>
            <KeyContent content={t.key} />
            <span className="value" data-type={t.type}>
              <TranslatedTagValue token={t} />
            </span>
          </div>
        ))}
      </StyledEmbeddedTagsView>
    </div>
  );
}
