import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Checkbox, Divider, Typography, styled, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import { useAdminsQuery } from "api/admins";
import SessionsAPI, { useAlertsInfiniteQuery } from "api/sessions";

import { ScenariosContext } from "contexts/ScenariosContext";

import DataLink from "components/ui/DataLink";
import EmbeddedTagsView from "components/ui/EmbeddedTagsView";
import HtmlTooltip from "components/ui/HtmlTooltip";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import RiskIndicator from "components/ui/RiskIndicator";
import { StyledFilterButtonsGroup } from "components/ui/StyledButtonsGroup";
import { VirtuosoTable } from "components/ui/VirtuosoTable";

import FilterOperators from "utils/FilterOperators";
import { baseUrl } from "utils/baseUrl";
import stdFetch from "utils/stdFetch";
import { timeFormatter } from "utils/time-fmt";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import { useStateDeep } from "hooks/deepcomp";
import { useEnhancedAPI } from "hooks/useAPI";
import { useCapabilities } from "hooks/useCapabilities";
import useCurrentUrlState from "hooks/useCurrentUrlState";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useLocationChanged } from "hooks/useLocationChanged";
import { useMessages } from "hooks/useMessage";
import useMounted from "hooks/useMounted";

import FiltersSection from "./FiltersSection";
import FreeSearchStrip from "./FreeSearchStrip";
import { ProfileEvaluationIndicator } from "./ProfileEvaluationIndicator";
import { CommentIndicator } from "./SessionCommentIndicator";
import { SessionEvaluationIndicator } from "./SessionEvaluationIndicator";
import { SessionLocalDownloader } from "./SessionLocalDownloader";

const operators = FilterOperators;

const filtersSchema = (strings) =>
  Object.entries({
    start: {
      label: strings.sessionstable_filters_start,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    orig_app_user_id: {
      label: strings.sessionstable_filters_user,
      type: "text",
      operators: [
        operators["eq"],
        operators["contains"],
        operators["not-contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
    whole_risk: {
      label: strings.sessionstable_filters_risk,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    cluster: {
      label: strings.profile,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    alerted: {
      label: strings.sessionstable_filters_alerted,
      type: "boolean",
      visible: false,
      operators: [operators["eq"], operators["ne"]],
    },
    time_alerted: {
      label: strings.sessionstable_filters_time_alerted,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    annotation: {
      label: strings.sessionstable_filters_comment,
      type: "text",
      operators: [
        operators["defined"],
        operators["eq"],
        operators["ne"],
        operators["contains"],
        operators["not-contains"],
      ],
    },
    ts_user_or_annotation: {
      label: strings.sessionstable_filters_user_or_comment,
      type: "text",
      visible: false,
      operators: [operators["text-search"]],
    },
    ticket_closed_time: {
      label: strings.alertstable_filters_evaluated,
      type: "datetime_local",
      visible: false,
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    alert_info: {
      label: strings.sessionstable_filters_sent,
      type: "select",
      default_value: "All",
      dropdown_options: [
        {
          id: "all",
          label: "All",
          value: "All",
        },
        {
          id: "true",
          label: "True",
          value: "0",
          operator: operators["gt"],
        },
        {
          id: "false",
          label: "False",
          value: "1",
          operator: operators["le"],
        },
      ],
      operators: [operators["gt"]],
    },
  });

const filterPresets = () => ({
  sent: {
    alert_info: { operator: "gt", operand: "0", label: "True" },
  },
  unevaluated: {
    ticket_closed_time: { operator: "null", operand: null },
  },
  evaluated: {
    ticket_closed_time: { operator: "notnull", operand: null },
  },
});

const SelectionCheckbox = forwardRef((props, ref) => {
  const { style, ...rest } = props;
  return (
    <Checkbox
      ref={ref}
      style={{ ...style, color: "LightSlateGrey" }}
      {...rest}
    />
  );
});

const fields = (props) => [
  {
    id: "actions",
    label: (
      <SelectionButtons
        onSelectAll={props.onSelectAll}
        onSelectNone={props.onSelectNone}
        scenario={props.scenario}
      />
    ),
    immutable: true,
    render: (v) => (
      <div className="alert-actions">
        <SelectionCheckbox
          checked={props.selection?.has(v.id)}
          onChange={(e) => props.onSelected?.(v.id, e.target.checked)}
          style={{ padding: 0 }}
        />
        <HtmlTooltip
          title={props.strings.alertsstable_alert_action_tooltip_details}
        >
          <DataLink
            to={`/scenarios/${props.scenario}/sessions/${encodeURIComponent(
              v.app_session_id
            )}/session-summary`}
            size="small"
            style={{ color: "#777" }}
          >
            <VisibilityIcon />
          </DataLink>
        </HtmlTooltip>
        <ProfileEvaluationIndicator
          cluster={+v.cluster}
          cluster_malicious={v.cluster_malicious}
          cluster_annotation={v.cluster_annotation}
          scenario={props.scenario}
        />
        <CommentIndicator
          scenario={props.scenario}
          sessionId={v.app_session_id}
          comment={v.annotation}
        />
        <SessionEvaluationIndicator scenario={props.scenario} sessionInfo={v} />
      </div>
    ),
    style: {
      width: "10em",
    },
  },
  {
    id: "start",
    label: props.strings.alertstable_table_column_start,
    sort: "start",
    render: (v) => (
      <Typography variant="body1">
        {timeFormatter(new Date(v.start))}
      </Typography>
    ),
    style: {
      width: "9em",
    },
  },
  {
    id: "orig_app_user_id",
    label: props.strings.alertstable_table_column_user,
    sort: "orig_app_user_id",
    render: (v) => (
      <EmbeddedTagsView
        value={v.orig_app_user_id}
        userType={v.user_sensitivity_type}
      />
    ),

    style: {
      width: "17em",
      whiteSpace: "nowrap",
    },
  },
  {
    id: "profile",
    label: props.strings.profile,
    sort: "cluster",
    render: (v) =>
      +v.cluster > 0 ? (
        <DataLink
          to={() =>
            `/scenarios/${props.scenario}/profiles/${v.cluster}/diffusion`
          }
        >
          #{+v.cluster}
        </DataLink>
      ) : null,
    style: {
      textAlign: "left",
      width: "7em",
    },
  },
  {
    id: "whole_risk",
    label: props.strings.alertstable_table_column_risk,
    sort: "whole_risk",
    render: (v) => (
      <RiskIndicator value={v.whole_risk} reevaluated={!!v.reevaluated} />
    ),
    style: {
      textAlign: "left",
      width: "6em",
    },
  },
  {
    id: "issued",
    label: props.strings.alertstable_table_column_issued,
    sort: "time_alerted",
    render: (v) =>
      v.time_alerted ? (
        <DataLink to={`sessions/${v.app_session_id}/alert-details`}>
          <Typography variant="body1">
            {timeFormatter(new Date(v.time_alerted))}
          </Typography>
        </DataLink>
      ) : null,
    style: {
      textAlign: "left",
      width: "8em",
    },
  },
  {
    id: "closed-on",
    label: props.strings.alertstable_table_column_eval_time,
    sort: "ticket_closed_time",
    render: (v) =>
      v.ticket_closed_time ? (
        <Typography variant="body1">
          {timeFormatter(new Date(v.ticket_closed_time))}
        </Typography>
      ) : null,
    style: {
      textAlign: "left",
      width: "8em",
    },
  },
  {
    id: "closed-by",
    label: props.strings.alertstable_table_column_eval_by,
    sort: "ticket_closed_by",
    render: (v) => {
      const u = props.analysts[v.ticket_closed_by];
      return u ? (
        <a href={`mailto:${u.email}`}>{u.name}</a>
      ) : (
        <span>{v.ticket_closed_by}</span>
      );
    },
    style: {
      textAlign: "left",
      width: "8em",
    },
  },
];

function FilterPresetSelector({ onChange }) {
  const strings = useLocalizedStrings();
  return (
    <StyledFilterButtonsGroup
      buttons={filterPresets(strings)}
      buttonsStyle={{
        fontSize: ".8em",
        fontWeight: "bold",
        minWidth: "7em",
        padding: "8px 10px",
        whiteSpace: "nowrap",
      }}
      onClick={onChange}
      size="small"
    />
  );
}

const SelectionButtons = (props) => {
  const { onSelectAll, onSelectNone, scenario } = props;
  const [checked, setChecked] = useState(false);
  const strings = useLocalizedStrings();

  useEffect(() => {
    setChecked(false);
  }, [scenario]);

  useEffect(() => {
    checked ? onSelectAll?.() : onSelectNone?.();
  }, [checked, onSelectAll, onSelectNone]);

  return (
    <div className="selection-buttons">
      <HtmlTooltip title={strings.alertstable_selection_select_all_toggle}>
        <SelectionCheckbox
          checked={checked}
          onClick={() => setChecked((prev) => !prev)}
        />
      </HtmlTooltip>
    </div>
  );
};

const TicketingButtons = (props) => {
  const { onReopenSelectedTickets, onCloseSelectedTickets, updating } = props;
  const strings = useLocalizedStrings();
  const caps = useCapabilities();
  const writeAllowed = caps({ "analysis-model.sessions": { write: true } });

  return (
    <div className="ticketing-buttons">
      <HtmlTooltip title={strings.alertstable_tickets_reopen_all}>
        <span>
          <IconButton
            size="small"
            onClick={() => onReopenSelectedTickets?.()}
            disabled={updating || !writeAllowed}
          >
            <AssignmentIcon />
          </IconButton>
        </span>
      </HtmlTooltip>

      <HtmlTooltip title={strings.alertstable_tickets_close_all}>
        <span>
          <IconButton
            size="small"
            onClick={() => onCloseSelectedTickets()}
            disabled={updating || !writeAllowed}
          >
            <AssignmentTurnedInIcon />
          </IconButton>
        </span>
      </HtmlTooltip>
    </div>
  );
};

const StyledSessionsView = styled("div")`
  position: relative;
  height: calc(100vh - 36px - var(--appbar-height));
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr 30px;
  .sessions-toolbar {
    display: flex;
    align-items: center;
  }
  .alert-actions {
    display: flex;
    align-items: center;
    gap: 0.2em;
  }
  .buttons-group-member {
    padding: 8px 10px;
    min-width: 7em;
    font-size: 0.8em;
    font-weight: bold;
    white-space: nowrap;
  }
  .selection-buttons {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    .MuiCheckbox-root {
      padding: 0;
    }
  }
  .ticketing-buttons {
    display: flex;
    align-items: center;
  }
  a:not(.data-link) {
    text-decoration: none;
    color: SteelBlue;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const AlertsTable = () => {
  const mounted = useMounted();
  const strings = useLocalizedStrings();
  const theme = useTheme();
  const { selectedScenario } = useContext(ScenariosContext);
  const [filters, setFilters] = useCurrentUserSettings("alerts.filters", {});
  const [orderBy, setOrderBy] = useCurrentUserSettings("alerts.orderby", {});
  const [openFilters, setOpenFilters] = useStateDeep(false);
  const [textSearchPattern, setTextSearchPattern] = useState(
    filters?.["ts_user_or_annotation"]?.operand || ""
  );
  const api = useEnhancedAPI();
  const search = useRef(null);
  const { removeQueryString } = useCurrentUrlState();
  const [location] = useLocationChanged();
  const [selection, setSelection] = useState(new Set());
  const [updatingTickets, setUpdatingTickets] = useState(false);
  const [reOpenConfirmOpen, setReOpenConfirmOpen] = useState(false);
  const [closeSelectedConfirmOpen, setCloseSelectedConfirmOpen] =
    useState(false);
  const { pushMessage } = useMessages();
  const scenario = selectedScenario?.id;

  const { data: admins = [] } = useAdminsQuery();

  const analysts = useMemo(
    () => admins?.reduce((a, d) => ({ ...a, [d.id]: d }), {}),
    [admins]
  );

  useEffect(() => {
    setSelection(new Set());
  }, [scenario]);

  const pageLimit = 100;
  const { data, refetch, fetchNextPage, isFetching, isPending, cancel } =
    useAlertsInfiniteQuery({ filters, scenario, orderBy, pageLimit });

  //  build filters from query-string if exists
  useEffect(() => {
    const parseDate = (d) => d.replace(/Z$/, "");
    search.current = removeQueryString();
    const s = search.current;
    const f = {};
    if (s) {
      if (s["newonly"]) {
        f["ticket_closed_time"] = { operator: "null", operand: null };
      }
      if (s["uid"]) {
        f["orig_app_user_id"] = { operator: "eq", operand: s["uid"] };
      }
      if (s["cluster"]) {
        f["cluster"] = { operator: "eq", operand: s["cluster"] };
      }
      if (s["alerted_since"]) {
        f["time_alerted"] = {
          operator: "ge",
          operand: parseDate(s["alerted_since"]),
        };
      }
    }
    setFilters((prev) => ({ ...prev, ...f }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleNextSortDirection = useCallback(
    (id) => {
      cancel();
      setOrderBy((prev) => {
        let d = (prev[id] || 0) + 1;
        if (d > 1) {
          d = -1;
        }
        return d ? { [id]: d } : {};
      });
    },
    [cancel, setOrderBy]
  );

  const handleFilterPresetChanged = useCallback(
    (_e, id) => {
      cancel();
      const f = filterPresets(strings)[id];
      f && setFilters(f);
    },
    [strings, setFilters, cancel]
  );

  const handleResetFilters = useCallback(() => {
    cancel();
    setFilters({});
    setTextSearchPattern("");
  }, [setFilters, cancel, setTextSearchPattern]);

  const handleChangeFilters = useCallback(
    (f) => {
      cancel();
      setFilters((prev) => {
        if (
          prev?.["ts_user_or_annotation"]?.operand &&
          !f?.["ts_user_or_annotation"]?.operand
        ) {
          setTextSearchPattern("");
        }
        return f;
      });
    },
    [setFilters, cancel, setTextSearchPattern]
  );

  const handleSelectAll = useCallback(() => {
    setSelection(
      data?.rows?.reduce((a, d) => {
        a.add(d.id);
        return a;
      }, new Set())
    );
  }, [data?.rows]);

  const handleSelectNone = useCallback(() => {
    setSelection(new Set());
  }, []);

  const handleSelected = useCallback(
    (id, selected) => {
      if (selected && !selection.has(id)) {
        const s = new Set(selection);
        s.add(id);
        setSelection(s);
      } else if (!selected && selection.has(id)) {
        const s = new Set(selection);
        s.delete(id);
        setSelection(s);
      }
    },
    [selection]
  );

  const closeSelectedTickets = useCallback(
    async (close) => {
      const toBeReOpened = close === false;
      if (selection.size === 0) {
        return;
      }
      setUpdatingTickets(true);
      const promises = Array.from(selection).map((d) => {
        const selectedAlert = data?.rows?.find((item) => item.id === d);
        const isAlertOpen = !selectedAlert.ticket_closed_time;
        const benign = false;
        const malicious = false;

        if (isAlertOpen && !toBeReOpened) {
          return api(
            SessionsAPI.getQuickCloseTicket({
              benign,
              close,
              malicious,
              scenario,
              session: d,
            })
          );
        } else if (!isAlertOpen && toBeReOpened) {
          return api(
            SessionsAPI.getQuickCloseTicket({
              benign,
              close,
              malicious,
              scenario,
              session: d,
            })
          );
        }
        return null;
      });
      try {
        await Promise.all(promises.filter((p) => !!p));
        toBeReOpened
          ? pushMessage(
              "success",
              strings.alertstable_tickets_reopen_all_success
            )
          : pushMessage(
              "success",
              strings.alertstable_tickets_close_all_success
            );
      } catch (err) {
        pushMessage("error", err.message);
      } finally {
        if (mounted) {
          setUpdatingTickets(false);
          setSelection(new Set());
          refetch();
        }
      }
    },
    [
      scenario,
      refetch,
      selection,
      mounted,
      api,
      data?.rows,
      pushMessage,
      strings,
    ]
  );

  const handleReopenSelectedTickets = useCallback(() => {
    closeSelectedTickets(false);
  }, [closeSelectedTickets]);

  const handleCloseSelectedTickets = useCallback(() => {
    closeSelectedTickets(true);
  }, [closeSelectedTickets]);

  // apply text-search changes into current filters
  useEffect(() => {
    if (search.current?.["uid"]) {
      return;
    }
    if (textSearchPattern) {
      setFilters((prev) => {
        const f = {
          ...prev,
          ts_user_or_annotation: {
            operator: "text-search",
            operand: textSearchPattern,
          },
        };
        delete f["orig_app_user_id"];
        return f;
      });
    } else {
      setFilters((prev) => {
        const o = { ...prev };
        if (o["ts_user_or_annotation"]) {
          delete o["ts_user_or_annotation"];
          delete o["orig_app_user_id"];
          return o;
        }
        return prev;
      });
    }
  }, [textSearchPattern, search, setFilters]);

  const handleDownloadCSV = async () => {
    const limit = 10000;
    const fetchUrl = SessionsAPI.getAlerts({
      filters,
      limit,
      offset: null,
      orderBy,
      scenario,
    });
    fetchUrl.url += "&type=alerts&format=csv";

    const modifiedFetchUrl = new URL(
      (baseUrl() || window.location.origin) + fetchUrl.url
    );
    modifiedFetchUrl.searchParams.delete("offset");
    return await stdFetch(modifiedFetchUrl.toString());
  };

  return (
    <StyledSessionsView>
      <div className="sessions-toolbar">
        <FreeSearchStrip
          filters={filters}
          schema={filtersSchema(strings)}
          setFilters={handleChangeFilters}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          searchPattern={textSearchPattern}
          setSearchPattern={setTextSearchPattern}
        >
          <Divider orientation="vertical" flexItem />
          <TicketingButtons
            onReopenSelectedTickets={() => setReOpenConfirmOpen(true)}
            onCloseSelectedTickets={() => setCloseSelectedConfirmOpen(true)}
            updating={updatingTickets || !selection?.size}
          />
          <Divider orientation="vertical" flexItem />
        </FreeSearchStrip>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SessionLocalDownloader
            filename={`alerts.${selectedScenario?.id}.csv`}
            onClick={handleDownloadCSV}
            type="text/csv"
          />
        </div>
        <div>
          <FilterPresetSelector onChange={handleFilterPresetChanged} />
        </div>
        <IconButton
          size="small"
          onClick={handleResetFilters}
          style={{ margin: "0 10px" }}
          title="Reset filters"
        >
          <ClearIcon />
        </IconButton>
      </div>
      <VirtuosoTable
        columns={fields({
          theme,
          analysts,
          selection,
          strings,
          scenario,
          onSelectAll: handleSelectAll,
          onSelectNone: handleSelectNone,
          onSelected: handleSelected,
        })}
        isPending={isPending}
        items={data?.rows}
        itemsCount={data?.count}
        loadMoreItems={() => !isFetching && fetchNextPage()}
        orderBy={orderBy}
        onOrderBy={handleNextSortDirection}
        pageLimit={pageLimit}
      />
      <FiltersSection
        filters={filters}
        schema={filtersSchema(strings)}
        setFilters={setFilters}
        openFilters={openFilters}
        setOpenFilters={setOpenFilters}
      />
      <MultipleOptionsDialog
        cancelText={strings.button_no}
        confirmText={strings.button_yes}
        onConfirm={handleReopenSelectedTickets}
        open={reOpenConfirmOpen}
        setOpen={setReOpenConfirmOpen}
        text={strings.alertstable_tickets_reopen_all_confirmation_text}
        title={strings.alertstable_tickets_reopen_all}
      />
      <MultipleOptionsDialog
        cancelText={strings.button_no}
        confirmText={strings.button_yes}
        onConfirm={handleCloseSelectedTickets}
        open={closeSelectedConfirmOpen}
        setOpen={setCloseSelectedConfirmOpen}
        text={strings.alertstable_tickets_confirmation_text}
        title={strings.alertstable_tickets_close_all}
      />
    </StyledSessionsView>
  );
};

export default AlertsTable;
