import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import API from "./apibase";
import { queryFetch } from "./query";

export default class AlertersAPI extends API {
  static available() {
    return { url: "/api/acm/v1/alerter/available" };
  }

  static alerters() {
    return { url: "/api/acm/v1/alerter/" };
  }

  static addAlerter(alerter) {
    const reduced = ({ alerter_id, active, options, scenarios }) => ({
      alerter_id,
      active,
      options,
      scenarios,
    });
    return {
      url: "/api/acm/v1/alerter/",
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(reduced(alerter)),
      },
    };
  }

  static updateAlerter(id, alerter) {
    const reduced = ({ id, active, options, scenarios }) => ({
      id,
      active,
      options,
      scenarios,
    });
    const a = reduced(alerter);
    delete a.id;
    delete a.account;
    return {
      url: `/api/acm/v1/alerter/key/${id}`,
      options: {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(a),
      },
    };
  }

  static deleteAlerter(aid) {
    return {
      url: `/api/acm/v1/alerter/key/${aid}`,
      options: {
        method: "DELETE",
      },
    };
  }
}

const baseKey = ["alerters"];
const alerterKeys = {
  list: () => [baseKey, "list"],
  available: () => [baseKey, "available"],
};

export const useAlertersGetAlertersQuery = () => {
  return useQuery({
    queryKey: alerterKeys.list(),
    queryFn: () => queryFetch(AlertersAPI.alerters()),
  });
};

export const useAlertersGetAvailableAlertersQuery = () => {
  return useQuery({
    queryKey: alerterKeys.available(),
    queryFn: () => queryFetch(AlertersAPI.available()),
  });
};

export const useAlertersAddAlerterQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (alerter) => queryFetch(AlertersAPI.addAlerter(alerter)),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: alerterKeys.list() }),
  });
};

export const useAlertersUpdateAlerterQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id, alerter) =>
      queryFetch(AlertersAPI.updateAlerter(id, alerter)),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: alerterKeys.list() }),
  });
};

export const useAlertersDeleteAlerterQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uid) => queryFetch(AlertersAPI.deleteAlerter(uid)),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: alerterKeys.list() }),
  });
};
