import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material";
import { startCase } from "lodash-es";
import { useHistory, useParams } from "react-router-dom";

import { blue } from "themes/MainTheme";

import { formatPeriod } from "components/sessions/relevance";
import Flexbox from "components/ui/Flexbox";
import HtmlTooltip from "components/ui/HtmlTooltip";

import useFeature from "hooks/useFeature";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import { useSessionSummaryFields } from "./SessionSummaryFields";

const StyledChart = styled(Flexbox)(() => ({
  alignItems: "flex-start",
  flexDirection: "column",
  flexGrow: 1,
  overflowX: "hidden",
  overflowY: "auto",
  paddingRight: "2px",
  position: "relative",
  width: "100%",
}));

const StyledFragmentedBar = styled(
  Grid,
  {}
)(({ theme, ownerState: { maxLabelWidth, tagNameHighlighted } }) => ({
  alignItems: "center",
  display: "flex",
  position: "relative",
  transition: "all 200ms ease",
  width: "100%",
  "&:not(:last-child)": {
    marginBottom: theme.spacing(0.4),
  },
  "&.hidden": {
    height: 0,
    margin: 0,
    transform: "scale(0)",
  },
  "& .gridItemLabel": {
    maxWidth: maxLabelWidth,
    minWidth: "100px",
  },
  "& .hoverable": {
    "&:hover": {
      width: "auto !important",
      maxWidth: "max-content",
      "& .label": {
        maxWidth: "max-content",
        transition: "all 500ms ease",
        width: "100%",
      },
    },
  },
  "& .label": {
    fontSize: tagNameHighlighted ? "10pt" : "",
    border: tagNameHighlighted ? `1px solid ${blue}` : "none",
    padding: "2px 4px",
    fontWeight: "700",
    marginRight: "5px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    "& .long": {
      minWidth: "auto",
      width: "auto",
    },
    "& .short": {
      minWidth: 120,
      width: 120,
    },
  },
  "& .bar": {
    flexGrow: 1,
    height: "2.3em",
    overflow: "clip",
    position: "relative",
  },
}));

const StyledTagValueBar = styled("div")(
  ({ theme, tagValueHighlighted, percent, sensitivity }) => ({
    width: `${percent * 100}%`,
    backgroundColor: `hsla(${sensitivity2Hue(
      Math.abs(sensitivity)
    )}, 60%, 50%, .8)`,

    position: "relative",
    transition: "all 200ms ease",
    padding: 0,
    display: "flex",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    overflow: "clip",
    whiteSpace: "nowrap",
    "&:not(.exceeded):not(:last-child)": {
      borderRight: "1px solid #777",
    },
    "&.exceeded": {
      width: "100%",
      justifyContent: "center",
      backgroundColor: "#666",
      color: "#aaa",
      textTransform: "uppercase",
      letterSpacing: "2px",
    },

    "&:not(.exceeded)::before": {
      content: "attr(value)",
      position: "absolute",
      left: "6px",
      maxWidth: "calc(100% - 12px)",
      overflow: "clip",
      padding: "3px",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: tagValueHighlighted ? blue : "rgba(0,0,0,.2)",
      color: "white",
      fontSize: "9pt",
      transition: "all 200ms ease",
    },
  })
);

function sensitivity2Hue(s) {
  return 100 - 10 * parseInt(s);
}

const FieldTooltipContent = ({ title, significance }) => (
  <>
    <Typography
      variant="caption"
      sx={{
        position: "absolute",
        top: 1,
        right: 5,
      }}
    >
      {startCase(significance)}
    </Typography>
    <pre>{title}</pre>
  </>
);

const TagValueBar = memo(
  ({
    name,
    percent,
    foundInSessions = 0,
    totalSessions,
    sensitivity,
    isPendingTagTotals,
    factsEnabled,
    tagValueHighlighted,
    significanceValue,
    fromDate,
    tagName,
    toDate,
  }) => {
    const strings = useLocalizedStrings();
    const history = useHistory();
    const summaryFields = useSessionSummaryFields();
    const { scenario, session } = useParams();

    const t1 = `${name} (${(percent * 100).toFixed(1)}%)`;
    const t2 = isPendingTagTotals
      ? strings.text_loading2
      : strings.formatString(
          strings.sessiondetails_tags_value_tooltip,
          foundInSessions,
          totalSessions,
          formatPeriod(fromDate, toDate, strings)
        );
    const linkedToTimeline = !!summaryFields;
    const title = factsEnabled ? `${t1}\n${t2}` : t1;

    const handleTimelineRedirect = () => {
      if (linkedToTimeline) {
        let pathname = `/scenarios/${scenario}/sessions/${session}/session-timeline`;
        history.push({
          pathname,
          state: {
            initialField: { name: tagName, value: name },
            summaryFields,
          },
        });
      }
    };

    return (
      <HtmlTooltip
        key={name}
        title={
          <FieldTooltipContent title={title} significance={significanceValue} />
        }
      >
        <StyledTagValueBar
          tagValueHighlighted={tagValueHighlighted}
          percent={percent}
          sensitivity={sensitivity}
          value={name}
          onClick={handleTimelineRedirect}
          style={{ cursor: linkedToTimeline && "pointer" }}
        />
      </HtmlTooltip>
    );
  }
);

const useFieldInsights = ({ insights, field }) => {
  //used in the field name on the left
  const valuesProbability = useMemo(
    () =>
      insights?.find((v) => v.type === "values_probability" && v.tag === field),
    [insights, field]
  );
  //used in the green field value bars
  const sessionsFoundPerValue = useMemo(
    () =>
      insights
        ?.filter((i) => i.tag === field && i.type === "sessions_found")
        ?.reduce((a, c) => {
          a[c.value] = c;
          return a;
        }, {}),
    [field, insights]
  );

  return { valuesProbability, sessionsFoundPerValue };
};

const SessionTokensFragmentedBar = ({
  tagData,
  minSensitivity,
  readOnly,
  width,
  insights,
  totals,
}) => {
  const maxLabelWidth = 350;
  const strings = useLocalizedStrings();
  const valueLimit = useFeature("ui_settings/tag_values_max_number");
  const labelGridTextRef = useRef();
  const [isHoverable, setIsHoverable] = useState(false);
  const additionalPixels = !readOnly ? 90 : 35; // we are adding 70px to the labelGridTextRef because it's the width of other elements (siblings) and gap combined
  const factsEnabled = !!totals?.num_sessions;

  const calculateHoverability = useCallback(() => {
    setIsHoverable(
      labelGridTextRef.current?.offsetWidth + additionalPixels > maxLabelWidth
    );
  }, [additionalPixels]);

  const { valuesProbability, sessionsFoundPerValue } = useFieldInsights({
    insights,
    field: tagData?.tag,
  });

  const titleTooltipContent = useMemo(() => {
    if (!factsEnabled) {
      return;
    }
    return (
      <FieldTooltipContent
        title={strings.formatString(
          strings.sessiondetails_tags_key_tooltip,
          tagData.values_count,
          tagData.avg_values_count ?? 0,
          tagData.appearances
        )}
        significance={valuesProbability?.significance?.value}
      />
    );
  }, [
    factsEnabled,
    strings,
    tagData.values_count,
    tagData.avg_values_count,
    tagData.appearances,
    valuesProbability?.significance,
  ]);

  useEffect(() => {
    calculateHoverability();
    window.addEventListener("resize", calculateHoverability);
    return () => window.removeEventListener("resize", calculateHoverability);
  }, [calculateHoverability]);
  return (
    <StyledFragmentedBar
      container
      className={`${
        +Math.abs(tagData.sensitivity) < +minSensitivity && "hidden"
      }`}
      ownerState={{
        maxLabelWidth: maxLabelWidth,
        tagNameHighlighted: valuesProbability,
      }}
    >
      <Grid
        className={`gridItemLabel ${isHoverable ? "hoverable" : ""}`}
        item
        xs={"auto"}
        style={{ width: width + additionalPixels }}
      >
        <Flexbox style={{ height: "100%", gap: "5px" }}>
          <HtmlTooltip title={titleTooltipContent}>
            <Typography
              className={`label ${readOnly ? "long" : "short"} `}
              ref={labelGridTextRef}
              variant="body2"
            >
              {tagData.tag}
            </Typography>
          </HtmlTooltip>
        </Flexbox>
      </Grid>
      <Grid xs={true} item>
        <Flexbox className="bar">
          {tagData.tag_values?.length <= valueLimit ? (
            tagData.tag_values
              .sort((a, b) => b.count - a.count)
              .map((e) => {
                return (
                  <TagValueBar
                    key={e.name}
                    name={e.name}
                    foundInSessions={e.found_in_sessions}
                    percent={e.percent}
                    sensitivity={tagData.sensitivity}
                    totalSessions={totals?.num_sessions}
                    factsEnabled={factsEnabled}
                    tagName={tagData.tag}
                    tagValueHighlighted={sessionsFoundPerValue?.[e.name]}
                    significanceValue={
                      sessionsFoundPerValue?.[e.name]?.significance?.value
                    }
                    fromDate={totals?.from_date}
                    toDate={totals?.to_date}
                  />
                );
              })
          ) : (
            <div className="fragment exceeded">
              ...too many distinct values...
            </div>
          )}
        </Flexbox>
      </Grid>
    </StyledFragmentedBar>
  );
};

const SessionTokens = ({
  histograms,
  totals,
  minSensitivity,
  readOnly,
  insights,
}) => {
  // sort token bars by #of distinct values
  const sortBars = (a, b) => {
    return a?.tag_values?.length - b?.tag_values?.length;
  };

  const longestLabelWidth = useMemo(() => {
    let longestLabel = histograms?.reduce((savedText, tag) => {
      return tag?.tag?.length > savedText.length ? tag.tag : savedText;
    }, "");

    const inputText = longestLabel;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "13px roboto";
    const width = context.measureText(inputText).width;
    const formattedLabelWidth = Math.ceil(width);
    canvas.remove();
    return formattedLabelWidth;
  }, [histograms]);
  return (
    <StyledChart>
      {histograms?.sort(sortBars).map((tagData) => (
        <SessionTokensFragmentedBar
          tagData={tagData}
          key={tagData.tag}
          minSensitivity={minSensitivity}
          readOnly={readOnly || false}
          width={longestLabelWidth}
          insights={insights}
          totals={totals}
        />
      ))}
    </StyledChart>
  );
};
export default SessionTokens;
